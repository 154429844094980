@import "../../../theme/variables.scss";

.Quiz-Wrapper {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;
  @include XGA {
    margin: 0 auto;
  }

  .VeloFreeze {
    .top-desc {
      font-size: 12px;
      color: $primaryColor;
      text-align: center;
      margin-bottom: 40px;
    }
    .next-cta {
      width: 240px;
    }
    .quiz-item {
      .top-desc {
        font-size: 12px;
        color: $primaryColor;
        text-align: center;
        width: 200px;
        margin: 0 auto;
        margin-top: 32px;
        margin-bottom: -10px;
        @include XGA {
          display: none;
        }
      }
      .image {
        margin-bottom: -72px;
      }
      .desc {
        margin-bottom: -20px;
      }
      .puzzle-desc {
        margin-top: 0;
      }
      .body {
        margin-top: 30px;
        padding: 24px;
        padding-bottom: 32px;
        .hilight {
          color: #7088c6;
        }

        .footer {
          display: block;
          font-size: 8px;
          line-height: 16px;
          @include XGA {
            display: inline-block;
            font-size: 12px;
          }
        }

        @include HD {
          width: 455px;
          padding: 60px;
        }
        @include FHD {
          width: 468px;
        }
      }
      .board {
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        list-style: none;
        padding: 0;

        @include XGA {
          position: absolute;
          left: 0;
          top: 60px;
          width: 450px;
        }
        @include HD {
          top: 120px;
          width: 560px;
        }
      }
      .square {
        width: 99.99%;
        height: 99.99%;
        display: block;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &.active {
          opacity: 0.5;
        }
      }
      .tabs {
        margin: 0 auto;
        width: 100%;

        .copy {
          text-align: center;
          font-size: 12px;
        }
        .QuizCta {
          margin: 0px;
          width: 44px;
          height: 44px;
          display: flex;
          justify-content: center;
          padding: 0;
          align-items: center;

          span {
            display: block;
          }
          @include XGA {
            width: 62px;
            height: 62px;
          }
        }
      }

      .cta-tabs {
        width: 100%;
        max-width: 233px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        @include XGA {
          max-width: 330px;
          margin-bottom: 32px;
        }
      }
    }
    .QuizCta {
      &.active {
        color: #001854;
        border-color: #001854;
      }
    }
  }
  .kent-quiz {
    .quiz-item {
      .image {
        z-index: -1;
        position: relative;
        width: 100%;
      }
    }
    .body {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.Quiz {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include XGA {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
    margin: 0 auto;
    width: 100%;
    margin: 0;
  }

  @include HD {
    grid-column-gap: 130px;
  }

  .card-desktop {
    display: none;
    @include XGA {
      display: block;
      width: 468px;
      height: 506px;
      margin: 0;
      padding: 0;
    }
    .cards {
      margin-top: 0;
    }
  }

  .image-desktop {
    display: none;

    @include XGA {
      display: block;
      width: 400px;
      height: 506px;
      margin: 0;
      padding: 0;

      img {
        display: block;
        width: 400px;
        border-radius: 12px;
      }
    }
    @include HD {
      width: 568px;
      img {
        width: 568px;
      }
    }
  }

  .quiz-item {
    .title {
      display: none;

      @include XGA {
        display: block;
        font-size: 35px;
        line-height: 38px;
        margin-top: 40px;
        font-weight: 600;
      }
      @include HD {
        margin-top: 75px;
        font-size: 39px;
        line-height: 42px;
      }
    }

    .image {
      margin: 0 auto;
      margin-top: 23px;
      margin-bottom: -42px;
      width: 195px;

      img {
        width: 100%;
        height: auto;
        border-radius: 12px;
      }

      @include XGA {
        display: none;
      }
    }

    .body {
      background: #fff;
      padding: 24px;
      //padding-top: 60px;
      border-radius: 12px;
      box-shadow: -10px 15px 22px 7px rgba(0, 0, 0, 0.078);

      @include XGA {
        margin-top: 29px;
        padding: 40px 38px;
        padding-bottom: 60px;
        width: 100%;
      }

      @include XGA {
        .cards {
          display: none;
        }
      }
    }

    .question {
      .small {
        font-size: 12px;
      }
      .desc {
        text-align: center;
        @include XGA {
          font-size: 14px;
        }
      }
      .italic {
        font-style: italic;
      }
      .bold {
        font-weight: 700;
      }

      .subtitle {
        @include XGA {
          font-size: 24px;
        }
      }
      margin-bottom: 50px;
    }

    .cta-group {
      display: flex;
      flex-direction: column;

      max-width: 330px;
      margin: 0 auto;
    }
  }
  .next-cta {
    margin-top: 34px;
    @include XGA {
      margin-top: 40px;
    }

    &.disabled {
      background: #ccc;
      pointer-events: none;
    }
  }
}

.VuseEpodStep2 {
  .body {
    margin-top: 27px;
  }

  .cards {
    width: 220px;
    height: 220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto 30px;

    @include vHD {
      width: 440px;
      height: 440px;
    }
  }

  .bat-card {
    height: 70px;
    width: 70px;
    border-radius: 2px;
    background: #000;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    @include vHD {
      width: 140px;
      height: 140px;
    }
  }

  .bat-card.flipped .inner {
    transform: rotateY(180deg);
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;

    transform-style: preserve-3d;
    transition: 0.6s cubic-bezier(0.38, 0.02, 0.09, 1.66) all;
  }

  .bat-card .front,
  .bat-card .back {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    height: 70px;
    width: 70px;
    backface-visibility: hidden;
    overflow: hidden;
    border-radius: 4px;
    border: 2px solid transparent;

    @include vHD {
      width: 140px;
      height: 140px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .bat-card .front {
    background: #fff;
    transform: rotateY(180deg);
    text-align: center;
    font-size: 9px;
    font-weight: 600;
    line-height: 110%;
    @include vHD {
      font-size: 12px;
      span {
        padding: 0 5px;
        line-height: 130%;
      }
    }
  }

  .bat-card .back {
    transition: 0.3s ease all;
  }

  .bat-card .back:hover {
    color: #fff;
    border: 2px solid $secondaryColor;
  }
}

.VuseQuiz {
  .vuse-question {
    display: flex;
    justify-content: center;

    img {
      width: 34px;
      height: auto;
    }

    .vuse-select {
      padding-left:40px;
      position: relative;

      &:after {
        content: '1';
        position: absolute;
        top: 4px;
        left: 8px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: $primaryColor;
        color: #fff;
        text-align: center;
        line-height: 26px;
        font-weight: 700;
      }

      &.second-select {
        margin-top: 40px;
        &:after {
          content: '2';
        }
      }
    }

    select {
      min-width: 135px;
      max-width: 260px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 12px;
      font-weight: 600;
      background-position: 97% 14px;
    }
  }
}