@import "../../theme/variables.scss";

.Onboarding {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .onboarding-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .onboarding-inner {
    position: relative;
    width: 100%;
    max-width: 900px;
    height: 100%;
    background-color: $primaryColor;
    
    @include XGA {
      max-height: 90%;
      border-radius: 12px;
      overflow: hidden;
    }

    @include HD {
      max-width: 1100px;
    }

    .swiper {
      height: 100%;
    }

    .swiper-pagination {
      position: absolute;
      top: 330px;

      @include XGA {
        top: 360px;
      }

    }

    .swiper-pagination-bullet {
      background: #fff;
      opacity: 0.4;
      cursor: pointer;
      transition: ease-out 0.4s;
    }

    .swiper-pagination-bullet-active {
      // width: 8px;
      // height: 8px;
      width: 23px;
      border-radius: 50px;
      background: #fff;
      opacity: 1;
    }

    .onboarding-slide {
      height: 100%;
      display: flex;
      flex-direction: column;

      .image-top {
        width: 100%;
        height: 300px;

        @include XGA {
          height: 330px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .content {
        flex-grow: 1;
        padding: 65px 25px 40px;
        color: #fff;
        text-align: center;
        background-color: #0C235C;

        @include HD {
          padding: 65px 150px 40px;
          font-size: 18px;

          h2 {
            font-size: 30px;
          }
        }
      }
    }

    .close-btn {
      position: absolute;
      z-index: 10;
      bottom: 70px;
      left: 25px;
      color: #fff;
      text-decoration: underline;
      cursor: pointer;

      @include XGA {
        bottom: 30px;
      }
    }

    .swiper-onboarding-next, .swiper-close {
      position: absolute;
      z-index: 10;
      bottom: 66px;
      right: 25px;
      border: 1px solid #fff;
      height: 30px;
      width: 30px;
      background: transparent;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1px;

      @include XGA {
        bottom: 26px;
      }

      &.hide {
        opacity: 0;
        z-index: 9;
      }
    }
  }
}
