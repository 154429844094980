@import "../../theme/variables.scss";
.Contact {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include XGA {
    margin: 0 auto;
  }

  .contact-box {
    width: 100%;
    @include XGA {
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: minmax(200px, auto);
      grid-column-gap: 130px;
    }
  }

  .contact-desc {
    @include XGA {
      text-align: center;
      font-size: 18px;
    }
    @include HD {
      font-size: 21px;
    }
  }

  .hero-image {
    height: 124px;
    width: 100%;
    background-color: rgb(221, 74, 74);
    border-radius: 12px;
    overflow: hidden;

    @include XGA {
      height: 393px;
    }

    .bg {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .contact-link {
    color: $primaryColor;
    font-weight: 700;
  }

  .info-wrapper {
    border-radius: 12px;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #c3c9d8;
    padding: 20px;

    @include XGA {
      margin-top: 43px;
      min-height: 180px;
      align-items: center;
      justify-content: center;
    }

    .info-box {
      display: flex;
    }
    .info-box-body {
      display: flex;
      flex-direction: column;
      .desc {
        font-weight: 700;
      }
      .contact-link {
        text-decoration: none;
      }
    }
    .info-icon {
      margin-right: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mail {
      margin-top: 20px;

      @include XGA {
        margin-top: 24px;
      }

      .desc {
        font-weight: 300;
      }
    }
  }
  .info-disclaimer {
    font-size: 12px;

    @include XGA {
      margin-top: 34px;
      text-align: center;
      padding: 0 20%;
    }
  }
}
