@import "../../theme/variables.scss";
.Stats {
  position: relative;

  .sell-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include HD {
      margin-top: -20px;
    }

    .sell-stats-title {
      margin: 0px;
      @include XGA {
        font-size: 20px;
      }
    }
    .sell-stats-device {
      font-size: 14px;
      font-weight: 700;
      @include XGA {
        font-size: 30px;
      }
    }
    .score {
      color: $secondaryColor;
    }
  }
  .stats-dashboard {
    margin-top: 28px;
    margin-bottom: 30px;
    background: #fff;
    padding: 24px 30px;
    
    .top-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color:$primaryColor;
      margin-top: -5px;

      h4 {
        line-height: 0;
      }

      @include XGA {
        width:max-content;
        gap:12px;
        position: absolute;
        top:55px;
        right: 0;
      }
    }

    .DropDownTabs {
      max-width: 160px;
    }
    
  }

  .item-stats {
    display: flex;
    justify-content: space-between;
    margin: 25px 0 15px;

    .hyper-line {
      width: 0%;
      height: 4px;
      border-radius: 8px;
      background-color: $primaryColor;
      @include XGA {
        height: 8px;
      }
    }

    .vuse-line {
      width: 0%;
      height: 4px;
      border-radius: 8px;
      background-color: $tertiaryColor;
      @include XGA {
        height: 8px;
      }
    }
  }
  .item-name-box {
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
    @include XGA {
      padding: 0 1.5%;
    }

    .item-name {
      font-weight: 700;

      font-size: 14px;
      display: flex;
      align-items: center;
      @include XGA {
        font-size: 24px;
      }
    }
    .device {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: block;

      color: #fff;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7px;

      @include XGA {
        width: 33px;
        height: 33px;
      }
    }
    .device-glo {
      background-color: $primaryColor;
    }
    .device-epen {
      background-color: $tertiaryColor;
    }
  }

  .button-group {
    display: flex;

    &.stats-button-group-top {

      @include XGA {
        position: absolute;
        top: 60px;
        right: 0;
      }

      .ButtonTab {
        color: $primaryColor;
        border: 1px solid $primaryColor;

        &.active {
          color: #fff;
          background-color: $primaryColor;
        }
      }
    }
  }

  .sales-score {
    margin: 0 auto;
    //max-width: 430px;
    padding: 15px 0;
    display: flex;
    color: $primaryColor;
    justify-content: center;

    .score {
      font-size: 40px;
      font-weight: 700;
      display: flex;
      align-items: center;

      img {
        height: 48px;
        width: auto;
        margin-left: 10px;

        @include vHD {
          height: 70px;
        }
      }
    }

    .score-title {
      padding-left: 30px;
      display: flex;
      align-items: center;
      max-width: 360px;

      @include vHD {
        font-size: 24px;
      }
    }
  }
}

.history-section {
  margin-top: 42px;
  background-color: #fff;
  padding: 15px 10px 60px;
  text-align: center;

  h3 {
    font-size: 20px;
    @include XGA {
      text-align: left;
    }
  }

  table {
    width: 100%;
    text-align: left;
    font-size: 12px;

    @include XGA {
      padding: 0 30px;
    }

    tr {
      width: 100%;

      td {
        padding: 10px 0;
        border-bottom: solid 1px #efefef;
      }

      .date {
        color: #817e7e;
        width: 70px;
      }

      .name {
        padding-left: 5%;
        width: calc(100% - 140px);
      }

      .score {
        width: 70px;
        color: $secondaryColor;
        font-size: 14px;
        font-weight: 700;
        text-align: right;
      }
    }
  }
}

.stats-wrapper {
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
  }
}
