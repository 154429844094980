@import "../../../theme/variables.scss";

.BankAccount {
  .desc {
    margin-bottom: 24px;
  }

  .edit {
    width: 100%;
    display: none;
    &.active {
      display: block;
    }
  }

  .prefix-input-wrapper {
    display: flex;
    gap: 8px;
    position: relative;

    &.disabled {
      .input-item {
        width: 100%;
       max-width: 400px;
      }
      input {
         border:1px solid #C4C4C4;
         background: #F7F7F7;
         color: #817E7E;
         padding-right: 10px;
         padding-left: 10px;

         @include vHD {
          padding-left: 35px;
          padding-right: 35px;
         }
      }
    }
    .prefix {
      font-size: 14px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #eeeeee;
      border-radius: 4px;
      width: 48px;
      color: #817e7e;
      flex-shrink: 0;
      font-size: 14px;
      margin-bottom: 5px;
      border: 1px solid #c4c4c4;
    }

    .edit-cta {
      cursor: pointer;
      position: absolute;
      right: 0;
      width: 30px;
      top: 0;
      height: calc(100% - 6px);

      border: none;
      background: transparent;
      @include vHD {
        width: 60px;
      }
    }
  }

  .input-inner {
    position: relative;
  }
  .iban {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .error {
    color: #ef1212;
    margin: 0;
    padding: 0;
    font-weight: 700;
  }
  .warning {
    color: #ef1212;
    font-weight: 700;
    text-align: center;
    margin-top: -20px;
    @include vHD {
      text-align: left;
    }
  }
  input {
    padding-left: 30px;
    padding-right: 35px;
    text-overflow: ellipsis;
  }
  input::placeholder {
    font-style: normal;
    color: $primaryColor;
  }

  .Button {
    margin-bottom: 40px;

    @include XGA {
      max-width: 140px;
      margin-right: auto;
      margin-left: 0;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
