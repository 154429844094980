@import "../../theme/variables.scss";

.DropDownTabs {
  cursor: pointer;
  border-radius: 20px;
  min-width: 120px;
  position: relative;
  padding: 5px 20px 5px 8px;
  color: #fff;
  background: $secondaryColor;
  height: 27px;
  font-size: 12px;

  .name {
    display: block;
    text-align: center;
  }

  .drop-down-active {
    position: relative;
    width: 100%;

    .arrow {
      position: absolute;
      right: -13px;
      top: 0px;
      transform: rotate(0deg);
      width: 10px;
      height: 5px;
    }

    .arrow-active {
      top: 12px;
      transform: rotate(180deg);
    }
  }

  .drop-down-list {
    position: absolute;
    top: 25px;
    left: 0;
    background: #fff;
    width: 100%;
    border-radius: 12px;
    border: 1px solid $secondaryColor;
    display: none;
    padding: 0;
    overflow: hidden;
    margin: 5px 0 0;

    &--active {
      display: block;
    }
  }

  .tab-name {
    display: block;
    width: calc(100% - 4px);
    margin: 0 auto;
    border-radius: 12px;
    text-indent: 10px;
  }
  .drop-down-list__item {
    border-radius: 20px;
    color: $secondaryColor;

    height: 20px;
    display: flex;
    align-items: center;
    position: relative;
    //width: 116px;
  }
  .drop-down-list__item:not(:first-child) {
  }

  .drop-down-list__item:not(:last-child)::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    right: 0;
    display: block;
    background: $secondaryColor;
    width: 100%;
    height: 1px;
  }
  .drop-down-list__item:hover > .tab-name {
    background: $secondaryColor;
    color: #fff;
    cursor: pointer;
  }
}
