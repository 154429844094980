@import "../../theme/variables.scss";

.rankings {
  position: relative;

  //ref ranking
  .ref-ranking {
    .rankings-header-prize-item {
      margin: 10px 0;
    }
    @include XGA {
      margin:0;
    }
  }

 
  .Loading {
    height: 50vw;
    @include XGA {
      height: 20vw;
    }
    @include HD {
      height: 300px;
    }
  }

  .desc-raking {

      font-weight: 700;
    

  }


  .DropDownTabs {
    flex-shrink: 0;
  }
  .ButtonTab {
    margin-right: 0;
    font-weight: normal;
  }

  .ButtonTab:nth-child(3) {
    color:  $secondaryColor;
    border-color: $secondaryColor;
    &.active {
      color: #fff;
      border-color:  $secondaryColor;
      background:  $secondaryColor;
    }
  }

  .rankings-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .rankings-header {
    background: #fff;
    padding: 15px 24px;
    border-radius: 12px;
    // box-shadow: -10px 15px 22px 7px rgba(0, 0, 0, 0.078);

    @include XGA {
      padding: 21px;
    }

    .rankings-header-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 6px;
      @include XGA {
        margin-bottom: 16px;
      }
    }

    .rankings-header-prize {
      @include XGA {
        display: flex;
        justify-content: space-between;
      }
    }

    .rankings-header-prize-item {
      display: flex;
      align-items: center;
    }

    .rankings-header-prize-value {
      background: $tertiaryColor;
      width: 92px;
      height: 38px;
      border-radius: 6px;
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      @include XGA {
        flex-shrink: 0;
        height: 72px;
      }
    }
    .rankings-header-prize-value-currency {
      margin-left: 10px;
      font-size: 20px;
      align-self: end;
      font-weight: 500;
      display: block;
      margin-bottom: 3px;

      @include vHD {
        align-self: center;
        margin-bottom: -3px;
      }
    }

    .rankings-header-prize-desc {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      @include XGA {
        font-size: 15px;
      }

      align-items: center;

      &--under {
        display: block;
        color: #657498;
        @include XGA {
          margin-top: 5px;
        }
      }
    }
  }

  .rakings-user-raking {
    .rakings-user-raking-header {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      @include XGA {
        margin-top: 62px;
      }
    }
    .rakings-user-raking-cta-group {
      display: flex;
    
      flex-wrap: wrap;
      gap:8px;
      align-items: center;
      
      
    }
   
  }


  .contest-header {

    @include XGA {
      //margin-bottom: 50px;
    }

    .body {
      @include XGA {
        padding: 21px 31px;
        background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
        box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
      }

      .col {
        @include HD {
          width: 48%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

        }
      }

      .col:first-of-type {
        max-width: 494px;
      }
    }

    .prizes {
      @include XGA {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .prize {
        display: flex;
        margin-bottom: 24px;

        @include XGA {
          display: flex;
          align-items: center;
          margin: 15px 0 10px;
        }

        .box {
          font-size: 10px;
          padding: 8px 12px;
          background-color: #FE920F;
          border-radius: 6px;
          width: 65%;
          text-align: center;
          color: #fff;

          @include XGA {
            width: 110px;
          }

          span {
            font-size: 24px;
            font-weight: 600;
          }
        }

        .name {
          font-size: 22px;
          padding-left: 30px;

          @include XGA {
            width: 90px;
            padding-left: 12px;
          }
        }
      }
    }

    .title {
      margin: 0;
      font-size: 20px;
      display: flex;
      align-items: center;
    }

    .group {
      background: $secondaryColor;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 7px;
      color: #fff;
      font-size: 14px;
    }

    .section-title {
      margin: 12px 0;
      font-size: 14px;
      @include HD {
        margin-top: 0;

      }
    }

    .content-border {
      border: 1px solid   $secondaryColor;
      border-radius: 12px;
      padding: 18px 24px;

      @include HD {
        display: flex;
        justify-content: space-between;

        p {
          max-width:190px;

          span {
            display: block;
          }
        }
        
      }
    }
   

    .footer {
      margin-top: 15px;
     
      @include HD {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          order:2;
        }
      }
    }
  }

  .consumer-header {

    .ranking-list {
      padding: 0;
      .list-item {
        list-style: none;
       
      }
      
    }
  }
}

.timetravel {
  .Title {
    padding-bottom: 0;
  }

  .time-travel-contest-header {
    .top {
      text-align: center;

      h2 {
        font-size: 16px;
        margin-bottom: 2em;

        @include vHD {
          font-size: 24px;
          margin-bottom: 1em;
        }
      }

      p {
        &.h2 {
          @include vHD {
            font-size: 24px;
            margin: 3em 0;
          }
        }
      }

      .timetravel-editions {
        margin: 30px auto;
        max-width: 970px;

        @include vHD {
          margin: 50px auto;
        }

        .edition {
          position: relative;
          margin-top: 40px;

          @include vHD {
            margin-top: 50px;
          }

          @include HD {
            margin-top: 60px;
          }

          h3 {
            @include vHD {
              font-size: 1.5em;
            }
          }

          .image {
            position: relative;
            margin: 0 10%;
            width: 80%;
            border-radius: 12px;
            overflow: hidden;

            @include vHD {
              width: 57%;
              margin: 0;
            }
          }

          img {
            display: block;
            width: 100%;
            height: auto;
          }

          .box-wrap {
            margin-top: -5%;

            @include vHD {
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              display: flex;
              width: 48%;
              align-items: center;
              margin: 0;
            }
          }

          .box {
            position: relative;
            z-index: 1;
            width: 100%;
            color: #fff;
            padding: 15px 5%;
            background-color: $primaryColor;
            border-radius: 8px;

            @include vHD {
              padding: 20px 10% 30px;
            }
          }

          .Button {
            margin-top: 20px;
            max-width: 280px;
          }

          &.inactive {
            
            .box {
              background-color: #58678F;
              color: rgba(255,255,255,0.6);

              .Button {
                opacity: 0.6;
              }
            }
          }

          &.completed {
            .image {
              &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.7);
              }

              &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                z-index: 3;
                width: 100%;
                height: 100%;
                background-image: url(./img/check.svg);
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
      }
    }

    .body {
      padding: 21px 31px;
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      background: transparent;
      background-color: $primaryColor;
      color: #fff;
    }

    .prizes {
      @include vHD {
        justify-content: flex-start;
      }

      .prize {

        .box {
          background-color: #fff;
          color: $primaryColor;

          @include XGA {
            width: 175px;
          }
        }

        .name {
          @include XGA {
            br {
              display: none;
            }
            width: 140px;
          }
        }

        @include vHD {
          margin-right: 30px;
        }
      }
    }
  }

  .timetravel-editions-nav {
    display: flex;
  }

  .ButtonTab {
    margin: 0 10px;
    &.primary {
      margin-left: 10px;
      border-color: #ABCB0C;
      color: #ABCB0C;

      &.active {
        background-color: #ABCB0C;
        color: #fff;
      }
    }

    &.secondary {
      margin-left: 10px;
      border-color: #FEB80C;
      color: #FEB80C;

      &.active {
        background-color: #FEB80C;
        color: #fff;
      }
    }

    &.tertiary {
      margin-left: 10px;
      border-color: #6C3B90;
      color: #6C3B90;

      &.active {
        background-color: #6C3B90;
        color: #fff;
      }
    }
  }
}

.contest-footer {
  padding-top: 40px !important;
  text-align: center;

  h3 {
    margin-bottom: 2em;
  }

  .footer-h3 {
    position: relative;
    color: $primaryColor;
    margin-top: 3em;

    span {
      z-index:1;
      position: relative;
      display: inline-block;
      padding: 0 15px;
      background-color: #f4f5f9;
    }
    
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #BEC3D2;
    }
  }

  p {
    text-align: left;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    @include vHD {
      text-align: center;
    }
  }
}

.christmas-rankings {

  .contest-header {

    .body {
      @include XGA {
        color: #fff;
        background-size: cover;
        background-image: url(./img/christmas-bg.png);
      }
    }

    .group {
      background-color: $primaryColor;

      @include XGA {
        background-color: #fff;
        color: $primaryColor;
      }
    }

    .prizes {
      padding: 25px 25px 5px;
      background-size: cover;
      background-image: url(./img/christmas-bg-m.jpg);
      border-radius: 6px;

      @include XGA {
        background-image: none;
        padding: 0;
      }

      .prize {
        .name {
          color: #fff;
          font-size: 18px;

          @include XGA {
            font-size: 20px;
            //min-width: 160px;

            br {
              display: none;
            }
          }
        }

        .box {
          background-color: #fff;
          color: $primaryColor;

          @include XGA {
            width: 180px;
          }
        }
      }
    }
  }

  .ButtonTab:nth-child(3).active {
    border-color: #37549C;
    background: #37549C;
  }
}