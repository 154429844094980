@import "../../theme/variables.scss";

.RankingUserStats {
  background: #fff;
  margin-top: 25px;
  padding: 20px 0;


  &.salespoint {

    .arrow {
      display: inline-block;
      
      margin-left: 10px;
      transform: rotate(180deg);
      margin-bottom: 2px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        

       
      }
      &.active {
        transform: rotate(0deg);
      }
      // transform: translate(90deg);
    }

    .list-item {
      // border:1px solid red;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    
    .main-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 6px 0;

   

      &.active {
        background: $tertiaryColor;
        cursor: pointer;

        .list-item-content {
        
          color: #fff;
          
        }
        .list-item-score {
          color:#fff;
        }
        .list-item--space {
          border-bottom: 1px solid $tertiaryColor;
        }
      }
    }


    .list-item {
        // padding: 0;
        background: #fff;
    }
    .sublist-wrapper {
      padding: 0;
    }
    .sublist {
      display: none;
      background: #fff;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0;
      border-bottom: 1px solid $tertiaryColor;
       .list-item-content {
      
        color: $tertiaryColor;
       }
       .list-item-score {
        color: $tertiaryColor;
       }

       &.active {
        display: flex;
       }
    }
    

    .list-item-content {
      font-weight: 400;
      color: #000;
      
    }
    .list-item-number {
      font-weight: 700;
    }
   
 
   
   
  }

  .rankings-wrapper-list {
    @include HD {
      width: 90%;
      margin: 0 5%;
      max-width: 1170px;

      margin: 0 auto;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    .bp {
      margin-left: 30px;

      @include vHD {
        margin-left: 50px;
      }
    }
    .score {
      margin-right: 15px;
      @include vHD {
        margin-right: 15px;
      }
    }
  }

  .list {
    padding: 0;
  }

  .list-item {
    display: flex;
    justify-content: space-between;

    padding: 6px 0;

    &--space {
      border-bottom: 1px solid #f4f5f9;
    }
  }

  .list-separator {
    list-style: none;
  }

  .list-item--active {
    background: $tertiaryColor;

    &.timetravel {
      background-color: #ABCB0C;
      .list-item-name,
      .list-item-score {
        color: #fff;
      }
    }

    .list-item-number,
    .list-item-score {
      color: #fff;
    }
  }

  .timetravel {
    .list-item-score {
      color: #ABCB0C;
    }
  }

  .list-item-content {
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }
  .list-item-number {
    display: inline-block;
    width: 30px;
    text-align: center;

    @include vHD {
      width: 50px;
    }
  }
  .list-item-name {
    display: inline-block;
    width: 120px;
    @include vHD {
      width: 160px;
    }
  }
  .list-item-bp {
    font-weight: 400;
  }

  .list-item-score {
    color: $secondaryColor;
    font-weight: bold;
    margin-right: 21px;
  }

  .dot-btn {
    height: 40px;
    width: 20px;
    margin-left: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "...";
      display: block;
      text-decoration: underline;
      font-size: 15px;
      color: #001854;
      height: 30px;
      width: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include vHD {
      margin-left: 50px;
    }
  }

.show-more-btn {
  height: 40px;
  width: fit-content;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  color: #0C235C;
  text-align: left;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 7px;
  }
  
 

  @include vHD {
    margin-left: 50px;
  }
}
}
