@import "../../theme/variables.scss";

.CountdownModule {
  position: relative;
  width: 100%;
  height: 100%;
  //padding: 16px;
  border-radius: 12px;
  background-color: #2d1d38;
  background-image: url(./img/clock-green.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding-bottom: 5px;
  border-radius: 0;
  border-bottom-right-radius:12px;
  border-bottom-left-radius:12px;

  @include HD {
    //padding: 24px;
    //background-image: url(./img/klepsydra.png);
    background-color: #352242;
    background-position: center;
    border-bottom-left-radius:0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &.bonus {
    background-color: #200c51;
    background-image: url(./img/clock-green-bonus.jpg);
    background-size: cover;
  }

  .countdown-title {
    padding: 20px 0 0;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    display: block;
    //background: rgba(255,255,255,0.1);
  }

  .countdown-time {
    border-radius: 6px;
    padding: 5px 0 30px;
    background: rgba(255,255,255,0.2);
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 10px auto 15px;
    padding: 15px 10px 15px;

    @include HD {
      //padding: 30px 10px 25px;
    }

    .time-part {
      position: relative;
      color: #fff;
      font-weight: 700;
      font-size: 25px;
      line-height: 36px;
      text-align: center;
      padding: 0 10px;

      &:after {
        content: ":";
        position: absolute;
        top: 0;
        right: -3px;
        line-height: 1.3;
        color: rgba(254, 183, 12, 1);
      }

      &:last-child:after {
        display: none;
      }

      @include HD {
        //font-size: 50px;
        line-height: 1.1;
        //padding: 0 27px;

        &:after {
          line-height: 1;
          right: -6px;
        }
      }

      .part-name {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  .Button {
    margin-top: 15px;
    max-width: 240px;

    @include HD {
      position: absolute;
      right: 24px;
      top: 50px;
    }
  }
}