@import "../../theme/variables.scss";

.nav {
  z-index: 910;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 420px;
  padding: 32px 0 0;
  transform: translate(105%, 0);
  transition: transform, 0.6s $CubicEaseInOut;
  height: 100vh;

  @include XGA {
    max-width: 375px;
  }

  .nav-inner {
    display: flex;
    flex-direction: column;
    padding: 40px 25px 25px;
    background-color: $primaryColor;
    min-height: 100%;
    overflow-y: scroll;

    @include HD {
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #001854;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ffffff36;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ffffff36;
      }

      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none; /* Firefox */
      // &::-webkit-scrollbar {
      //   display: none;
      // }
    }

    &:after {
      content: "";
      position: absolute;
      top: 32px;
      left: 0;
      height: 65px;
      width: 100%;
      background-color: $primaryColor;
    }
  }

  &.active {
    transform: translate(0, 0);
  }

  .logo {
    position: absolute;
    z-index: 9;
    top: 50px;
    left: 25px;

    @include HD {
      display: none;
    }
  }

  .close {
    z-index: 10;
    position: absolute;
    top: 48px;
    right: 5vw;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include HD {
      right: 25px;
    }
  }

  .profile-section {
    padding-bottom: 10px;
    border-bottom: 1px solid #d9deeb;
  }

  .nav-links {
    padding: 20px 0 20px;

    .nav-item {
      position: relative;
      margin: 20px 0 5px;
      padding: 5px 0;
      display: block;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      text-decoration: none;
      cursor: pointer;

      svg {
        position: absolute;
        top: 5px;
        right: 0;
      }

      &.nav-dropdown {
        svg {
          right: -5px;
        }
      }
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .subnav {
    max-height: 0;
    transition: max-height 0.4s $QuadEaseOut;
    overflow: hidden;

    .subnav-item {
      color: #fff;
      display: block;
      text-decoration: none;
      font-weight: 400;
      padding: 5px 0;
      margin: 10px 0;
      cursor: pointer;
    }

    &.active {
      max-height: 250px;
    }
  }

  .nav-footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    font-weight: 700;
    color: #fff;
    text-align: center;

    .nav-item {
      cursor: pointer;
    }
  }
}
