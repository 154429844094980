@import "../../theme/variables.scss";

.RedeemCode {
  padding: 50px 0;
  margin-top: -55vw;

  @include XGA {
    margin-top: -150px;
  }

  @include HD {
    margin-top: -195px;
  }

  .redeem-inner {
    width: 90%;
    margin: 0 auto;
  }

  form {
    position: relative;
    z-index: 1;
    padding: 40px 0;

    label {
      color: #fff;
    }

    .input-item {
      max-width: 330px;
      margin: 0 auto 30px;

      input {
        color: #fff;
        border: 1px solid #fff;
        background-color: $primaryColor;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10%;
      right: -10%;
      background: $primaryColor;
      display: block;
      height: 100%;
      width: 120%;
      z-index: -1;
      transform: rotate(5deg);
    }
  }

  .show-redeemed-text {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    margin: 30px auto 0;
    color: #fff;
    max-width: 330px;
    z-index: 2;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    svg {
      margin-top: 3px;
        margin-left: 5px;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .redeemed-codes {
    max-width: 360px;
    margin: 30px auto;
    padding: 0;
    display: none;

    &.active {
      display: block;
      position: relative;
      z-index:10;
    }
    
    li {
      list-style: none;
      display: flex;
      padding: 10px 0;
      font-weight: 700;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      
      .date {
        width: 70px;
        margin-right: 20px;
      }

      .code {
        color: #FF2885;
      }
    }
  }
}