@import "../../theme/variables.scss";

.Payout {
  .earnings-box {
    max-width: 800px;
    padding: 0;

    @include HD {
      display: flex;
      max-width: 940px;
      width: 940px;
    }

    &.missing-user-data {
      h3, .earnings-current-number, .earnings-total-number {
        color: #817E7E;
      }
    }
  }

  .earnings-top {
    display: flex;
    justify-content: space-between;
    padding: 15px 5%;
   
    

    @include HD {
      flex-grow: 1;
      max-width: 350px;
      padding-right: 0;
      padding: 30px 0 20px 25px;
    }
  }

  .earnings-bot {
    padding: 15px 5%;
    text-align: left;
    font-size: .9em;
    color: #817E7E;
    border-top: 1px solid #EFEFEF;

    .desc {
      @include XGA {
        justify-content: flex-start !important;
  
      }
    }

    @include XGA {
      padding: 0;
    }

    & > p {
      @include XGA {

        margin-bottom: 0;
      }
    }

    ul {
      color: $primaryColor;
      text-align: left;
      padding: 0;
      padding-left: 16px;
      width: 100%;
      position: relative;
      @include XGA {
        margin-top: 0;
      }
  
    

      li {
        position: relative;
        &::before {
          content: "";
          height: 4px;
          width: 4px;
          background: $primaryColor;
          position: absolute;
          left: -10px;
          top:50%;
          transform: translateY(-50%);
        }
        font-weight: 700;
        list-style: none;
        
      }
    }

    p {
      width: 100%;
      @include HD {
        margin-top: 0
      }

      &.attention {
        @include HD {
          margin: 0;
        }
      }
    }

    @include HD {
      //display: flex;
      align-items: center;
      text-align: left;
      // width: 560px;
      border: 0;
      font-size: 11px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .color-red {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      img {
        display: block;
        margin-right: 15px;
      }
    }
  }

  .earnings-total {
    width: 50%;
    position: relative;
    text-align: center;
    @include HD {
      width: 120px;
    }

    .earnings-total-number {
      font-size: 30px;
      font-weight: 600;
      

      @include HD {
        font-size: 36px;
      }

      span {
        font-size: 14px;
        font-weight: 400;

        @include HD {
          font-size: 22px;
        }
      }

      &.smaller {
        font-size: 20px;

        @include HD {
          font-size: 25px;
        }
      }
    }
  }

  .earnings-current {
    width: 50%;
    max-width: 300px;

    @include HD {
      width: 190px;
    }

    .columns {
      display: flex;
 
      justify-content: center;
      > div {
        width: 50%;
   
      }

      .earnings-column {
        text-align: right;
        
        @include HD {
          width: 55%;
        }
      }

      .title-column {
        
        @include HD {
          width: 45%;
        }
      }
    }

    .earnings-current-number {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.4;
      width: 100%;
      text-align: center;

      @include HD {
        font-size: 36px;
      }
      span {
        font-size: 14px;
        font-weight: 400;

        @include HD {
          font-size: 22px;
        }
      }

      &.smaller {
        font-size: 25px;

        @include HD {
          font-size: 35px;
        }
      }
    }

    .Button {
      margin-top: 10px;
      height: 33px;

      @include HD {
        height: 40px;
      }
    }
  }

  .earnings-title {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .sub-text {
    font-size: 12px;
    color: #817e7e;
  }
  .sub-text-2 {
  text-align: center;
  }

  .stats-link {
    color: $primaryColor;
    text-align: center;
    margin: 2em 10%;
    font-size: 16px;

    @include HD {
      text-align: left;
      margin-left: 0;
      margin-right: 0;
      margin-top: 3.2em;
      padding-left: 30px;
      width: 230px;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .earnings-wrap {
    @include HD {
      display: flex;
    }
  }

  .link-to-profile {
    text-align: center;
    margin-top: .5em;
  }
}