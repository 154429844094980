@import "../../theme/variables.scss";
.QuizResultScreen {
  @include XGA {
    margin-top: 170px;
  }

  .Button {
    margin-top: 50px;
    max-width: 225px;
  }

  .heading {
    margin-top: 30px;
    margin-bottom: 60px;
    @include XGA {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      font-weight: 600;
      color: $primaryColor;
      font-size: 32px;
      margin-top: 15px;
      text-align: center;
      @include XGA {
        align-self: center;
        margin: 0;
        margin-left: 22px;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .body {
    background: #fff;
    padding: 12px 24px;
    border-radius: 12px;
    max-width: 667px;
    margin: 0 auto;
    text-align: center;
    color: $primaryColor;
    @include XGA {
      font-size: 24px;
    }
  }
}
