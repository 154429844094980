@import "../../theme/variables.scss";
.Kent {
  max-width: 1366px;
  min-height: 1000px;
  margin: 0 auto;
  background: #fff;
  background-image: url(./img/hero.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  @include XGA {
    background-image: url(./img/hero-desktop.jpg);
    //background: #f4f5f9;
  }

  .hero-section {
    position: relative;
    margin-top: -56px;
    padding-top: 56px;

    @include HD {
      margin-top: 0px;
    }
  }

  .glo-hero-content {
    position: relative;
    color: #fff;

    .Title {
      font-size: 10vw;
      width: 100%;
      font-weight: 600;
      line-height: 1.2em;

      span {
        font-weight: 400;
      }

      @include XGA {
        font-size: 54px;
        line-height: 54px;
        width: 700px;
        padding-bottom: 0;
      }

      @include HD {
        margin-top: 50px;
        font-size: 64px;
        line-height: 64px;
        width: 800px;
        padding-top: 0;
      }

      &.with-button {
        margin-top: 0;

        @include HD {
          padding-bottom: 0.3em;
        }
      }

      .ButtonBack {
        //align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }

    .kent-logo {
      margin: 20px 0 0;
      width: 112px;

      @include XGA {
        position: absolute;
        width: 225px;
        right: 60px;
        top: 30px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .sub-title {
      margin-top: -5vw;
      color: inherit;
      font-size: 5.3vw;
      text-align: left;
      width: 55vw;

      @include XGA {
        margin-top: 20px;
        font-size: 18px;
        line-height: 22px;
        width: 400px;
      }

      @include HD {
        font-size: 20px;
        line-height: 24px;
        width: 450px;
        margin-left: 100px;
      }
    }
  }

  .glo-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .static-items {
    position: relative;
    display: none;
    padding-bottom: 50px;

    .glo-wrapper {
      @include XGA {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 100%;
      }
    }

    @include XGA {
      display: block;
      margin-top: -70px;
    }

    @include HD {
      margin-top: -120px;
    }

    .static-item:not(:first-child) {
      margin-left: 16px;
    }

    .static-item {
      background: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;

      .item-img {
        width: 212px;
        height: 190px;
        display: block;
      }
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      @include HD {
        width: 269px;
        height: 248px;
      }
    }
  }

  .kent-swiper {
    padding: 30px 0 50px;

    @include XGA {
      max-width: 1030px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
    }

    .kent-swiper-slide {
      background: $primaryColor;
      border-radius: 12px;
      min-height: 270px;
      border-radius: 12px;
      color: #f4f5f9;
      font-size: 18px;
      overflow: hidden;
      background-image: url(./img/slider.jpg);
      background-size: cover;

      .top {
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 32px;

        @include XGA {
          height: 98px;
        }

        .icon {
          width: 48px;
          height: 48px;
          display: block;

          @include XGA {
            width: 66px;
            height: 66px;
          }
        }
      }

      .body {
        padding: 16px 24px;
        text-align: center;

        @include XGA {
          padding-top: 10px;
        }

        p {
          margin: 0;
        }
      }

      .title {
        font-size: 14px;
        @include XGA {
          margin-top: 10px;
          font-size: 21px;
          margin-bottom: 0;
        }
      }

      .desc {
        font-size: 14px;
        line-height: 22px;
        @include XGA {
          margin-top: 10px;
          font-size: 17px;
        }
      }
    }
  }

  .content-box {
    margin: 0 auto;
    width: 90%;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);

    @include XGA {
      margin-top: 60px;
      padding: 30px;
    }

    @include HD {
      padding: 40px;
    }
  }

  .card-control {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;

    .control-btn {
      font-size: 14px;
      color: $primaryColor;
      border: 2px solid $primaryColor;
      border-radius: 20px;
      padding: 10px 20px;
      cursor: pointer;

      &.active {
        color: #fff;
        font-weight: 700;
        background-color: $primaryColor;
        border: 2px solid $primaryColor;
      }
    }
  }

  .kent-boxes {
    @include XGA {
      display: flex;
      justify-content: center;
    }
  }

  .kent-box {
    position: relative;
    height: 126px;
    max-width: 290px;
    padding: 20px;
    margin: 70px auto;
    color: #fff;
    font-size: 18px;
    border-radius: 16px;
    background-color: $primaryColor;

    @include XGA {
      margin: 70px 40px;
      width: 290px;
    }

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 12px;
    }

    img {
      position: absolute;
      left: 42%;
      top: -25%;
      width: 128px;
      height: auto;
    }

    .price {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 20px;
      left: 59%;
      color: #0558c8;
      font-weight: 700;
      width: 86px;
      height: 86px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.9);
    }

    &.kent-blue {
      background-image: url(./img/blue-bg.jpg);
      background-size: cover;
    }
    &.kent-silver {
      color: $primaryColor;
      background-image: url(./img/silver-bg.jpg);
      background-size: cover;
    }
    &.kent-ssl {
      color: $primaryColor;
      background-image: url(./img/ssl-bg.jpg);
      background-size: cover;
    }
    &.kent-red {
      background-color: #af1d26;
    }
  }

  .info-boxes {
    @include XGA {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include HD {
    }
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    padding: 20px;
    margin: 20px 0;
    color: #fff;
    font-size: 18px;
    border-radius: 16px;
    text-align: center;
    background-color: $primaryColor;

    @include XGA {
      width: 47%;
    }

    @include HD {
      width: 23%;
    }

    p {
      margin: 0;
    }
  }

  .kent-title {
    padding: 0 5%;
    margin: 2em 0 0;
    display: flex;
    font-size: 26px;
    align-items: center;
    justify-content: center;

    @include XGA {
      font-size: 32px;
    }

    img {
      width: 110px;
      height: auto;
      margin: 0 15px;
    }
  }

  .footer-image {
    margin: 20px auto 50px;
    max-width: 700px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .footer-info {
    //margin-top: 40px;
    padding: 25px 0 50px;
    background-image: url(./img//footer-bg-m.jpg);
    background-size: cover;

    @include XGA {
      background-image: url(./img//footer-bg.jpg);
    }

    @include HD {
      padding: 60px 0 70px;
    }

    p {
      width: 70%;
      margin: 0 auto;
      color: #fff;
      text-align: center;
    }
  }

  .kent-slides {
    width: 90%;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .kent-slide {
    background: $primaryColor;
    border-radius: 12px;
    min-height: 270px;
    border-radius: 12px;
    color: #f4f5f9;
    font-size: 20px;
    overflow: hidden;
    width: 31%;
    margin-bottom: 25px;
    background-image: url(./img/slider.jpg);
    background-size: cover;

    .top {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 32px;

      @include XGA {
        height: 98px;
      }

      .icon {
        width: 48px;
        height: 48px;
        display: block;

        @include XGA {
          width: 66px;
          height: 66px;
        }
      }
    }

    .body {
      padding: 16px 24px;
      text-align: center;

      @include XGA {
        padding-top: 10px;
      }

      p {
        margin: 0;
      }
    }
  }
}
