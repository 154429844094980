@import "../../theme/variables.scss";
.GloUniq {
  max-width: 1366px;
  margin: 0 auto;
  background: #fff;


 
  @include XGA {
    background: #f4f5f9;
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
    margin-top: -56px;
    @include HD {
      margin-top: 0px;
    }
  }

  .glo-hero-content {
    position: absolute;
    top: 8%;
    color: #fff;

    @include HD {
      top: 0;
    }

    .Title {
      font-size: 10vw;
      width: 100%;
      font-weight: 600;
      line-height: 1.2em;

      @include XGA {
        font-size: 54px;
        line-height: 54px;
        width: 700px;
        padding-bottom: 0;
      }
      @include HD {
        margin-top: 37px;
        font-size: 64px;
        line-height: 64px;
        width: 800px;
      }

      .ButtonBack {
        //align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }

    .sub-title {
      margin-top: -5vw;
      color: inherit;
      font-size: 5.3vw;
      text-align: left;
      width: 55vw;

      @include XGA {
        margin-top: 20px;
        font-size: 18px;
        line-height: 22px;
        width: 400px;
      }
      @include HD {
        font-size: 20px;
        line-height: 24px;
        width: 450px;
        margin-left: 100px;
      }
    }
  }
  .hero-desc {
    color: inherit;
    text-align: center;
    font-size: 7vw;
    line-height: 10.3vw;
    font-weight: 600;
    position: absolute;
    left: auto;
    right: auto;
    width: 90%;
    bottom: 8%;
    color: #fff;

    @include XGA {
      bottom: 25%;
      font-size: 24px;
      line-height: 28px;
      width: 400px;
      text-align: left;
    }
    @include HD {
      font-size: 32px;
      line-height: 36px;
      width: 500px;
      margin-left: 100px;
      bottom: 20%;
    }
  }

  .glo-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .static-items {
    position: relative;
    display: none;
    padding-bottom: 50px;

    .glo-wrapper {
      @include XGA {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 100%;
      }
    }

    @include XGA {
      display: block;
      margin-top: -70px;
    }
    @include HD {
      margin-top: -120px;
    }

    .static-item:not(:first-child) {
      margin-left: 16px;
    }
    .static-item {
      background: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;

      .item-img {
        width: 212px;
        height: 190px;
        display: block;
      }
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      @include HD {
        width: 269px;
        height: 248px;
      }
    }
  }





  .uniq-swiper {
    padding: 50px 0;
    margin-top: -120px;




  

    @include XGA {
      display: none;
    }

    

  

    .uniq-swiper-slide {
      background: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      .slide-img {
        width: 212px;
        height: 190px;
        display: block;
        pointer-events: none;
      }
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
    }
  }

  .slider-desc {
    margin: 0 auto;
    text-align: center;
    padding: 0 5%;
    padding-bottom: 30px;

    @include XGA {
      max-width: 550px;
      span {
        display: block;
      }
    }
  }

  

  .uniq-slider-case {
    position: relative;

    @include XGA {
      max-width: 773px;
      margin: 0 auto;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      height: 35%;
      width: 100%;
      background: #f4f5f9;
    }
    .card {
      cursor: pointer;
      position: relative;
      border: 1px solid #dfdfdf;
      border-radius: 12px;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        display: block;
        height: 35%;
        width: 100%;
        background: #f4f5f9;
      }
      img {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .card-desc {
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      bottom: 3%;
      left: 50%;
      text-transform: uppercase;
      transform: translateX(-50%);
      width: 80%;
      text-align: center;
    }
    .footer {
      margin-top: 30px;
      padding: 0 25px;
    }
  }

  .core-section {
    text-align: center;
    position: relative;


    .ArrowButton {
      display: none;
      border:1px solid #fe920f;
      @include XGA {
        display: block;
      }
    }
  
    .swiper-cta-prev,.swiper-cta-next {
      top:30%;
      position: absolute;
      z-index: 2;
    }
    .swiper-cta-prev {
      left: -50px;
      transform: rotate(180deg);
    }
    .swiper-button-disabled {
      opacity: .5;
    }
    
    .swiper-cta-next {
    right: -50px;
    }
    // background: #f4f5f9;

    .top {
      padding: 40px;
      background: #f4f5f9;
    }
    .title {
      margin-bottom: 0;
      font-weight: 600;
      @include XGA {
        font-size: 32px;
      }
    }

    .top-desc {
      text-align: center;
    }

    .navigation {
      display: flex;
      justify-content: space-evenly;
      max-width: 400px;
      margin: 0 auto;

      @include XGA {
        margin-top: 36px;
      }

      button {
        width: 90px;
        color: #ff8d03;
        background: transparent;
        border: none;
        border-radius: 20px;
        border: 1px solid #ff8d03;
        font-size: 12px;
        height: 42px;
        cursor: pointer;

        @include vHD {
          width: 120px;
          height: 22px;
        }
      }

      .active {
        background: #ff8d03;
        color: #fff;
      }
    }
  }

  .uniq-feature {
    position: relative;
    @include XGA {
      width: 960px;
      margin: 0 auto;
    }

    .feature-title {
      font-size: 32px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 0;
      @include XGA {
        text-align: left;
      }
    }
    .feature-desc {
      text-align: center;
      font-size: 14px;
      @include XGA {
        text-align: left;
      }
    }
  }
  .uniq-feature-wrapper {
    margin-top: 50px;
    position: relative;
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;

    .box-wrapper {
      border: 1px solid #f4f5f9;
      border-radius: 12px;
      overflow: hidden;
      display: grid;
    }

    .box-body:nth-child(odd) {
      background: #f4f5f9;
    }
    .box-body:nth-child(even) {
      background: #fff;
    }
    .box-body:nth-of-type(1) {
      background: #f4f5f9;
      padding-right: 0 !important;
    }
    .box-body-content {
      display: flex;
      justify-content: space-around;
    }

    .uniq-feature-heading {
      padding: 0 25px;

      margin-bottom: 30px;

      @include XGA {
        position: absolute;
        left: 0;
        color: #fff;
        max-width: 470px;
        padding: 0;
      }
      @include HD {
        top: 60px;
      }
    }

    .uniq-feature-box {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      width: 330px;

      @include XGA {
        width: 470px;
      }

      @include XGA {
        top: 180px;
        left: 0;
        transform: translateX(0%);
      }
      @include HD {
        top: 250px;
      }

      &__desc {
        text-align: center;
      }

      .box-body {
        padding: 5px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        font-size: 12px;
        padding: 5px 15px;

        @include XGA {
          padding: 15px;
          grid-template-columns: 200px 1fr;
          br {
            display: none;
          }
        }
      }

      .half-box,
      .full-box {
        font-size: 12px;
        font-weight: 700;
      }
      .full-box {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .box-header {
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        text-align: center;
        margin-bottom: 10px;

        @include XGA {
          grid-template-columns: 200px max-content max-content;
          .half-box {
            color: #fff;
            font-size: 17px;
            font-weight: 600;
          }
        }

        .half-box:nth-of-type(1) {
          grid-column: 2 / span 1;
          margin-right: 10px;
          max-width: 70px;

          @include XGA {
            max-width: 150px;
          }
        }
        .half-box:nth-of-type(2) {
          grid-column: 3 / span 1;
          margin-right: 10px;
          @include XGA {
            margin-left: 30px;
          }
        }
      }
    }

    .uniq-bg {
      margin-top: 160px;
      width: 100%;
      display: block;

      @include XGA {
        margin-top: 0;
      }
    }
  }
  .uniq-video {
    padding: 0 24px;
    position: relative;
    &::before {
      position: absolute;
      height: 50%;
      width: 100%;
      background: #fff;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      content: '';
    }
   @include XGA {
     &::before {
       display: none;
     }
     
   }

    .video-wrapper {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      max-width: 569px;
      height: 100%;
      width: 100%;
      display: block;
      border-radius: 12px;
      overflow: hidden;
      @include XGA {
        margin-top: 0;
      }
      
    }
    .wrapper {
      @include XGA {
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        margin-top: 75px;
        align-items: center;
      }
    }
    .title {
      font-size: 32px;
      @include XGA {
        font-size: 34px;
        line-height: 40px;
        text-align: left;
        max-width: 415px;
      }
      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        @include XGA {
          font-size: 34px;
          line-height: 40px;
          text-align: left;
        }
      }
    }
  }
}
