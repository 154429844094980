@import "../../theme/variables.scss";
.Notifications {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
  }
  .list {
    padding: 0;
    list-style: none;
    margin: 0 auto;
    max-width: 640px;

    &.hidden {
      display: none;
    }
  }

  .body {
    height: 90px;
    user-select: none;
    position: absolute;
    left: 80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    padding: 15px 19px;
    border-radius: 12px;
    width: calc(100% - 80px);
    background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);

    @include XGA {
      left: 160px;
      width: calc(100% - 160px);
      height: 108px;
    }
  }
  .inner {
    position: relative;
    cursor: pointer;
    height: 108px;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    transition: all ease-in-out 0.3s;

    &:hover {
      transform: scale(1.1);
    }

    @include XGA {
      height: 128px;
    }

    .image {
      height: 108px;
      width: 108px;
      flex-shrink: 0;
      border-radius: 12px;
      overflow: hidden;

      @include XGA {
        width: 200px;
        height: 128px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .cta {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);

      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background: #000;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .title {
      font-size: 15px;
      line-height: 17px;
      color: #000000;
      min-height: 45px;

      &.color-secondary {
        color: $secondaryColor;
      }

      @include XGA {
        min-height: 55px;
      }
    }

    .footer {
      display: flex;
      position: relative;
      position: relative;
      color: #fff;
    }
    .copy {
      text-align: right;
      flex-shrink: 0;
      display: block;
      width: fit-content;
      height: 22px;
      font-size: 10px;
      text-decoration: underline;
      cursor: pointer;
      margin-top: -1px;
      color: $primaryColor;

      @include XGA {
        font-size: 12px;
        height: auto;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: $primaryColor;
      align-self: center;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      opacity: 0.5;
      @include XGA {
        transform: translateY(5px);
      }
    }
    .date {
      flex-shrink: 0;
      color: $primaryColor;
      font-size: 10px;
      display: flex;
      align-items: center;
      margin-top: -7px;
      opacity: 0.6;
      @include XGA {
        font-size: 12px;
        margin-top: 0;
      }

      svg {
        margin-right: 5px;
      }
    }
  }
}
