.Disclaimer {
  z-index: 100;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 66px;
  color: #000;
  background-color: #fff;
  border: 5px solid #000;
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
}