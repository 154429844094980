@import "../../theme/variables.scss";

.Dashboard {
  position: relative;
  width: 100%;
  max-width: 1170px;

  .payout-tooltip {
    position: absolute;
    padding: 12px;
    top:0;
    right: -20px;
    left: -20px;
    border-radius: 8px;
    background: $primaryColor;
    color: #fff;
    z-index: 5;
    font-size: 12px;

  

.mail {
    color: #fff;

    font-weight: 400;
}

   

    &.desktop {
      top:25px;
      transform: translateX(-65%);
      display: none;
      @include XGA {
        display: block;
      }
    }
    &.mobile {
      @include XGA {
        display: none;
      }
    }


    @include XGA {
      left: 0;
      right: 0;
    }


    .desc,.disclaimer {
      margin: 0;
    }
    .list-desc {
      margin-bottom: 0;
    }
  
    ul {
      padding-left: 14px;

     
      li {
  
        list-style: none;
        position: relative;
        font-weight: 700;
   
        &::before {
          content: "";
          height: 4px;
          width: 4px;
          background: #fff;
          position: absolute;
          left: -10px;
          top:50%;
          transform: translateY(-50%);
        }
      
        
      }
    }

  }

  .payout-content {
   position: relative;
   z-index: 5;
   margin-top: 20px;

   @include XGA {
    margin-top: 20px;
   }
 

    .link {
      font-weight: 700;
      text-decoration: underline;
      color: $primaryColor;
      display: flex;
      align-items: center;
      gap:5px;
      cursor: pointer;
      margin-top: auto;

      @include XGA {
        font-size: 16px;
      }
    }
  
  }

  h2 {
    font-size: 21px;

    @include HD {
      font-size: 30px;
      font-weight: 600;
    }
  }

  @include HD {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
    grid-column-gap: 35px;
  }

  .user-info {
    width: 90%;
    margin: 20px 5%;
    grid-column: 1/3;
    display: flex;
    flex-wrap: wrap;
    padding-top: 18px;
    padding-bottom: 18px;
    justify-content: space-between;

    @include HD {
      width: 100%;
      margin: 0px 0 0 0;
      display: flex;
      justify-content: space-between;
      padding-left: 22px;
      padding-right: 22px;
    }

    .hello {
      width: 33%;
      color: #001854;

      @include HD {
        width: 250px;
        padding-left: 6px;
      }

      .hello-name {
        @include HD {
          width: 40%;
          text-align: left;
          margin-top: 10px;
        }

        .user-name {
          font-size: 20px;
          font-weight: 700;
          line-height: 1;

          @include HD {
            font-size: 30px;
          }
        }
      }

      .hello-number {
        color: $primaryColor;
        font-size: 12px;
        margin-top: 10px;

        @include HD {
          font-size: 14px;
          margin-top: 15px;
        }

        .bat-number {
          font-size: 16px;
          font-weight: 700;

          @include HD {
            font-size: 20px;
          }
        }
      }
    }

    .tile-wrapper {
      width: 100%;
      position: relative;

      @include HD {
        display: flex;
        width: auto;

        overflow: hidden;
        border-radius: 12px;
      }
    }

    .earnings-total {
      position: relative;
      width: 100%;

      background-color: #00B1EB;
      padding: 12px 5%;
      margin: 12px 0;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;

      @include HD {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 12px;

        width: 145px;
        padding: 22px;
        flex-direction: column;
        margin: 0;
        align-items: flex-start;
      }

      .earnings-total-number {
        font-size: 20px;
        font-weight: 700;

        @include HD {
          font-size: 30px;
        }

        span {
          display: inline-block;
          font-size: 0.8em;
          font-weight: 700;
          padding-left: 3px;
        }

        &.smaller {
          font-size: 25px;
        }
      }
    }

    .earnings-current {
      background-color: #e7ecfa;
      border-radius: 12px;
      width: 66%;
      padding: 12px 4.5%;

      &.missing-user-data {
        h3,
        .earnings-current-number,
        .earnings-total-number,
        .sub-text {
          color: #817e7e;
        }
      }

      .color-red {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        img {
          display: block;
          margin-right: 15px;
        }
      }

      @include HD {
        width: 356px;
        padding: 22px;
      }

      .columns {
        display: flex;
        align-items: center;

        .title-column {
          width: 50%;
          color: #001854;
          opacity: .5;
        }

        .earnings-column {
          text-align: right;
          width: 50%;
        }
      }

      .earnings-current-number {
        font-size: 30px;
        font-weight: 700;
        line-height: 1;
        width: 100%;
        opacity: .5;

        @include HD {
          font-size: 50px;
        }

        span {
          display: inline-block;
          padding-left: 3px;
          font-size: 0.8em;
          font-weight: 700;

          @include HD {
            font-size: 22px;
            padding-left: 5px;
          }
        }

        &.smaller {
          font-size: 25px;

          @include HD {
            font-size: 35px;
          }
        }
      }

      .Button {
        margin-top: 10px;
        height: 33px;

        @include HD {
          height: 40px;
        }
      }
    }
  }

  .dash-rank {
    position: relative;
    width: 90%;
    margin: 0 5%;

    .rank-wrap-box {
      padding-top: 18px;
      padding-bottom: 18px;
      min-height: 502px;
    }

    @include HD {
      margin: 32px 0 0;
      width: 100%;
    }

    h2 {
      font-size: 16px;
      line-height: 1.1;
      margin-top: 0;
      color: $primaryColor;

      @include HD {
        font-size: 20px;
      }
    }

    .top-title {
      display: flex;
      width: 50%;

      @include HD {
        width: 55%;
      }

      h2 {
        margin-right: 10px;
        width: 100px;

        @include HD {
          width: 220px;
        }
      }

      .ArrowButton {
        width: 28px;
      }
    }

    .bottom-title {
      @include HD {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      h2 {
        margin: 1em 0 0.5em;
      }

      a {
        font-size: 12px;
        font-weight: 400;

        @include HD {
          margin-bottom: 1em;
        }
      }
    }

    .counter-wrap {
      @include vHD {
        display: flex;
        align-items: center;
      }
    }

    .rank {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0;
      font-weight: 700;
      padding-bottom: 10px;
      border-bottom: 1px solid #e7ecfa;

      &.rank-head {
        font-size: 12px;
        font-weight: 400;
      }

      &.rank-before,
      &.rank-after {
        font-weight: 400;
      }

      &.rank-after {
        border: 0;
      }
    }

    .next-rank {
      padding: 15px 5%;
      background-color: #e7ecfa;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
      color: $primaryColor;

      @include vHD {
        width: 160px;
        padding: 35px 10px;
        flex-direction: column;
        margin: 20px 0;
      }

      .needed {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include vHD {
          justify-content: center;
        }

        .num {
          font-size: 16px;
          font-weight: 700;
          margin-right: 8px;

          @include vHD {
            font-size: 20px;
          }
        }

        img {
          width: 26px;
          height: auto;

          @include vHD {
            width: 32px;
          }
        }

        .text {
          display: block;
          width: 100%;
          font-size: 12px;

          @include vHD {
            text-align: center;
            font-size: 14px;
          }
        }
      }

      .next-bonus {
        font-size: 30px;
        font-weight: 700;

        @include vHD {
          margin-top: 15px;
        }
      }
    }

    .DropDownTabs {
      position: absolute;
      top: 20px;
      right: 5%;
      z-index: 20;
      background-color: #fff;
      border: 1px solid #001854;
      color: #001854;
      font-weight: 700;
      border-radius: 12px;

      .arrow {
        svg {
          path {
            fill: #001854;
          }
        }
      }
    }
  }

  .dash-news {
    grid-column: 2/3;
    // grid-row: 1/3;

    // when counter is on grid-row: 2/4;
    grid-row: 2/3;
    width: 100%;

    h2 {
      margin-left: 5%;

      @include HD {
        margin-left: 0;
      }
    }

    @include HD {
      max-width: 568px;
    }

    .swiper {
      padding-left: 5%;

      @include HD {
        padding-left: 0;
      }
    }
  }

  .dash-countdown {
    width: 100%;

    @include HD {
      margin: 0;
      width: 320px;
    }
  }

  .earnings-title {
    margin: 0;
    font-size: 14px;
    font-weight: 700;

    @include HD {
      font-size: 18px;
    }
  }

  .sub-text {
    font-size: 12px;

    @include HD {
      font-size: 14px;
    }
  }

  .dash-shortcut {
    width: 90%;
    margin: 20px 5%;
    grid-column: 1/3;
    box-shadow: none;
    background-image: url(./img/shortcut_bg_m.jpg);
    background-size: cover;
    background-color: #001854;
    color: #fff;
    font-size: 16px;
    font-weight: 700;

    @include XGA {
      display: flex;
      justify-content: space-between;
      padding: 32px;
      font-size: 20px;
      background-image: url(./img/shortcut_bg.jpg);
    }

    @include HD {
      width: 100%;
      margin: 0px 0 0;
    }

    .dash-shortcut-content {
      ul {
        padding: 0;
        margin: 30px 0;

        @include XGA {
          display: flex;
          margin-top: 20px;
          margin-bottom: 0;
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 8px 0;

          @include XGA {
            margin-right: 25px;
          }

          svg {
            margin-right: 10px;
            height: 30px;

            @include XGA {
              height: 36px;
            }
          }
        }
      }
    }

    .shortcut-box {
      padding: 20px;
      color: $primaryColor;
      text-align: center;
      background-color: #e7ecfa;
      border-radius: 12px;

      @include XGA {
        height: 200px;
        width: 40%;

        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      p {
        margin-top: 0;
      }
    }
  }
}
