@import "../../theme/variables.scss";

.Tabs {
  z-index: 100;
  position: fixed;
  bottom: 36px;
  height: 72px;
  width: 100%;
  display: flex;
  background-color: #0e2b63;
  color: #fff;
  font-weight: 700;

  &.disclaimer {
    bottom: 102px;

    @include HD {
      bottom: auto;
    }
  }

  @include HD {
    position: absolute;
    bottom: auto;
    top: 32px;
    width: 380px;
    left: calc(50% - 190px);
    background: transparent;
    height: 76px;
  }

  &.with-info {
    @include HD {
      top: 72px;
    }
  }

  .tab-nav {
    display: flex;
    width: calc(50% - 45px);

    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
      padding-bottom: 15px;
      color: #fff;
      text-decoration: none;
      text-align: center;
      font-size: 10px;

      &:active {
        background-color: #0e286b;

        @include HD {
          background-color: #fff;
        }
      }

      @include HD {
        color: #0c235c;
        padding-bottom: 10px;
      }

      svg {
        path {
          @include HD {
            fill: #0c235c;
          }
        }
      }

      .to-home {
        svg {
          path {
            @include HD {
              fill: transparent;
              stroke: $primaryColor;
            }
          }
        }
      }
    }
    .disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .tab-btn-sale {
    transform: translate(0, -32px);

    @include HD {
      transform: translate(0, -24px);
    }

    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      height: 100px;
      width: 90px;
      color: #fff;
      text-decoration: none;
      text-align: center;
      font-size: 10px;

      @include HD {
        color: #0c235c;
      }

      span {
        display: block;
        line-height: 1.1;
        transform: translate(0, -12px);

        @include HD {
          br {
            display: none;
          }
        }
      }
    }
  }
  .disabled {
    filter: grayscale(1);
    a {
      cursor: not-allowed;
    }
  }
}
