@import "../../theme/variables.scss";
.GloHyper {
  max-width: 1366px;
  margin: 0 auto;
  background: #fff;

  @include XGA {
    background: #f4f5f9;
  }

  .quiz {
    padding: 0 24px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include XGA {
      margin-top: 40px;
    }

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      content: "";
      display: block;
      height: 100%;
      width: 100%;

      background: #fff;
      @include XGA {
        display: none;
      }
    }
    .title {
      text-align: center;
      font-size: 20px;
    }
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
    margin-top: -56px;
    @include HD {
      margin-top: -35px;
    }
  }

  .glo-hero-content {
    position: absolute;
    top: 8%;
    color: #fff;

    .Title {
      font-size: 15.5vw;
      width: 150px;
      font-weight: 600;
      line-height: 15.5vw;

      @include XGA {
        font-size: 54px;
        line-height: 54px;
        width: 600px;
        padding-bottom: 0;
      }
      @include HD {
        margin-top: 20px;
        font-size: 64px;
        line-height: 64px;
      }
      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
    .sub-title {
      margin-top: -10vw;
      color: inherit;
      font-size: 5.3vw;
      text-align: left;
      width: 55vw;

      @include XGA {
        margin-top: 30px;
        font-size: 18px;
        line-height: 22px;
        width: 400px;
      }
      @include HD {
        font-size: 20px;
        line-height: 24px;
        width: 500px;
        margin-left: 100px;
      }
    }
  }

  .glo-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .power-tobacco {
    display: flex;
    align-items: center;

    .bullet {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 4px;

      &.empty {
        border: 2px;
      }

      &.blue {
        background: #196bd0;
      }
      &.green {
        background: #7cc12d;
      }
      &.green-empty {
        border: 1px solid #7cc12d;
      }
    }
  }

  .new-taste {
    position: relative;

    max-width: 1170px;
    // margin-left: 24px;

    @include XGA {
      margin: 0 auto;
      max-width: 960px;
    }

    .swiper-wrapper {
      @include XGA {
        display: flex;
        justify-content: space-between;
      }
    }

    .title {
      text-align: center;
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      width: 80%;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
      @include XGA {
        margin-bottom: 45px;
      }
    }
    .cards {
      list-style: none;
      padding: 0;
      width: 100%;
      height: 100%;

      .card {
        border-radius: 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: transparent;
        min-height: 200px;
        box-shadow: none;
        padding: 0;

        @include XGA {
          width: 460px;
          height: 365px;
        }

        .card-head {
          padding: 12px 0 0 6%;
          color: #fff;
          font-weight: 700;
          height: 54vw;

          @include VGA {
            height: 56vw;
          }

          @include XGA {
            height: 200px;
          }

          h3 {
            margin-bottom: 1.5em;
            font-size: 5vw;

            @include XGA {
              font-size: 30px;
              margin-bottom: 1em;
              margin-top: 10px;
            }
          }

          p {
            font-size: 3.5vw;
            padding-right: 48%;

            @include XGA {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }

        .card-inner {
          display: flex;
          align-items: center;
          height: 36px;
          margin-top: 10px;

          svg {
            width: 36px;
            height: 36px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .power-tobacco-wrapper {
    display: flex;
    margin-left: 10px;
  }
  .glo-swiper {
    padding: 50px 0;
    margin-top: -200px;
    @include XGA {
      max-width: 1030px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
    }
    @include HD {
      margin-top: -335px;
    }

    .glo-swiper-slide {
      background: #fff;

      border-radius: 12px;
      min-height: 270px;
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      overflow: hidden;

      .top {
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f4f5f9;
        @include XGA {
          height: 90px;
        }
        .icon {
          width: 48px;
          height: 48px;
          display: block;

          @include XGA {
            width: 66px;
            height: 66px;
          }
        }
      }
      .body {
        padding: 16px 24px;
        text-align: center;

        @include XGA {
          padding-top: 10px;
        }
      }
      .title {
        font-size: 14px;
        @include XGA {
          margin-top: 10px;
          font-size: 21px;
          margin-bottom: 0;
        }
      }
      .desc {
        font-size: 14px;
        line-height: 22px;
        @include XGA {
          margin-top: 10px;
          font-size: 17px;
        }
      }
      .disc {
        font-size: 12px;
      }
    }
  }

  .slider-desc {
    margin: 0 auto;
    text-align: center;
    padding: 0 5%;
    padding-bottom: 30px;

    @include XGA {
      max-width: 550px;
      span {
        display: block;
      }
    }
  }

  .glo-slider-case {
    position: relative;

    @include XGA {
      max-width: 773px;
      margin: 0 auto;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      height: 35%;
      width: 100%;
      background: #f4f5f9;
    }

    .card {
      cursor: pointer;
      position: relative;
      border: 1px solid #dfdfdf;
      border-radius: 12px;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        display: block;
        height: 35%;
        width: 100%;
        background: #f4f5f9;
      }
      img {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .card-desc {
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      bottom: 3%;
      left: 50%;
      text-transform: uppercase;
      transform: translateX(-50%);
      width: 80%;
      text-align: center;
    }
    .footer {
      margin-top: 30px;
      padding: 0 25px;
    }
  }

  .core-section {
    text-align: center;

    // background: #f4f5f9;

    .top {
      padding: 40px;
      background: #f4f5f9;
    }
    .title {
      margin-bottom: 0;
      font-weight: 600;
      @include XGA {
        font-size: 32px;
      }
    }

    .top-desc {
      text-align: center;
    }

    .nav {
      display: flex;
      justify-content: space-evenly;
      max-width: 400px;
      margin: 0 auto;

      @include XGA {
        margin-top: 36px;
      }

      button {
        width: 90px;
        color: #ff8d03;
        background: transparent;
        border: none;
        border-radius: 20px;
        border: 1px solid #ff8d03;
        font-size: 12px;
        height: 22px;
        cursor: pointer;
      }
      .active {
        background: #ff8d03;
        color: #fff;
      }
    }
  }

  .uniq-feature {
    position: relative;
    @include XGA {
      width: 960px;
      margin: 0 auto;
    }

    .feature-title {
      font-size: 32px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 0;
      @include XGA {
        text-align: left;
      }
    }
    .feature-desc {
      text-align: center;
      font-size: 14px;
      @include XGA {
        text-align: left;
      }
    }
  }
  .uniq-feature-wrapper {
    margin-top: 50px;
    position: relative;
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;

    .box-wrapper {
      border: 1px solid #f4f5f9;
      border-radius: 12px;
      overflow: hidden;
      display: grid;
    }

    .box-body:nth-child(odd) {
      background: #f4f5f9;
    }
    .box-body:nth-child(even) {
      background: #fff;
    }
    .box-body:nth-of-type(1) {
      background: #f4f5f9;
      padding-right: 0 !important;
    }
    .box-body-content {
      display: flex;
      justify-content: space-around;
    }

    .uniq-feature-heading {
      padding: 0 25px;

      margin-bottom: 30px;

      @include XGA {
        position: absolute;
        left: 0;
        color: #fff;
        max-width: 470px;
        padding: 0;
      }
      @include HD {
        top: 60px;
      }
    }

    .uniq-feature-box {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      width: 330px;

      @include XGA {
        width: 470px;
      }

      @include XGA {
        top: 180px;
        left: 0;
        transform: translateX(0%);
      }
      @include HD {
        top: 250px;
      }

      .desc {
        text-align: center;
      }

      .box-body {
        padding: 5px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        font-size: 12px;
        padding: 5px 15px;

        @include XGA {
          padding: 15px;
          grid-template-columns: 200px 1fr;
          br {
            display: none;
          }
        }
      }

      .half-box,
      .full-box {
        font-size: 12px;
        font-weight: 700;
      }
      .full-box {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .box-header {
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        text-align: center;
        margin-bottom: 10px;

        @include XGA {
          grid-template-columns: 200px max-content max-content;
          .half-box {
            color: #fff;
            font-size: 17px;
            font-weight: 600;
          }
        }

        .half-box:nth-of-type(1) {
          grid-column: 2 / span 1;
          margin-right: 10px;
        }
        .half-box:nth-of-type(2) {
          grid-column: 3 / span 1;
          margin-right: 10px;
          @include XGA {
            margin-left: 50px;
          }
        }
      }
    }

    .uniq-bg {
      margin-top: 160px;
      width: 100%;
      display: block;

      @include XGA {
        margin-top: 0;
      }
    }
  }
  .uniq-video {
    padding: 0 24px;

    .video {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      max-width: 569px;
      height: 100%;
      display: block;
      border-radius: 12px;
      overflow: hidden;
      @include XGA {
        margin-top: 0;
      }
      .video-bg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .wrapper {
      @include XGA {
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        margin-top: 75px;
        align-items: center;
      }
    }
    .title {
      font-size: 32px;
      @include XGA {
        font-size: 34px;
        line-height: 40px;
        text-align: left;
        max-width: 415px;
      }
      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        @include XGA {
          font-size: 34px;
          line-height: 40px;
          text-align: left;
        }
      }
    }
  }
}
