@import "../../theme/variables.scss";

.ButtonTab {

    min-width: 72px;
    padding: 0 10px;
    text-align: center;
    margin-right: 8px;
    background: transparent;
    border-radius: 24px;
    color: $tertiaryColor;
    height: 22px;
    border: 1px solid $tertiaryColor;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &.active {
      background: $tertiaryColor;
      color: #fff;
    }
  
}