@import "../../theme/variables.scss";
.SaleRules {
  text-align: left;

  .Title {
    position: relative;
    span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .regs-list {
    list-style: none;
    padding: 0;
    li {
      margin: 25px 0 0;
      padding: 0 0 25px;
      border-bottom: solid 1px #ff8d03;
      color: #000;

      .reg-item-footer {
        display: flex;
        justify-content: space-between;
      }

      h3 {
        font-size: 14px;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}
