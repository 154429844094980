@import "../../theme/variables.scss";

.VuseGo {
  max-width: 1366px;
  margin: 0 auto;
  background: #212121;
  overflow: hidden;
  margin-top: -56px;

  @include HD {
    margin-top: 0px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #0E2B63;
    //padding: 0 5%;

    @include vHD {
      font-size: 32px;
    }
  }

  .card {
    list-style: none;
    background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    overflow: hidden;
    margin: 0 auto;
    
    @include XGA {
      max-width: 970px;
    }

    .top {
      background: #2B2750;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      color: #fff;

      @include HD {
        min-height: 90px;
      }
    }

    .body {
      padding: 18px;
      text-align: center;

      @include HD {
        padding: 18px;
      }
    }
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .hero-content {
    position: relative;
    width: 90%;
    margin: 0 auto;
    color: #fff;

    @include XGA {
      position: absolute;
      top: 0;
    }

    @include HD {
      margin-top: 40px;
    }

    .title {
      font-size: 32px;
      font-size: clamp(14px, 7.5vw, 84px);
      font-weight: 400;
      line-height: 115%;

      .bold {
        font-weight: 600;
      }

      @include XGA {
        padding-bottom: 0;
        font-size: 25px;
        max-width: 800px;
        .br {
          display: none;
        }
      }
      @include HD {
        margin-top: 0px;
        font-size: 44px;
        transform: translate(100px, -70px);
        max-width: 1100px;
      }
      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }

    .page-title {
      margin-top: -4.7em;
      font-size: 40px;
      font-weight: 900;
      text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.6);
      text-align: center;
      line-height: 1.1;
      text-transform: uppercase;

      @include XGA {
        text-shadow: none;
        text-align: left;
        font-size: 70px;
        max-width: 600px;
        margin-top: 50px;
      }
    }

    .subtext {
      font-size: 20px;
      //font-weight: 600;
      text-align: center;

      @include XGA {
        text-align: left;
        max-width: 500px;
      }

      ul {
        text-align: left;
        @include XGA {
          padding-left: 20px;
        }
      }
    }

    .products-image {
      display: none;
      position: absolute;
      right: 0;
      bottom: -70px;

      @include XGA {
        display: block;
      }

      @include HD {
        right: 7%;
        bottom: -110px
      }
    }
  }

  .wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .vuse-cards {
    margin-top: 50px;
    position: relative;
    z-index: 10;
    background-image: url(./img/bg-dark.jpg);
    background-size: 1000px;
    background-position: -450px;
    background-repeat: no-repeat;

    @include XGA {
      margin-top: 20px;
      background-size: contain;
      background-position: 0;
    }

    h2 {
      color: #fff;
      width: 86%;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        width: 100%;
        margin: 0;
        padding: 20px 0;
      }
    }
  }

  .recommend-swiper {
    padding: 10px 0 40px;
    //margin-top: -200px;

    .swiper-wrapper {
      @include XGA {
        margin: 0 auto;
        padding: 0 40px 0 0;
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        justify-content: space-around;
      }

      @include HD {
        max-width: 840px;
        padding: 0 0 0 40px;
      }
    }

    .swiper-slide {
      &:nth-child(4) {
        @include XGA {
          margin-top: 24px;
          margin-left: 140px;
        }
      }
      &:nth-child(5) {
        @include XGA {
          margin-top: 24px;
          margin-right: 140px !important;
        }
      }
    }

    .recommend-swiper-slide {
      border-radius: 12px;
      min-height: 290px;
      padding: 24px 28px;
      background: url(./img/bg-card.png);
      background-size: cover;

      @include XGA {
        width: 240px;
        height: 290px;
      }

      .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 90px;
      }

      .body {
        text-align: left;
        color: #fff;

        h3 {
          font-size: 1.2em;
          font-weight: 700;
          margin: .5em 0;
        }

        .desc {
          margin-top: .5em;
        }
      }
    }
  }

  .vuse-flavors {
    margin-top: 50px;
    position: relative;
    z-index: 10;

    @include XGA {
      margin-top: 90px;
      margin-bottom: 60px;
    }

    h2 {
      color: #fff;
      width: 86%;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        width: 100%;
        margin: 0;
        padding: 20px 0;
      }
    }
  }

  .flavor-swiper {
    padding: 10px 0 40px;
    max-width: 800px;
    margin: 0 auto 20px;

    @include XGA {
      padding: 0 10px;
      max-width: 1000px;
      transform: translate(-50px, 0);
    }
    .flavor-swiper-slide {
      min-height: 290px;

      @include XGA {
        height: 250px;
        display: flex;
        min-height: 240px;
      }

      .top {

        img {
          display: block;
          border: 0;
          width: 70%;
          height: auto;
          margin: 0 auto;

          @include XGA {
            height: 250px;
            width: auto;
            transform: translate(70px,0);
          }
        }
      }

      .body {
        position: absolute;
        bottom: 0;
        text-align: left;
        color: #fff;
        padding: 20px 28px 16px;
        border-radius: 12px;
        width: 100%;
        min-height: 185px;

        @include XGA {
          position: relative;
          margin: 20px 0;
        }

        h3 {
          font-size: 32px;
          font-weight: 700;
          margin: 0 0 10px;
          line-height: 1.1;

          @include XGA {
            width: 70%;
          }
        }

        .desc, .nicotine {
          margin: .5em 0;
        }

        .desc {
          @include XGA {
            min-height: 40px;
          }
        }
      }
    }
  }

  .info {
    width: 90%;
    margin: 0 auto;
    color: #fff;
    opacity: .7;
    text-align: center;
  }

  .control-btns {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin: 0 auto 20px;

    .ArrowButton {
      border: 1px solid #fff;
    }

    .flavor-btn-prev, .vuse-btn-prev {
      rotate: 180deg;
    }

    &.vuse-control-btns {
      @include XGA {
        display: none;
      }
    }
  }
}
