@import "../../../theme/variables.scss";

.ActivitiesDetails {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include XGA {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 130px;
  }

  .heading {
    display: flex;
    flex-wrap: wrap;

    .Title {
      font-size: 32px;
      line-height: 1.1;
      width: 75%;
      padding-bottom: 10px;
      min-height: 70px;
    }

    .prize {
      width: 25%;
      align-self: flex-end;
      display: flex;

      .value {
        font-size: 21px;
        font-weight: bold;
        color: $primaryColor;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
      }
      .icon {
        margin-left: 9px;
        display: flex;
        svg {
          align-self: center;
          height: 20px;
          width: 20px;
        }
      }
      @include XGA {
        display: none;
      }
    }

    .calendar {
      position: relative;
      display: flex;
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      color: $primaryColor;
      font-size: 10px;

      .line {
        position: absolute;
        width: 100%;
        background: $primaryColor;
        height: 1px;
        left: 80px;
        bottom: 4px;
        opacity: 0.3;
      }
      @include XGA {
        display: none;
      }
    }
  }

  .activity-help {
    text-align: center;

    .title {
      font-size: 14px;
    }
    .link {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        margin-left: 5px;
      }
    }
  }

  .tutorial {
    width: calc(100% + 28px);
    margin-left: -14px;
    margin-top: 20px;
    .body {
      color: #fff;
      border-radius: 4px;
      text-align: center;
      padding: 24px 12px;
      background: $primaryColor;
      font-size: 12px;
      display: none;

      &.active {
        display: block;
      }
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        cursor: pointer;
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .title {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  @include XGA {
    margin: 0 auto;
  }

  .desktop-activity {
    display: none;

    padding-bottom: 100px;
    .activity-body {
      margin-top: 10px;
      text-align: center;
    }

    .activity-body > .activity-desc {
      text-align: center;
      margin: 25px 0;
    }
    .activity-body > .cta {
      margin: 0 auto;
    }

    .activity-heading {
      display: flex;
      flex-direction: column;

      .calendar {
        width: 100%;
        transform: translateY(-20px);
        display: flex;

        .date {
          color: #001854;
          margin: 0 5px;
          width: 60px;
          line-height: 10px;

          &::before {
            content: "";
            position: absolute;
            left: 70px;
            background: #001854;
            height: 1px;
            display: block;
            width: 60%;
            bottom: 0;
            transform: translateY(-100%);
          }
        }
      }
    }

    .activity-body > p {
      padding: 0 10%;
    }

    .activity-title {
      text-align: left;
      font-size: 39px;
      line-height: 42px;
      font-weight: 600;
      margin-top: 0;
    }
    .activity-subtitle {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      font-weight: 600;
    }

    @include XGA {
      display: block;
      position: absolute;
      right: 0;
      top: 50px;
      width: 400px;

      .cta {
        margin: 0 auto;
        max-width: 225px;
      }
    }
    @include HD {
      width: 468px;
      top: 100px;
    }
    .activity-body {
      border-radius: 12px;
      background: #fff;
      padding: 32px 14px;
    }
  }

  .activity-item {
    list-style: none;
    position: relative;

    &.fixed {
      @include XGA {
        position: fixed;
        top: 60px;
      }
    }

    strong {
      font-weight: 600;
    }

    @include XGA {
      margin-bottom: 25px;
      width: 468px;
      height: 293px;
    }

    @include HD {
      margin-bottom: 25px;
      width: 568px;
      height: 393px;
    }

    .activity-item-category {
      position: absolute;
      right: 5%;
      top: 0;
      transform: translateY(-50%);
      background: $pink;
      border-radius: 100px;
      min-width: 82px;
      min-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 10px;
      font-size: 8px;
      font-weight: 300;

      &.contest {
        background: $tertiaryColor;
      }
      @include XGA {
        font-size: 14px;
        min-height: 33px;
        min-width: 150px;
      }
    }

    .activity-image {
      height: 100%;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
      height: 209px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include XGA {
        height: 393px;
      }
    }

    .activity-body {
      padding: 32px 14px;
      max-width: 90%;
      margin: 0 auto;
      background: #fff;
      border-radius: 12px;
      transform: translateY(-50px);
      text-align: center;

      &.mobile {
        display: block;

        @include XGA {
          display: none;
        }
      }
    }

    .module {
      p {
        margin: 25px 0;
      }
    }

    .activity-body > p {
      text-align: center;
      margin: 25px 0;
    }

    .activity-body > .cta {
      margin: 0 auto;
    }

    .activity-body > p {
      padding: 0 10%;
    }

    .activity-title {
      text-align: center;
      font-size: 23px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 0;
    }
  }

  .desktop-activities {
    display: none;

    .activity-body {
      background: #fff;
      padding: 20px;
      border-radius: 12px;
      box-shadow: -10px 15px 22px 7px rgba(0, 0, 0, 0.078);
      text-align: center;
    }


    .heading {
      display: flex;
      flex-wrap: wrap;
  
      .title {
        font-size: 35px;
        line-height: 35px;
        width: 75%;
        padding-bottom: 0;
        min-height: 70px;
        margin-top: 0;
        @include XGA {
          font-size: 39px;
          line-height: 39px;
        }
      }
  
      .prize {
        width: 25%;
        align-self: flex-end;
        display: flex;
        
        .value {
          font-size: 21px;
          font-weight: bold;
          color: $primaryColor;
          display: flex;
          justify-content: space-between;
          margin-left: auto;
        }
        .icon {
          margin-left: 9px;
          display: flex;
          svg {
            align-self: center;
            height: 20px;
            width: 20px;
          }
        }
       
      }
  
      .calendar {
        position: relative;
        display: flex;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        color: $primaryColor;
        font-size: 12px;
  
        .line {
          position: absolute;
          width: 100%;
          background: $primaryColor;
          height: 1px;
          left: 80px;
          bottom: 4px;
          opacity: 0.3;
        }
      
      }
    }

    
    @include XGA {
      display: block;
      
    }
  }

  .terms-link {
    display: inline-block;
    margin-top: 10px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
