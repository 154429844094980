@import "../../theme/variables.scss";
.Remind {
  max-width: 350px;

  h1 {
    font-weight: 600;
    text-align: center;
  }
  input::placeholder {
    font-style: normal;
  }

  .body-form {
    max-width: 351px;
  }

  @include XGA {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 120px;
    max-width: 351px;
    width: 100%;
  }
  @include HD {
    // gap: 140px;
  }

  h1 {
    font-weight: 600;
    text-align: center;
    @include XGA {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  input::placeholder {
    font-style: normal;
  }


  .login-image {
    width: 100%;
    height: 124px;
    border-radius: 12px;

    @include XGA {
      height: auto;
      width: 100%;
    }
    @include HD {
      height: 393px;
      width: 568px;
      position: sticky;
      top: 40px;
    }

    overflow: hidden;
    picture,
    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
}