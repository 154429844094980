.Counter {
  position: relative;
  height: 145px;
  width: 252px;
  padding: 10px;
  //overflow: hidden;
  margin: 0 auto;

  .counter-meter {
    position: relative;

    .counter-point-position {
      position: absolute;
      top: 116px;
      left: 116px;
      transform: rotate(180deg);

      .counter-point {
        position: absolute;
        width: 22px;
        height: 22px;
        background: #001854;
        box-shadow: -3px -4px 4px rgba(0, 50, 12, 0.15);
        border-radius: 50%;
        transform: translate(-11px, -11px);

        &::after {
          content: "";
          position: absolute;
          top: 7px;
          left: 7px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }

    .counter-numbers {
      position: absolute;
      top: 116px;
      left: 116px;
      font-size: 14px;
      opacity: .7;

      .counter-number {
        position: absolute;

        &:first-child {
          top: 0;
          left: -115px;
        }

        &:nth-child(2) {
          top: -47px;
          left: -90px;
        }

        &:nth-child(3) {
          top: -89px;
          left: -54px;
        }

        &:nth-child(4) {
          top: -100px;
          left: -8px;
        }

        &:nth-child(5) {
          top: -74px;
          left: 56px;
        }

        &:last-child {
          top: 0;
          left: 103px;
        }
      }
    }
  }

  .sales-number {
    position: absolute;
    top: 85px;
    left: 0;
    text-align: center;
    font-size: 14px;
    width: 100%;
    color: #001854;

    .number {
      display: block;
      font-size: 30px;
      font-weight: 700;
    }
  }
}