@import "../../../theme/variables.scss";

.Content, .Content-default {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
  }
}

.Content-max {
  position: relative;
  width: 100%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
  }
}