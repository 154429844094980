@import "../../theme/variables.scss";
.Page404 {
  .wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include XGA {
      margin-top: 50px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    @include XGA {
      font-size: 64px;
      line-height: 64px;
    }
  }
  .desc {
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    width: 70%;
    margin-bottom: 30px;
    @include XGA {
      font-size: 20px;
      line-height: 31px;
      margin-bottom: 33px;
    }
  }
  .error-box {
    height: 84px;
    width: 84px;
    border: 1px solid #c3c9d8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 36px;
    font-weight: 600;
    color: #c3c9d8;

    @include XGA {
      width: 152px;
      height: 152px;
      font-size: 64px;
    }
  }
}
