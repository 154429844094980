@import "../../theme/variables.scss";

.Complaint {
  font-size: 16px;

  @include vHD {
    font-size: 18px;
  }

  .complaint-top {
    @include XGA {
      margin-top: 2em;
      display: flex;
      padding: 0 100px;
      justify-content: space-between;
      align-items: center;
    }

    .text {
      @include XGA {
        max-width: 470px;
      }
    }

    .image {
      img {
        width: 100%;
        height: auto;
        display: block;

        @include XGA {
          max-width: 470px;
        }
      }
    }
  }

  h2, h3 {
    font-size: 24px;
    font-weight: 600;

    @include vHD {
      font-size: 30px;
    }
  }

  h3 {
    text-align: center;

    @include XGA {
      text-align: left;
    }
  }

  .download-link {
    color: $secondaryColor;
    text-align: center;
    display: flex;
    justify-content: center;

    @include XGA {
      justify-content: flex-start;
    }
  }

  ul {
    padding-inline-start: 20px;

    li {
      list-style:disc;
    }
  }

  .color {
    color: #0E2B63;
    font-weight: 600;
  }

  .process-list {
    padding-inline-start: 0;

    &>li {
      list-style: none;
      padding-bottom: 1px;
      border-bottom: 1px solid #0E2B63;

      &:last-child {
        border: 0;
      }
    }
  }

  .new-process {
    margin: 1em 0;
    border-radius: 8px;
    padding: 20px 20px;
    background-color: #fff;

    @include vHD {
      margin: 4em 0;
      padding: 60px 100px;
    }

    h2 {
      margin-top: 0;
    }
  }

  .old-process {
    @include vHD {
      padding: 0 100px;
    }
  }
}