@import "../../../theme/variables.scss";
.NewSaleSerialNumber {
  margin-top: 50px;
  .device-wrapper {
    max-width: 327px;
    margin: 0 auto;
    .input-item {
      input::placeholder {
        font-style: normal;
      }
      margin-bottom: 26px;
    }
    .title {
      font-size: 32px;
      text-align: center;
      font-weight: 600;
      line-height: 44px;
      margin-top: -50px;
      @include XGA {
        margin-top: 0;
      }
    }
    .heading {
      text-align: center;
    }
  }
}
