@import "../../theme/variables.scss";

.Activities {
  padding-top: 10px;

  @include HD {
    padding-top: 30px;
  }

  .activities-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    // first list item need to be bigger
    .Activities-box:first-of-type {
      .box-item {
        height: 240px;
      }
    }

    .activities-static {
      &.mobile {
        display: block;
        @include XGA {
          display: none;
        }
      }
      &.desktop {
        display: none;
        @include XGA {
          display: block;
          position: relative;
        }
      }
    }

    .swiper-cta {
      display: block;
      content: "";
      position: absolute;
      top: 60%;
      height: 30px;
      width: 30px;
      background: transparent;
      border-radius: 50%;
      cursor: pointer;
      //border: 1px solid $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-cta-next {
      right: -40px;
    }
    .swiper-cta-prev {
      left: -40px;
      svg {
        transform: rotate(180deg);
      }
    }
    .activities-slider {
      padding-bottom: 38px;

      .swiper-pagination-bullet {
        background: #6f87c5;
        cursor: pointer;
        transition: ease-out 0.4s;
      }
      .swiper-pagination-bullet-active {
        // width: 8px;
        // height: 8px;
        width: 23px;
        border-radius: 50px;
        background: $primaryColor;
      }
    }

    @include HD {
      margin: 0 auto;
    }
  }
}
