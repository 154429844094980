@import "../../theme/variables.scss";

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal > * {
  opacity: 0;
}

.overlay {
  z-index: 9999;
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //padding: 40px;
  transition: opacity 300ms $CubicEaseInOut;
  background: rgba(0,0,0,.15);

  &_after-open {
      opacity: 1;
  }
  &_before-close {
      opacity: 0;
  }
}

.drawer {
  position: relative;
  //left: 50%;
  //bottom: 0;
  width: 100%;
  max-width: 570px;
  min-height: 200px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 0 -10px 50px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 10;
  transform: translateY(100%);
  transition: transform .4s $QuadEaseInOut;
  text-align: center;
  outline: none;
  overflow: hidden;

  &_after-open {
    transform: translateY(0);
  }

  &_before-close {
    transform: translateY(100%);
  }

  .btn-close {
      z-index: 20;
      position: absolute;
      top: 15px;
      right: 13px;
      background: none;
      border: none;
      display: block;
      text-align: center;
      outline: none;

      .icon {
        position: relative;
        width: 38px;
        height: 38px;
        margin: 0 auto;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 12px;
            height: 2px;
            background-color: $primaryColor;
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 12px;
            height: 2px;
            background-color: $primaryColor;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &.white {
          &:before, &:after {
            background-color: #fff;
          }
        }
      }

      p {
          text-transform: uppercase;
          margin-top: 10px;
          font-size: 10px;
      }
  }

  .drawer-inner {
    padding: 40px 25px 20px;
    width: 100%;
    min-height: 200px;

    @include vHD {
      padding: 40px 50px 30px;
    }

    h2 {
      margin-top: .5em;
      margin-bottom: .5em;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      padding-bottom: 12px;
      //color: $primaryColor;
      //border-bottom: solid 1px #efefef;
    }

    p {
      padding: 0 5%;
    }

    .Button {
      margin-top: 40px;
    }

    &.drawer-batcode {
      height: 400px;
      padding-top: 120px;
      background-color: $primaryColor;
      color: #fff;

      .bat-number {
        text-align: center;
        font-size: 42px;
        letter-spacing: .3em;
        font-weight: 700;
      } 
    }

    &.drawer-error {
      h2 {
        font-size: 32px;
        color: #FF4444;
      }
    }

    &.drawer-success {
      h2 {
        font-size: 32px;
        color:#3BBB69;
      }
    }

    &.drawer-info {
      h2 {
        font-size: 20px;
        font-weight: 700;
      }
    }
    &.drawer-blue {
      background-color: $primaryColor;
      color: #fff;
    }
    &.drawer-pop {
      h2 {
        font-size: 32px;
        font-weight: 600;
      }

      h3 {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}