.Camera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #fff;

  @keyframes flash {
    from {
      opacity: 0.75;
    }

    to {
      opacity: 0;
    }
  }

  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }

  .container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .canvas {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    transform: scale(.3);
    transform-origin: 0 0;
  }

  .video {
    position: absolute;

    &::-webkit-media-controls-play-button {
      display: none !important;
      -webkit-appearance: none;
    }
  }

  .overlay {
    z-index: 80;
    position: absolute;
    top: 50%;
    right: 20px;
    left: 20px;
    box-shadow: 0px 0px 20px 200px rgba(0, 0, 0, 0.6);
    border: 1px solid #ffffff;
    border-radius: 10px;
  }

  .controls {
    z-index: 90;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: #fff;
    padding: 20px;
  }

  .btn {
    width: 75%;

  }
}

:host::shadow::-webkit-media-controls-container{
  display:none !important;
  visibility: hidden!important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

/*export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;
}*/