@import "../../theme/variables.scss";

.Register {
  position: relative;
  max-width: 350px;

  @include XGA {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 120px;
    max-width: 1170px;

    margin-top: 40px;
  }
  @include HD {
    gap: 140px;
  }

  .Button {
    margin-top: 40px;
  }

  input::placeholder {
    font-style: normal;
  }

  .form-step {
    .disclaimer {
      font-size: 11px;
    }
    .link {
      display: block;
      margin: 0 auto;
      margin-top: 16px;
      text-align: center;
    }
  }

  h1,
  .h1 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  .register-page-nav {
    margin: 10px auto 30px;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 152px;
    height: 4px;

    li {
      display: block;
      width: 40px;
      height: 100%;
      background-color: #ffdaae;
      border-radius: 2px;
      margin: 0 5px;
      padding: 0;

      &.active {
        background-color: $secondaryColor;
      }
    }
  }

  .input-row {
    display: flex;

    .input-item {
      flex-grow: 1;
    }

    .input-prefix {
      flex-grow: 0;
      width: 80px;
      margin-right: 10px;
    }
  }

  .input-info {
    position: relative;

    label {
      display: block;
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    .info-btn {
      position: absolute;
      top: 2px;
      right: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $primaryColor;
      cursor: pointer;

      &:after {
        position: absolute;
        content: "i";
        line-height: 16px;
        text-align: center;
        width: 100%;
        font-size: 11px;
        color: #fff;
      }

      &.active {
        &::before {
          position: absolute;
          content: "";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url("../Register/img/corss.svg");
          height: 7px;
          width: 7px;
          background-position: center center;
        }

        &::after {
          display: none;
        }
      }
    }

    .info {
      display: none;
      padding: 10px;
      margin-bottom: 10px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      background-color: $primaryColor;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      line-height: 14px;

      &.active {
        display: block;
      }
    }
  }

  .register-image {
    width: 100%;
    height: 124px;
    border-radius: 12px;
    margin-bottom: 24px;

    @include XGA {
      height: 303px;
      width: 438px;
      position: sticky;
      top: 40px;
    }
    @include HD {
      height: 393px;
      width: 568px;
    }

    overflow: hidden;
    picture,
    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .body-form {
    @include XGA {
      max-width: 327px;
    }
  }
}

.info-bottom {
  font-size: 11px;
  margin: 20px auto;
  width: 90%;
  max-width: 1170px;
}

.overlay {
  z-index: 9999;
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //padding: 40px;
  // transition: opacity 300ms $CubicEaseInOut;
  background: rgba(0,0,0,.15);

  &_after-open {
      opacity: 1;
  }
  &_before-close {
      opacity: 0;
  }
}

.drawer {
  position: relative;
  //left: 50%;
  //bottom: 0;
  width: 100%;
  max-width: 570px;
  min-height: 200px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 0 -10px 50px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 10;
  transform: translateY(100%);
  transition: transform .4s $QuadEaseInOut;
  text-align: center;
  outline: none;
  overflow: hidden;

  &_after-open {
    transform: translateY(0);
  }

  &_before-close {
    transform: translateY(100%);
  }

  .btn-close {
      z-index: 20;
      position: absolute;
      top: 15px;
      right: 13px;
      background: none;
      border: none;
      display: block;
      text-align: center;
      outline: none;

      .icon {
        position: relative;
        width: 38px;
        height: 38px;
        margin: 0 auto;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 12px;
            height: 2px;
            background-color: $primaryColor;
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 12px;
            height: 2px;
            background-color: $primaryColor;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &.white {
          &:before, &:after {
            background-color: #fff;
          }
        }
      }

      p {
          text-transform: uppercase;
          margin-top: 10px;
          font-size: 10px;
      }
  }

  .drawer-inner {
    padding: 40px 25px 20px;
    width: 100%;
    min-height: 200px;

    @include vHD {
      padding: 40px 50px 30px;
    }

    h2 {
      margin-top: .5em;
      margin-bottom: .5em;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      padding-bottom: 12px;
      //color: $primaryColor;
      //border-bottom: solid 1px #efefef;
    }

    p {
      padding: 0 5%;
    }

    .Button {
      margin-top: 40px;
    }

    &.drawer-batcode {
      height: 400px;
      padding-top: 120px;
      background-color: $primaryColor;
      color: #fff;

      .bat-number {
        text-align: center;
        font-size: 42px;
        letter-spacing: .3em;
        font-weight: 700;
      } 
    }

    &.drawer-error {
      h2 {
        font-size: 32px;
        color: #FF4444;
      }
    }

    &.drawer-success {
      h2 {
        font-size: 32px;
        color:#3BBB69;
      }
    }

    &.drawer-info {
      h2 {
        font-size: 20px;
        font-weight: 700;
      }
    }
    &.drawer-pop {
      h2 {
        font-size: 32px;
        font-weight: 600;
      }

      h3 {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}