@import "../../theme/variables.scss";

.Header {
  margin: 0;
  padding: 0;
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .color-line {
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    border: 0;
    display: block;

    &.with-info {
      margin-top: 48px;

      @include XGA {
        margin-top: 40px;
      }
    }
  }

  .ButtonBack {
    top: 12px;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: -28px;
    background: #f4f5f9;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  &.fixed .header-inner {
    height: 100%;
    height: 56px;
  }
  // &.fixed .logo {
  //   align-self: center;
  //   margin-top: 5px;

  // }
  // &.fixed  .nav-trigger {
  //   top:50%;
  //   transform: translateY(-50%);
  // }
  // &.fixed  .bell-icon {
  //   top:50%;
  //   transform: translateY(-50%);
  // }
  // &.fixed .ButtonBack {
  //   top:50%;
  //   transform: translateY(-50%);
  // }

  @include HD {
    height: 110px;
    background: #f4f5f9;
    box-shadow: 0px -3px 50px rgba(45, 45, 45, 0.15);
  }

  .header-inner {
    position: relative;
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;
    height: 100%;
    padding-top: 10px;
    height: 56px;

    &.with-info {
      margin-top: 0px;
    }

    @include HD {
      margin: 0 auto;
    }
  }

  .nav-trigger {
    z-index: 100;
    position: absolute;
    top: 12px;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);

    @include HD {
      top: 24px;
      //right: 25px;
    }
  }

  .bell-icon {
    position: absolute;
    top: 12px;
    right: 55px;
    width: 32px;
    height: 32px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);

    @include HD {
      top: 24px;
      //right: 80px;
    }

    .unseen {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 12px;
      height: 12px;
      color: #fff;
      text-align: center;
      font-size: 9px;
      line-height: 12px;
      text-decoration: none;
      border-radius: 50%;
      background-color: $secondaryColor;
    }
  }
  .disabled {
    opacity: 0.5;
    a {
      cursor: auto;
      pointer-events: none;
    }
  }
  .logo {
    margin-top: 10px;

    @include HD {
      margin-top: 20px;
    }
  }
}

.technical-break {
  background-color: #fff;
  height: 40px;
  color: red;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 10px;
  gap: 12px;
  @include XGA { 
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
    font-size: 14px;
    margin-top: 23px;
  }
}

.header-info {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primaryColor;
  height: 48px;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 10px;
  text-align: center;
  padding: 9px;
  line-height: 1.3;

  span {
    display: none;
  }

  @include XGA {
    height: 40px;
    font-size: 14px;
    padding: 10px 0 8px;

    br {
      display: none;
    }

    span {
      display: inline;
    }
  }
}

.outage-info,
.contest-info {
  z-index: 1999;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #ff4444;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

  svg {
    height: 30px;
    width: auto;
    margin: 0 10px 0 0;
  }

  .btn-close {
    z-index: 20;
    position: absolute;
    top: 2px;
    right: 0;
    background: none;
    border: none;
    display: block;
    text-align: center;
    outline: none;
    cursor: pointer;

    .icon {
      position: relative;
      width: 38px;
      height: 38px;
      margin: 0 auto;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 2px;
        background-color: $primaryColor;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 2px;
        background-color: $primaryColor;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.contest-info {
  z-index: 1998;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 63px;
  background-color: #f3ce4c;
  color: #000;
  font-size: 11px;
  font-weight: 400;
  display: block;
  align-items: left;
  padding: 0 5%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  background-image: url(../../pages/Rankings/img/bg-info.png);
  background-position: top 0% right -15%;
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (min-width: 375px) {
    background-position: center right;
  }

  @include vHD {
    background-image: none;
  }

  @include HD {
    padding: 0;
  }

  .contest-info-inner {
    max-width: 1170px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include vHD {
      background-image: url(../../pages/Rankings/img/bg-info.png);
      background-position: top right;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .Button {
      display: none;
      max-width: 230px;
      margin: 0;

      @include XGA {
        display: flex;
      }
    }

    @include XGA {
      .text {
        padding-right: 30px;
        cursor: pointer;
        font-size: 12px;

        br {
          display: none;
        }
      }
    }

    @include HD {
      .text {
        font-size: 14px;
      }
    }
  }
}
