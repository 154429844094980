@import "../../../theme/variables.scss";

.ReactModalPortal {
    opacity: 1;
}

.overlay {
    opacity: 1;
    z-index: 999;
    background-color: #3333;
    max-width: unset;
}

.overlay-code {
    padding: 0 !important;
}

.modal {

    &.age-gate {
        width: fit-content;
        padding-left: 16px;
        padding-right: 16px;

        @include vHD {
            padding-left: 72px;
            padding-right: 72px;
        }
    }
}