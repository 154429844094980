@import "../../../theme/variables.scss";

.NewSaleDetails {
  @include XGA {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 130px;
  }
  .card {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    width: 327px;
    height: 124px;
    position: relative;

    @include XGA {
      margin-bottom: 25px;
      width: 468px;
      height: 293px;
    }
    @include HD {
      width: 568px;
      height: 393px;
    }

    .photo {
      width: 100%;
      height: 100%;

      border-radius: 12px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .details {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 90%;
    transform: translate(-50%, 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 12px;
    padding: 12px 10px;

    @include HD {
      padding: 20px;

      transform: translate(-30%, -50%);
    }

    .copy {
      margin: 0;
      font-size: 18px;

      @include HD {
        font-size: 34px;
      }
    }
    .icon {
      margin-top: -5px;
      margin-left: 15px;
      align-self: center;

      @include HD {
        margin-left: 50px;
        width: 75.84px;
        height: 47.8px;
      }
    }
  }

  .info {
    margin-top: 50px;

    @include XGA {
      margin-top: 0;
    }
    .title {
      text-align: center;
      font-size: 14px;
      margin-top: 0;
      @include HD {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .desc {
      text-align: center;
    }
    .cta {
      color: $primaryColor;
      font-weight: 700;
      background: transparent;
      border: none;
      text-align: center;
      display: block;
      width: 100%;
      margin: 20px 0;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .footer {
    background: $primaryColor;
    padding: 24px;
    border-radius: 4px;

    @include XGA {
      border-radius: 12px;
    }
    .title {
      color: #fff;
      text-align: center;
      @include XGA {
        font-size: 17px;
        margin-bottom: 0;
      }
    }
    .Button {
      margin-top: 27px;
      margin-left: auto;
      margin-right: auto;
    }
    .cta {
      color: #fff;
      font-weight: 700;
      background: transparent;
      border: none;
      text-align: center;
      display: block;
      width: 100%;
      margin-top: 24px;
      font-size: 14px;
      text-decoration: underline;
      @include XGA {
        margin-bottom: 0;
      }
    }
  }
}
