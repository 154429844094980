@import "../../theme/variables.scss";

.ProductBadge {
  position: absolute;
  left: 5%;
  top: 60px;
  display: inline-block;
  background-color: #0E2B63;
  color: #fff;
  padding: 6px 12px;
  border-radius: 8px;
  height: 36px;

  @include XGA {
    top: 10px;
    left: calc( 5% + 60px );
  }

  @include HD {
    top: 57px;
    left: calc( 5% + 80px );
  }

  .category {
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    text-transform: initial;
  }
}