@import "../../theme/variables.scss";

.goals {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
  }

  .goals-inner {
    max-width: 970px;
    margin: 0 auto;

    @include XGA {
      display: flex;
      justify-content: space-between;
      justify-content: center;
    }
  }

  .Counter {
    margin-bottom: 30px;

    @include HD {
      margin-bottom: 40px;
    }
  }

  .sales-column {
    border-radius: 8px;
    background-color: #fff;
    padding: 20px 5%;

    @include vHD {
      border-radius: 12px;
    }

    @include XGA {
      width: 48%;
      padding: 32px;
    }

    h2 {
      margin-top: 0;
      font-size: 16px;
      color: $primaryColor;

      @include XGA {
        font-size: 20px;
      }
    }

    .levels-head {
      font-size: 12px;
      color: $primaryColor;
      display: flex;
      justify-content: space-between;
      margin: 15px 0;

      @include HD {
        font-size: 14px;
      }
    }

    .voucher-wrap {
      display: flex;
      justify-content: space-between;

      .voucher-left {
        margin: 0;
        width: 30%;
      }

      .voucher-right {
        margin: 0;
        width: 67%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .code {
          font-size: 16px;
          color: $pink;
          font-weight: 700;
        }

        .bonus {
          font-size: 12px;
          font-weight: 700;
          padding-left: 0;
          padding-top: 0;

          span {
            font-weight: 700;
          }
        }
      }
    }

    .level {
      border-radius: 12px;
      //border: 1px solid #FE328A;
      padding: 0 10px;
      margin: 15px 0;
      height: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      background-color: #BCBCBC;

      &.active {
        background-color: #FEB80C;

        .bonus {
          color: #FEB80C;
        }
      }
      /*  &.orange {
          .bonus {
            background-color: #FE920F;
          }
        }
  
        &.yellow {
          .bonus {
            background-color: #FEB80C;
          }
        }
  
        &.lightgreen {
          .bonus {
            background-color: #AACB0C;
          }
        }
  
        &.green {
          .bonus {
            background-color: #44BE70;
          }
        }
      }

      &.orange {
        
          border: 1px solid #FE328A;
        
      }

      &.yellow {
        
          border: 1px solid #FEB80C;
        
      }

      &.lightgreen {
        
          border: 1px solid #AACB0C;
        
      }

      &.green {
        
          border: 1px solid #44BE70;
        
      }*/

      .number {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;

        span {
          display: block;
          width: 20px;
          text-align: right;
          margin-right: 10px;
        }
      }

      .season-bonus {
        font-size: 10px;
        text-align: right;
        margin: 0 10px 0 15px;
        line-height: 1;

        strong {
          font-size: 12px;
        }
      }

      .bonus {
        padding: 5px 10px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 700;
        min-width: 64px;
        background-color: #fff;
        color: #bcbcbc;

        @include vHD {
          font-size: 20px;
        }

        span {
          font-weight: 400;
        }
      }
    }
  }

  .Button {
    background-image: url(./img/copy.svg);
    background-position: 93% center;
    background-repeat: no-repeat;
  }
}
