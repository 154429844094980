@import "../../../theme/variables.scss";
.DashboardSlider {
  position: relative;

  .swiper-cta {
    display: none;

    @include HD {
      z-index: 10;
      display: block;
      content: "";
      position: absolute;
      top: 0px;
      height: 30px;
      width: 30px;
      background: transparent;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .swiper-cta-next {
    right: 0px;
  }
  .swiper-cta-prev {
    right: 160px;
    svg {
      transform: rotate(180deg);
    }
  }
  .news-list-big-item {
    width: 100%;
  }
  .activities-slider {
    padding-top: 20px;
    margin-top: -20px;

    @include XGA {
      padding-bottom: 70px;
    }

    @include HD {
      padding-top: 60px;
      margin-top: -60px;
    }

    .swiper-pagination {
      @include HD {
        width: 120px;
        top: 5px;
        right: 35px;
        bottom: auto;
        left: auto;
      }
    }

    .swiper-pagination-bullet {
      background: #6f87c5;
      cursor: pointer;
      transition: ease-out 0.4s;
    }

    .swiper-pagination-bullet-active {
      // width: 8px;
      // height: 8px;
      width: 23px;
      border-radius: 50px;
      background: $primaryColor;
    }
  }

  @include HD {
    margin: 0 auto;
  }
}
