@import "../../theme/variables.scss";


.Cookies {
display: flex;
flex-direction: column;
flex:1;
align-items: center;
height: 100%;




    .switch-outer {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      column-gap: 12px;
      margin-top: 16px;
      margin-bottom: 32px;
      cursor: pointer;

  

      .switch {
        position: relative;
        width: 28px;
        height: 16px;
        border-radius: 9px;
        background-color: #fff;

        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: 2px;
          top: 2px;
          background-color: $primaryColor;
          border-radius: 50%;
          transition: left .3s ease-in-out;
        }

        &.active {
          background-color: #fff;

          &::before {
            left: 14px;
          }
        }
      }

      p {
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
      }
    }

  .intro {
     display: flex;
    flex-direction: column;
    margin-bottom: auto;
    flex:1;
  
     
  .title {
        //font-size: 32px;
        padding-bottom: 0;

        @include HD {
          font-size: 32px;
        }
      }

      a {
        color: #fff;
      }
     
  }
  .buttons-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:32px 0 26px;
    
    .ButtonTab {
      padding: 14px 12px;
      color: #fff;
      border:1px solid #fff;
   &.active {
      background: #fff;
      color: $primaryColor;
      border:1px solid #fff;
      font-weight: 700;
    }
    }
 
  }
  .steps {
    
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    flex:1;
     .title {
        @include HD {
          font-size: 32px;
        }
      }
  }
  .cta-group {
width: 100%;
margin-top: auto;
    
    .transparent {
        margin-top: 0;
        text-decoration: underline;
        height: auto;
        margin-top: 15px;
        width: auto;
        padding: 5px 10px;
        background: transparent;
    
      }
   
    
  }
  
}
.cookies {
  position: relative;
  width: 100%;
  height: calc(100vh - 32px);
   display: flex;
    flex-direction: column;

  @include vHD {
    max-width: 568px;
  min-height: 568px;
  height: auto;
    border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  }
  
  
  box-shadow: 0 -10px 50px 0 rgba(0, 0, 0, 0.2);
  background-color: $primaryColor;
  color: #fff;
  z-index: 10;
  transform: translateY(100%);
  transition: transform .4s $QuadEaseInOut;
  text-align: center;
  outline: none;
  overflow: hidden;

  &_after-open {
    transform: translateY(0);
  }

  &_before-close {
    transform: translateY(100%);
  }

  .btn-close {
    z-index: 20;
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    display: block;
    text-align: center;
    outline: none;
    cursor: pointer;

    .icon {
      position: relative;
      width: 38px;
      height: 38px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 50%;

      @include vHD {
        background-color: $primaryColor;
      }
    


      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 2px;
        background-color: $primaryColor;
        transform: translate(-50%, -50%) rotate(-45deg);
         @include vHD {
          background: #fff;
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 12px;
        height: 2px;
        background-color: $primaryColor;
        transform: translate(-50%, -50%) rotate(45deg);

          @include vHD {
          background: #fff;
        }
      }
    }
  
}

  .Cookies {
    padding: 40px 25px 20px;
    width: 100%;
    min-height: 200px;
   

    @include vHD {
      // padding: 40px 50px 30px;
    }

    h2 {
      margin-top: .5em;
      margin-bottom: .5em;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      padding-bottom: 12px;
      //color: $primaryColor;
      //border-bottom: solid 1px #efefef;
    }

    p {
      padding: 0 5%;
    }

   

 



 
  }
}

