@import "../../theme/variables.scss";
.DetalistInfo {
  max-width: 1366px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: -56px;

  @include HD {
    margin-top: 0;
    padding-bottom: 50px;
  }

  h2 {
    text-align: center;
    font-size: 28px;
    color :#004F9F;
    line-height: 1.2;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include XGA {
      font-size: 32px;
    }
  }

  .hero-section {
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      max-height: 600px;
      display: block;
      object-fit: cover;
    }
  }

  .hero-content {
    position: absolute;
    top: 50px;
    left: 5%;
    width: 90%;
    max-width: 1170px;
    color: #fff;

    @include HD {
      top: 30px;
      left: 50%;
      margin-left: -585px;
    }

    p {
      font-size: 18px;
      margin: 0;

      @include XGA {
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
      }

      @include HD {
        font-size: 24px;
      }
    }

    .Title {
      @include XGA {
        text-align: center;
      }

      @include HD {
        justify-content: center;

        .ButtonBack {
          position: absolute;
          left: 0;
        }
      }
    }

    .title {
      font-size: 32px;
      font-size: clamp(14px, 8vw, 84px);
      font-weight: 400;
      line-height: 1.2;

      .bold {
        font-weight: 600;
      }

      @include XGA {
        padding-bottom: 0;
        font-size: 36px;
        text-align: center;
        margin: 0 auto;
        max-width: 820px;

        .br {
          display: none;
        }
      }

      @include HD {
        margin-top: 0px;
        font-size: 44px;
      }

      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
  }

  .wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .what-is-glo {
    margin-top: -30vw;
    position: relative;
    padding-top: 80px;

    @include XGA {
      margin-top: -150px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50px;
      left: -10%;
      right: -10%;
      background: #004F9F;
      display: block;
      height: 150px;
      width: 120%;
      z-index: 0;
      transform: rotate(12deg);

      @include XGA {
        transform: rotate(7deg);
      }

      @include HD {
        top: 60px;
      }
    }

    h2 {
      z-index: 1;
      position: relative;
      color: #fff;
    }

    .product {
      z-index: 1;
      position: relative;
      max-width: 768px;
      width: 90%;
      margin: 0 auto;

      @include vHD {
        display: flex;
        margin-top: 40px;
        margin-bottom: 70px;
      }

      .image {
        img {
          width: 100px;
          height: auto;
          display: block;
          margin: 0 auto;

          @include vHD {
            width: 130px;
            margin-right: 20px;
          }

          @include vHD {
            width: 170px;
            margin-right: 30px;
          }
        }
      }

      .product-infos {
        @include vHD {
          margin-top: 30px;
        }

        .product-info {
          background-color: #fff;
          box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.08);
          border-radius: 14px;
          margin-bottom: 15px;
          padding: 25px 45px;
          color: #004F9F;

          @include XGA {
            font-size: 21px;
          }
        }
      }
    }
  }

  .product-benefits {
    width: 90%;
    margin: 0 auto;
    max-width: 970px;

    @include vHD {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .product-benefit {
      padding-bottom: 15px;

      @include vHD {
        width: 48%;
      }

      .image-title {
        background-color: #0E2B63;
        background-position: right;
        background-size: cover;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.1;
        padding: 0 30% 0 20px;
        height: 140px;
        display: flex;
        align-items: center;
        border-radius: 18px;;

        &.img1 {
          background-image: url(./img/i1-m.jpg);

          @include XGA {
            background-image: url(./img/i1.jpg);
          }
        }

        &.img2 {
          background-image: url(./img/i2-m.jpg);

          @include XGA {
            background-image: url(./img/i2.jpg);
          }
        }

        &.img3 {
          background-image: url(./img/i3-m.jpg);

          @include XGA {
            background-image: url(./img/i3.jpg);
          }
        }

        &.img4 {
          background-image: url(./img/i4-m.jpg);

          @include XGA {
            background-image: url(./img/i4.jpg);
          }
        }
      }
    }
  }

  .recommend-swiper {
    padding: 10px 0 50px;
    max-width: 870px;
    margin: 0 auto;

    @include XGA {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include HD {
      padding-left: 7%;
      padding-right: 7%;
    }

    .swiper-wrapper {
      @include vHD {
        flex-direction: column;
      }
    }

    .swiper-slide {
      @include vHD {
        width: 100% !important;
      }
    }

    .recommend-swiper-slide {
      background: #fff;
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      min-height: 240px;
      padding: 25px;
      display: flex;
      align-items: center;

      @include vHD {
        min-height: auto;
        margin-bottom: 15px;
        width: 100%;
      }

      .body {
        text-align: left;
        color: #004F9F;

        

        p {
          margin: 0;

        }
      }

      .title {
        color: #001854;
        font-size: 21px;
        margin: 0;
        line-height: 22px;
      }

      .title-small {
        font-size: 18px;
        line-height: 21px;
      }

      .desc {
        font-size: 20px;
        line-height: 1.3;

        @include XGA {
          font-size: 20px;
        }

        a {
          text-decoration: none;
          color: #2f4374;
        }
        .special {
          color: #2f4374;
          font-weight: bold;
        }
      }
    }
  }

  .qa-list {
    padding: 0;
    width: 90%;
    max-width: 1170px;
    margin: 50px auto 0;

    .header {
      position: relative;

      @include XGA {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 100%;
          height: 1px;
          background: #bec3d2;
        }
      }

      .category-title {
        font-size: 24px;
        font-weight: 400;

        @include XGA {
          text-align: center;
          position: relative;
          display: block;
          background: #f4f5f9;
          padding: 0 10px;
          width: fit-content;
          font-weight: 600;

          margin: 0 auto;
          font-size: 18px;
          position: relative;
          overflow: hidden;
        }
      }
    }

    .list-item {
      display: block;
      margin-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $secondaryColor;

      cursor: pointer;

      .question {
        font-weight: 700;
        position: relative;
        padding-right: 20px;

        .icon {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .answer {
        display: none;
      }

      &.active {
        .question {
          margin-bottom: 10px;
        }
        .icon {
          transform: rotate(180deg);
        }

        .answer {
          display: block;
        }
      }
    }
  }
}
