@import "../../theme/variables.scss";

@font-face {
  font-family: "VELOSans";
  src: url("./fonts/VELOSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "VELOSans";
  src: url("./fonts/VELOSans-Bold.otf") format("opentype");
  font-weight: bold;
  font-display: swap;
}

.Velo {
  max-width: 1366px;
  margin: 0 auto;
  background: #fff;
  color: $primaryColor;

  @include XGA {
    background: #f4f5f9;
    font-size: 16px;
  }

  h1, h2 {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2;

    @include XGA {
      font-size: 64px;
    }
  }

  h2 {
    margin-top: 1.5em;
  }

  h3 {
    margin: 0;
    font-size: 32px;

    @include XGA {
      font-size: 36px;
      margin: 0;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 1.2;
    color: $primaryColor;

    @include vHD {
      font-size: 20px;
    }
  }

  .velo-box {
    border-radius: 12px;
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.13);

    @include XGA {
      box-shadow: -10px 10px 22px 6px rgba(0, 0, 0, 0.13);
    }
  }

  .velo-red {
    color: #A8122F;
  }

  .velo-red-bg {
    background-color: #A8122F;
  }

  .wrapper {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
  }

  .hero-section {
    position: relative;
    margin-top: -56px;
    display: flex;
    flex-direction: column;
    background-color: $primaryColor;
    padding-bottom: 15px;

    @include HD {
      margin-top: 0px;
      padding-bottom: 70px;
    }

    .bg {
      width: 100%;
      height: auto;
      display: block;

      @include vHD {
        width: 60%;
        margin: 0 auto;
      }

      @include HD {
        position: absolute;
        top: 130px;
        left: 20px;
        width: 55%;
      }
    }
  }

  .velo-hero-content {
    color: #fff;
    padding-top: 56px;

    .Title {
      margin-left: 5%;
      padding-right: 15%;
      padding-bottom: 0;
      margin-top: 36px;

      @include vHD {
        padding-right: 50%;
      }

      @include HD {
        padding-right: 0;
        margin:0 auto;
        max-width: 1170px;
        text-align: right;
        align-items: flex-start;
        margin-top: 0;
        padding-top: 0;

        >span {
          padding-left: 48%;
        }
      }
      
      .ButtonBack {
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
  }

  .velo-formats {
    .formats-holder {
      @include XGA {
        display: flex;
        justify-content: space-between;
      }

      .selection {
        @include XGA {
          width: 32%;
        }

        h4 {
          @include XGA {
            margin-top: 0;
          }
        }

        .select-buttons {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 1.5em;

          .select-button {
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 8px 10px;
            border: 1px solid $primaryColor;
            border-radius: 18px;
            font-weight: 700;
            cursor: pointer;

            @include XGA {
              padding: 12px 20px;
              margin-bottom: 20px;
              border-radius: 24px;
              margin-right: 30px;
              width: 230px;
            }

            &.active {
              background-color: $primaryColor;
              color: #fff;
            }

            &:first-child {
              border: 1px solid #00AED6;

              &.active {
                background-color: #00AED6;
                color: $primaryColor;
              }
            }

            &:last-child {
              border: 1px solid #A8122F;
              color: #A8122F;

              &.active {
                background-color: #A8122F;
                color: #fff;
              }
            }
          }
        }
      }

      .presentation {
        text-align: center;
        overflow: hidden;

        @include XGA {
          width: 63%;
        }

        h4 {
          margin: 0;
        }

        .head {
          background-color: #00AED6;
          padding: 25px 5% 20px;

          @include XGA {
            padding: 40px;
          }

          h4 {

            @include XGA {
              padding: 0 10%;
            }
          }

          .small {
            font-size: .9em;
            margin-top: 0;
          }
        }

        .content {
          padding: 20px 5%;

          @include XGA {
            padding: 30px 10px;
          }

          @include HD {
            padding: 30px 20px;
          }
        }

        &-velo-slim {
          .head {
            background-color: $primaryColor;
            color: #fff;

            h4 {
              color: #fff;
            }
          }
        }

        &-velo-ultra {
          .head {
            background-color: #A8122F;
            color: #fff;

            h4 {
              color: #fff;
            }
          }
        }

        .flavours {
          text-align: center;
          margin-top: 1.5em;

          @include XGA {
            display: flex;
            flex-wrap: wrap;
          }
        }

        .flavour {
          margin: 2em 0 1em;
          font-size: 14px;

          @include XGA {
            width: 50%
          }

          img {
            width: 100%;
            max-width: 230px;
            margin: 0 auto;
          }

          p {
            margin: .5em auto 0;
            width: 70%;

            @include XGA {
              width: 80%;
            }

            @include HD {
              width: 65%;
            }
          }

          .intensity-name {
            display: block;
          }

          .intensity {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #009180;
            
            >span {
              margin-right: 10px;
            }

            &.mint {
              span {
                color: #009180;
              }
            }

            &.berry {
              span {
                color: #414BBF;
              }
            }

            &.darkred {
              span {
                color: #F13240;
              }
            }

            &.frost {
              span {
                color: #011954;
              }
            }
          }

          .intensity-dots {
            display: flex;
    
            div {
              width: 8px;
              height: 8px;
              border: 1px solid #009180;
              border-radius: 50%;
              margin-right: 4px;
    
              &.full {
                background-color: #009180;
              }
    
            }
    
            &.mint {

              div {
                border: 1px solid #009180;
    
                &.full {
                  background-color: #009180;
                }
              }
            }
    
            &.berry {

              div {
                border: 1px solid #414BBF;
    
                &.full {
                  background-color: #414BBF;
                }
              }
            }
    
            &.darkred {

              div {
                border: 1px solid #F13240;
    
                &.full {
                  background-color: #F13240;
                }
              }
            }
    
            &.frost {

              div {
                border: 1px solid #011954;
    
                &.full {
                  background-color: #011954;
                }
              }
            }
          }
        }

        .packs {
          display: flex;

          .pack {
            width: 48%;
          }
        }
      }
    }
  }

  .velo-why {
    .profits {
      @include XGA {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .profit {
        margin: 20px 0;
        padding: 25px 5% 20px;
        text-align: center;

        @include XGA {
          margin: 15px 0;
          width: 31.5%;
        }

        &:nth-child(5) {
          display: none;

          @include XGA {
            box-shadow: none;
            display: flex;
            align-items: center;
            padding: 0;

            img {
              width: 100%;
            }
          }
        }

        img {
          width: 100px;
          height: auto;
          display: block;
          margin: 0 auto;
        }

        h4 {
          margin: .5em 0;
        }

        p {
          margin-top: .5em;
        }
      }
    }
  }

  .velo-steps {
    h2 {
      margin-bottom: .4em;
    }

    .subtitle {
      margin: .5em 0 2em;
    }

    .steps {
      @include XGA {
        display: flex;
        justify-content: space-between;
      }

      .step {
        margin: 20px 0;
        padding: 25px 5% 20px;
        text-align: center;

        @include XGA {
          width: 23%;
          padding: 25px;
        }

        .icon {
          display: flex;
          align-items: center;
          height: 48px;
        }

        img {
          width: 46px;
          height: auto;
          display: block;
          margin: 0 auto;
        }

        h4 {
          margin: .5em 0;
        }

        p {
          margin-top: .5em;
        }
      }
    }
  }

  .for-more {
    padding-top: 50px;

    @include XGA {
      padding-top: 100px;
    }

    .wrapper {
      @include XGA {
        display: flex;
      }
    }

    .heading {
      text-align: left;

      @include XGA {
        width: 50%;
      }

      .title {
        font-size: 34px;
        margin-bottom: 0;
        line-height: 1.2;

        @include XGA {
          font-size: 44px;
        }
        
        a {
          color: $tertiaryColor;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .Button {
        margin: 1em 0;
      }
    }

    .footer-image {
      @include XGA {
        width: 50%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
