@import "../../theme/variables.scss";

$toast-mobile: 255px;
$toast-desktop: 345px;

.NotificationToasts {
  display: block;
  position: fixed;
  top: 140px;
  right: 34px;
  width: $toast-mobile;
  z-index: 200;

  &.hidden {
    display: none;
  }

  @include XGA {
    top: 160px;
  }

  @include XGA {
    width: $toast-desktop;
  }


  .list {
    padding: 0;
    list-style: none;
    position: absolute;
    width: 255px;
    @include XGA {
      width: $toast-desktop;
    }
  }

  .item {
    max-height: 108px;
    margin-bottom: 20px;
    user-select: none;

    position: relative;
    transition: all 0.3s ease-out;
    &.remove {
      opacity: 0.5;
      transform: translate(400px);
    }
  }
  .inner {
    background-color: $secondaryColor;
    padding: 15px 19px;
    border-radius: 12px;
    position: relative;
    width: calc(100% - 16px);

    a {
      color: #fff;
    }


    .cta {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);

      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

  
    .title {
      font-size: 15px;
      line-height: 17px;
      color: #fff;
    }

    .footer {
      display: flex;
      margin-top: 10px;
      position: relative;
      color: #fff;
    }
    .copy {
      text-align: right;
      flex-shrink: 0;
      display: block;
      width: fit-content;
      height: 22px;
      font-size: 10px;
      text-decoration: underline;
      cursor: pointer;
      margin-top: -1px;

      @include XGA {
        font-size: 12px;
        height: auto;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #fff;
      align-self: center;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      opacity: 0.5;
      @include XGA {
        transform: translateY(5px);
      }
    }
    .date {
      flex-shrink: 0;
      color: $primaryColor;
      font-size: 10px;
      display: flex;
      align-items: center;
      margin-top: -7px;
      color: inherit;
      opacity: 0.6;
      @include XGA {
        font-size: 12px;
        margin-top: 0;
      }

      svg {
        margin-right: 5px;
      }
    }
  }
}
