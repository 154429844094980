@import "../../theme/variables.scss";

.RegisterGlo {
  position: relative;
  padding: 0;
  max-width: 100%;
  width: 100%;

  @include HD {
    padding-top: 0 !important;
  }

  .Button {
    margin-top: 30px;
  }

  .ButtonBack {
    top: 25px;
    left: 5%;

    @include HD {
      left: 50%;
      margin-left: -585px;
    }
  }

  input::placeholder {
    font-style: normal;
  }

  .form-step {
    .disclaimer {
      font-size: 11px;
    }
    .link {
      display: block;
      margin: 0 auto;
      margin-top: 16px;
      text-align: center;
    }
  }

  h1, .h1 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  .register-page-nav {
    margin: 10px auto 30px;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 152px;
    height: 4px;

    li {
      display: block;
      width: 40px;
      height: 100%;
      background-color: #ffdaae;
      border-radius: 2px;
      margin: 0 5px;
      padding: 0;

      &.active {
        background-color: $secondaryColor;
      }
    }
  }

  .bg-image {
    height: 400px;
    background-image: url(./img/bg-m.jpg);
    background-size: cover;
    background-position: center;

    @include vHD {
      background-image: url(./img/bg.jpg);
    }

    @include XGA {
      height: calc(100vh - 100px);
      width: 62%;
    }
  }

  form {
    padding: 20px;
    max-width: 350px;
    margin: 0 auto;
    margin-top: -140px;

    @include XGA {
      padding: 30px 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 650px;
      margin-top: -300px;
      margin-left: -100px;
    }

    @include HD {
      margin-top: -300px;
    }

    h1 {
      margin-top: 0;
    }

    input {
      background-color: #fff;
    }
  }

  .input-row {
    display: flex;

    .input-item {
      flex-grow: 1;
    
    }

    .input-prefix {
      flex-grow: 0;
      width: 80px;
      margin-right: 10px;
    }
  }

  .phone-input {
    label {
      &:after {
        top: 40px;
      }
    }
  }

  .input-info {
    position: relative;
    margin-bottom: 20px;

    label {
      display: block;
      font-weight: 700;
      margin-bottom: .5em;
    }

    .info-btn {
      position: absolute;
      top: 2px;
      right: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $primaryColor;
      cursor: pointer;

      &:after {
        position: absolute;
        content: 'i';
        line-height: 16px;
        text-align: center;
        width: 100%;
        font-size: 11px;
        color: #fff;
      }

      &.active {
        &::before {
          position: absolute;
          content: '';
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background: url("../Register/img/corss.svg");
          height:7px;
          width: 7px;
          background-position: center center;
        }

        &::after{
          display: none;
        }
      }
     
    }

    .info {
      display: none;
      padding: 10px;
      margin-bottom: 10px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      background-color: $primaryColor;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      line-height: 14px;

      &.active {
        display: block;


       
      }
    }
  }

  .renew-wrap {
    display: flex;

    p {
      margin-top: 0;
    }
  }

  .renew-otp {
    text-decoration: underline;
    font-weight: 700;
    color: $primaryColor;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      text-decoration: none;
    }
    &.disabled {
      opacity: .5;
      cursor: auto;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .timer {
    font-weight: 700;
    color: $primaryColor;

    &.disabled {
      opacity: .5;
    }
  }

  .glonumber {
    font-weight: 700;
    font-size: 1.5em;
  }

}

.info-bottom {
  font-size: 11px;
  margin: 20px auto;
  width: 90%;
  max-width: 1170px;
}