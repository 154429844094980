@import "../../theme/variables.scss";
.NewSale {
  .sale-wrapper {
    position: relative;
  }

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header {
    margin-top: -80px;
    position: relative;

    @include XGA {
      margin-top: 0;
    }

    .tool-tip {
      visibility: hidden;
      position: absolute;
      bottom: 0;
      transform: translateY(calc(100% - 45px));
      z-index: 2;
      background: $primaryColor;
      padding: 12px;
      color: #fff;
      transition: ease-in-out 0.3s opacity;
      cursor: pointer;
      opacity: 0;

      @include XGA {
        width: 300px;
        transform: translate(250px, calc(100% - 45px));
      }

      .text {
        font-size: 12px;
        line-height: 14px;
        margin: 0;
        span {
          font-weight: 700;
        }
      }
      &.active {
        visibility: visible;
        opacity: 1;
      }
    }

    .title {
      font-size: 40px;
      text-align: center;
      font-weight: 600;
      line-height: 44px;
      @include XGA {
        margin-top: 0;
      }
    }
    .title-icon {
      cursor: pointer;

      line-height: inherit;
      width: 15px;
      height: 15px;
      height: 30px;
      display: inline-block;
      position: relative;
      left: -5px;
      top: 5px;
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
      }
    }
  }

  .user-pic {
    .btn {
      font-weight: 700;
      font-size: 14px;
      background: transparent;
      border: 0;
      color: #001854;
      text-decoration: underline;
      display: block;
      margin: 0 auto;
      margin-top: 15px;
      cursor: pointer;
      text-align: center;
    }

    .ball {
      width: 245px;
      height: 245px;
      margin: 0 auto 25px;
      background: $secondaryColor;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      
      .camera {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -40%);
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        
        .camera-desc {
          font-weight: 700;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .limit-warning {
      color: rgb(223, 23, 23);
      font-weight: 700;
      padding: 5px;
      border-radius: 12px;
      font-size: 12px;
      text-align: center;
    }

    #userPic {
      display: none;
    }
  }

  .info {
    margin-top: 50px;
    display: flex;
    gap: 20px;

    @include XGA {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translateX(-500px);
      width: 320px;
    }
    .left-col {
      .warning {
        width: 44px;
        height: 44px;
        display: block;
        border-radius: 50%;
        border: 1.5px solid #000;
        margin-top: 5px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .right-col {
      .title {
        font-size: 14px;
        margin: 0;
      }
      .desc {
        margin: 0;
      }
    }
  }

  .device-wrapper {
    max-width: 327px;
    margin: 0 auto;
    .input-item {
      input::placeholder {
        font-style: normal;
      }
      margin-bottom: 26px;
    }
    .title {
      font-size: 26px;
      text-align: center;
      font-weight: 600;
      line-height: 44px;
      margin-top: -50px;
      @include XGA {
        margin-top: 0;
      }
    }
    .heading {
      text-align: center;
    }
    .serial-label {
      text-align: center;
    }
    .scan-cta {
      margin-bottom: 20px;
      background-color: #fff;
      border:1px solid #001854;
      color: #001854;
    }
    .read-only-input {
    
      background: #fff;
      text-align: center;
      border-color: #fff;
      border-radius: 50px;
      font-size: 20px;
      font-weight: 700;
      height: 40px;
      color: #001854;

      &::placeholder {
        color: #001854;
      }
     
    }
  }
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}