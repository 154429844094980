.Title {
  margin: 0;
  padding: 0.3em 0 1em;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;

  &.with-button {
    display: flex;

    .ButtonBack {
      position: relative;
      top: auto;
      left: auto;
      margin: 5px 20px 0 0;
    }
  }
}
