@import "../../theme/variables.scss";

.Activities-box {
  padding-bottom: 65px;

  .box-item {
    width: 100%;
    height: 180px;
    position: relative;
    max-width: 370px;
    margin: 0 auto;
    cursor: pointer;

    &.wrong {
      pointer-events: none;
    }

    @include XGA {
      height: 240px;
    }
    .box-item-line-desc {
      display: none;
    }
  }

  .box-bg-wrapper {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    .bg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .checked {
      display: none;
    }
  }

  .box-item-body {
    position: absolute;
    background: #fff;
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 76px;
    padding: 12px 2.5% 6px 5%;
    @include XGA {
      width: 85%;
    }
  }

  .box-item-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .box-item-top {
    display: flex;
  }
  .box-item-prize {
    display: flex;
    justify-content: space-between;
    align-items: center;

    align-self: flex-start;
    margin-left: auto;
    margin-right: 5px;
    flex-shrink: 0;
  }
  .box-item-value {
    font-size: 19px;
    font-weight: 700;
    color: #001854;
    margin-left: auto;
    margin-right: 5px;

    @include XGA {
      font-size: 21px;
    }
  }
  .box-item-footer {
    display: flex;
    margin-top: 5px;
    position: relative;
  }
  .box-item-icon {
    width: 18px;
    height: 18px;
    @include XGA {
      width: 21px;
      height: 21px;
    }
  }
  .box-item-line {
    width: 100%;
    height: 1px;
    background: #ccc;
    align-self: center;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
  }
  .box-item-date {
    flex-shrink: 0;
    color: #001854;
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-top: -10px;

    svg {
      margin-right: 5px;
    }
  }

  // if its done
  .done {
    pointer-events: none;
    .box-item-body {
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: rgba(0, 0, 0, 0.68);
        z-index: 1;
      }
    }

    .box-bg-wrapper {
      position: relative;

      &::before {
        content: "";
        background: rgba(0, 0, 0, 0.68);
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
      }
      .checked {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        height: 47px;
        width: 47px;
        transform: translate(-50%, -50%);
        border: 1px solid #fff;
        border-radius: 50%;
      }
      .checked-img {
        position: absolute;
        left: 60%;
        transform: translateX(-50%);
      }
    }
  }
  // if its done incorrectly
  .incorrectly-completed {
    opacity: 0.8;

    .box-item-line-desc {
      display: block;
      position: absolute;
      right: 38px;
      top: -2px;
      font-size: 12px;
      font-weight: 700;
      color: $secondaryColor;
    }
    .box-item-line {
      background: $secondaryColor;
    }
  }
}
