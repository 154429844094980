@import "../../theme/variables.scss";

.ArrowButton {
  width: 28px;
  height: 28px;
  background: $primaryColor;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.button {
    background: transparent;
    border: 1px solid $primaryColor;
  }
}
