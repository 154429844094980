@import "../../theme/variables.scss";
.Faq {
  .qa-list {
    .header {
      @include XGA {
        margin-top: 32px;
        margin-bottom: 0px;
        height: 44px;
        line-height: 44px;
      }
    }
  }
  .qa-list:first-of-type {
    margin-top: -20px;
    .header {
      margin-top: 0;
     
    }
  
  }
  .qa-list {
    padding: 0;

    .header {
      position: relative;

      @include XGA {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 100%;
          height: 1px;
          background: #bec3d2;
        }
      }

      .category-title {
        font-size: 24px;
        font-weight: 400;

        @include XGA {
          text-align: center;
          position: relative;
          display: block;
          background: #f4f5f9;
          padding: 0 10px;
          width: fit-content;
          font-weight: 600;

          margin: 0 auto;
          font-size: 18px;
          position: relative;
          overflow: hidden;
        }
      }
    }

    .list-item {
      display: block;
      margin-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $secondaryColor;

      cursor: pointer;

      .question {
        font-weight: 700;
        position: relative;
        padding-right: 20px;

        .icon {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .answer {
        display: none;
      }

      &.active {
        .question {
          margin-bottom: 10px;
        }
        .icon {
          transform: rotate(180deg);
        }

        .answer {
          display: block;
        }
      }
    }
  }
}
