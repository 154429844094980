@import "../../theme/variables.scss";

.products {
  position: relative;

  .product-page-wrap {

    @include vHD {
      display: flex;


    }
  }

  .products-dropdown {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    @include vHD {
      width: 290px;
      margin: 0;
      margin-right: 60px;
    }

    @include HD {
      margin-right: 100px;
    }

    &.active {
      .products-dropdown-options {
        display: block;
        z-index: 10;
      }
    }

    .products-dropdown-selected {
      display: flex;
      position: relative;
      padding: 15px 25px;
      background-color: #0E2B63;
      color: #fff;
      font-size: 16px;
      font-weight: 700;

      @include vHD {
        display: none;
      }

      .count {
        margin-top: 2px;
        margin-left: 15px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 700;
        color: #0E2B63;
        text-align: center;
        line-height: 20px;
        background-color: #fff;
      }

      .ArrowButton {
        position: absolute;
        top: 12px;
        right: 25px;
        background-color: #fff;
        transform: rotate(90deg);
      }
    }

    .products-dropdown-options {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;

      @include vHD {
        display: block;
        position: relative;
        top: 0;
      }

      .products-dropdown-option {
        display: flex;
        position: relative;
        padding: 15px 25px;
        background-color: #fff;
        color: $primaryColor;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;

        @include vHD {
          background-color: transparent;
          justify-content: space-between;
        }

        &.active {
          background-color: #0E2B63;
          color: #fff;
          border-radius: 12px;
          display:none;

          @include vHD {
            display: flex;
          }

          .count {
            color: #0E2B63;
            background-color: #fff;
          }
        }

        .count {
          margin-top: 2px;
          margin-left: 15px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          font-size: 11px;
          font-weight: 700;
          text-align: center;
          line-height: 20px;
          color: #fff;
          background-color: #0E2B63;
        }
      }
    }
  }

  .product-items {
    @include XGA {
      width: calc(100% - 350px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include HD {
      width: calc(100% - 390px);
    }

    .product-item {
      display: block;
      max-width: 400px;
      margin: 30px auto;
      text-decoration: none;

      @include XGA {
        margin: 0;
        margin-bottom: 30px;
        width: 48%;
      }

      &:first-child {
        @include vHD {
          margin-top: 0;
        }
      }

      .image {
        overflow: hidden;
        border-radius: 12px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
        border: 0;
      }

      .content {
        position: relative;
        z-index: 2;
        width: 80%;
        background: linear-gradient(180deg, #FFFFFF 0%, #FAFCFF 100%);
        box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.12);
        padding: 15px 25px;
        margin: -40px auto 0 auto;
        overflow: hidden;
        border-radius: 12px;

        .product-name {
          font-size: 21px;
          font-weight: 700;
          color: $primaryColor;
        }

        .product-category {
          font-size: 10px;
          color: $primaryColor;
          opacity: 0.5;
          margin-top: .7em;

          &:after {
            content: "";
            z-index: 0;
            width: calc(100% - 85px);
            position: absolute;
            bottom: 20px;
            left: 25px;
            height: 1px;
            background-color: $primaryColor;
            opacity: 0.5;
          }

          span {
            position: relative;
            z-index: 1;
            display: inline-block;
            background-color: #fff;
            padding-right: 15px;
          }
        }

        .ArrowButton {
          position: absolute;
          bottom: 15px;
          right: 15px;
          background-color: $primaryColor;
        }
      }
    }
  }
}
