@import "../../theme/variables.scss";

.HyperX2 {
  max-width: 1366px;
  margin: 0 auto;
  background: #E17D55;
  overflow: hidden;
  margin-top: -56px;

  @include HD {
    margin-top: 0px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;

    @include vHD {
      font-size: 32px;
    }
  }

  .card {
    list-style: none;
    background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    overflow: hidden;
    margin: 0 auto;
    
    @include XGA {
      max-width: 970px;
    }

    .top {
      background: #2B2750;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      color: #fff;

      @include HD {
        min-height: 90px;
      }
    }

    .body {
      padding: 18px;
      text-align: center;

      @include HD {
        padding: 18px;
      }
    }
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .hero-content {
    position: absolute;
    top: 3%;
    color: #fff;

    @include HD {
      margin-top: 40px;
      top: 0;
    }

    .title {
      font-size: 32px;

      font-size: clamp(14px, 7.5vw, 84px);
      font-weight: 400;
      line-height: 115%;

      .bold {
        font-weight: 600;
      }

      @include XGA {
        padding-bottom: 0;
        font-size: 25px;
        max-width: 800px;
        .br {
          display: none;
        }
      }
      @include HD {
        margin-top: 0px;
        font-size: 44px;
        transform: translate(100px, -70px);
        max-width: 1100px;
      }
      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
  }

  .wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .device-section {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;
    z-index: 10;
    position: relative;
    margin-top: 40px;
    margin-bottom: 4em;

    @include XGA {
      margin-left: auto;
      margin-right: auto;
      margin-top: -200px;
    }

    h3 {
      @include XGA {
        font-size: 24px;
      }
    }

    .devices {
      display: flex;
      justify-content: center;
      gap: 40px;
      max-width: 750px;

      img {
        height: 186px;
        width: auto;
        display: block;
        margin: 0 auto;
      }

      p {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #292C2C;
        text-align: center;
        letter-spacing: .1em;
      }
    }

    .devices-btn {
      z-index: 10;
      position: absolute;
      top: 68%;
      left: 50%;
      margin-left: -100px;

      .ArrowButton{
      background-color: #001854;
      }

      &-next {
        margin-left: 72px;
      }

      &-prev {
        transform: rotate(180deg);
      }
    }
  }

  .recommend-swiper {
    padding: 10px 0 40px;
    //margin-top: -200px;
    @include XGA {
      padding-left: 30px;
      padding-right: 30px;
      // display: flex;
      // align-items: center;
    }
    @include HD {
      //margin-top: -255px;
      padding-left: 7%;
      padding-right: 7%;
    }

    .swiper-wrapper {
      @include XGA {
        // display: flex;
        // justify-content: space-between;
      }
    }

    .swiper-slide {
      //height: 100%;
      //flex-grow: 1;
    }

    .recommend-swiper-slide {
      background: #2B2750;
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      min-height: 295px;

      .top {
        display: flex;
        background: #fff;
        justify-content: center;
        align-items: center;
        height: 90px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          font-size: 32px;
          font-weight: 700;
          color: #E17D55;
          border: 1px solid #E17D55;
          border-radius: 50%;
        }
      }

      .body {
        padding: 15px;
        text-align: center;
        color: #fff;
        //border-bottom-left-radius: 12px;
        //border-bottom-right-radius: 12px;

        h3 {
          font-size: 1.2em;
          font-weight: 600;
        }
      }
    }
  }

  .qr {
    position: relative;
    z-index: 2;
    padding-bottom: 150px;

    &::before {
      position: absolute;
      bottom: 0px;
      height: 370px;
      width: calc(140%);
      background: rgb(255, 255, 255);
      transform: rotate(-15deg) translate(-10%, 0%);
      display: block;
      content: "";
      z-index: 1;

      @include XGA {
        bottom: -75px;
        transform: rotate(-15deg) translate(-20%, 0%);
      }
    }
    .wrapper {
      position: relative;
      z-index: 2;
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
    }

    .title {
      color: #fff;
    }

    .cards {
      margin-top: 10px;
      width: 200%;
      display: flex;
      justify-content: space-between;
      transition: transform .6s $QuadEaseOut;
      transform: translate(0,0);

      @include XGA {
        width: 100%;
        gap: 30;
      }
    }

    .legend {
      margin-top: 352px;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      line-height: 2;

      p {
        margin: 0;
      }
    }

    .card {
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      background: #fff;
      width: 44%;

      @include XGA {
        max-width: 470px;
        min-height: 360px;
        width: 31%;
      }
      
    }

    .top {
      height: 250px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #001854;
      font-size: 32px;
      font-weight: 700;
      color: #fff;
      padding: 32px;
      background-size: cover;

      @include XGA {
        height: 320px;
        font-size: 48px;
      }
    }

    .body {
      padding: 24px;

      @include XGA {
        padding: 32px;
      }
    }
    
    .desc {
      font-size: 17px;
      font-weight: 600;
      line-height: 2;
      text-align: center;
      margin: 0;
      text-align: left;


      @include XGA {
        font-size: 24px;
        font-weight: 400;
      }

      span {
        font-weight: 400;
        @include XGA {
          display: none;
        }
      }
    }
  }

  .card-control {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;

    .control-btn {
      font-size: 14px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 20px;
      padding: 10px 20px;
      cursor: pointer;

      &.active {
        font-weight: 700;
        background-color: #2B2750;
        border: 1px solid #2B2750;
      }
    }
  }

  .footer {
    //padding-top: 180px;
    margin-top: -70px;
    background-color: #fff;
    position: relative;
    z-index: 3;
    padding: 0 5%;

    h2 {
      color: #2B2750;
    }

    .wrapper {
      position: relative;
      z-index: 2;
    }

    .cards {
      padding-top: 2em;
      padding-bottom: 5rem;
      flex-wrap: wrap;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: flex-start;
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .logo {
      margin: 0 auto;
      display: block;

      img {
        display: block;
        height: auto;
        width: 151px;
      }
    }

    .desc {
      color: #fff;
      text-align: center;
      font-size: 21px;
      line-height: 23px;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }

    .footer-desc {
      color: #fff;
      font-size: 21px;
      line-height: 23px;
      font-weight: 600;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      background: transparent;
      width: 40%;

      img {
        width: 100px;

        @include XGA {
          width: 148px
        }
      }
      
      .desc {
        color: #2B2750;
        font-weight: 700;
        padding: 0 20%;
        font-size: 16px;
      }

      @include HD {
        width: 23%;
      }
    }
  }
}
