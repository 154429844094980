@import "../../theme/variables.scss";
.VuseEpen {
  max-width: 1366px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: -56px;
  @include HD {
    margin-top: 20px;
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .hero-content {
    position: absolute;
    top: 8%;
    color: #fff;

    .Title {
      font-size: 15.5vw;
      font-weight: 600;
      line-height: 15.5vw;
      margin-top: 25px;

      @include XGA {
        font-size: 54px;
        line-height: 54px;
        width: 600px;
        padding-bottom: 0;
       margin-top: 0;
      }
      @include HD {
        font-size: 64px;
        line-height: 64px;
      }
      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
    .sub-title {
      margin-top: -10vw;
      color: inherit;
      font-size: 5.3vw;
      text-align: left;
      width: 300px;

      @include XGA {
        margin-top: 30px;
        font-size: 18px;
        line-height: 22px;
        width: 400px;
      }
      @include HD {
        font-size: 20px;
        line-height: 24px;
        width: 350px;
        margin-left: 100px;
      }
    }
  }

  .hero-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .vuse-taste {
    background: #f4f5f9;
    position: relative;

    max-width: 1170px;
    margin: 0 auto;

    &::before {
      content: "";
      position: absolute;
      top: 300px;
      left: -250px;
      right: -250px;
      width: calc(100% + 500px);
      height: 150px;
      background: $primaryColor;
      transform: rotate(-10deg);

      @include XGA {
        transform: rotate(-7deg);
        top: 240px;
      }
    }

    .desc {
      @include XGA {
        max-width: 70%;
        margin: 0 auto;
      }
    }

    .content {
      margin-bottom: 30px;
      p {
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        padding: 0 5%;
      }
    }

    .vuse-taste-slider {
      .swiper-wrapper {
      }

      position: relative;

      .swiper-slide {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 315px;
      }
      .card {
        border-radius: 12px;
        width: 100%;
        margin-bottom: 35px;
        height: 280px;
        width: 270px;
        display: flex;
        flex-direction: column;
        transition: ease-in-out all 0.3s;

        @include XGA {
          width: 292px;
          max-height: 275px;
        }
        &.active {
          background: #fff;
          box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
          .title {
            opacity: 1;
          }
        }
      }

      .bg {
        width: 100%;
        height: 100%;
        display: block;
      }
      .title {
        font-size: 20px;
        margin: 0;
        margin-top: 15px;
        opacity: 0;
        transition: ease-in 0.3s opacity;
      }
      .top {
        width: 136px;
        height: 178px;
        margin: 0 auto;
        margin-top: 27px;
      }

      .desc {
        margin: 0 auto;
        text-align: center;
        padding: 0 5%;
        padding-bottom: 30px;
        font-size: 11px;

        @include XGA {
          max-width: 550px;
          span {
            display: block;
          }
        }
      }
    }

    .title {
      text-align: center;
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        margin-bottom: 45px;
      }
    }
  }

  .power-tobacco-wrapper {
    display: flex;
    margin-left: 10px;
  }
  .vuse-swiper {
    padding: 50px 0;
    margin-top: -90vw;
    @include XGA {
      margin-top: -200px;
      max-width: 1030px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
    }
    @include HD {
      margin-top: -280px;
    }

    .vuse-swiper-slide {
      background: #fff;

      border-radius: 12px;
      min-height: 270px;
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      overflow: hidden;
      @include XGA {
        width: 292px;
        height: 300px;
      }

      .top {
        height: 109px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f2ab00;

        .icon {
          display: block;
        }
      }
      .body {
        padding: 12px 16px;
        text-align: center;
        min-height: 200px;
        padding-bottom: 0;

        @include XGA {
          padding-top: 10px;
        }
      }
      .title {
        font-size: 17px;
        margin: 10px;
        line-height: 18px;

        @include XGA {
          margin-top: 10px;
          font-size: 18px;
          margin-bottom: 0;
          height: 50px;
        }

        span {
          display: block;
        }
      }
      .desc {
        font-size: 13px;
        line-height: 22px;

        span {
          display: block;
        }
      }

      .disc {
        font-size: 12px;
      }
    }
  }

  .vuse-device {
    margin-top: 70px;
    .vuse-bg {
      width: 100%;
      display: block;

      @include XGA {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      text-align: center;
      padding: 0 10%;
      margin-bottom: 30px;
      font-size: 32px;
      h2 {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 36px;
        font-size: 32px;
      }

      p {
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        span {
          display: block;
        }
      }
    }

    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;

      @include XGA {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 12px;
      }

      svg {
        width: 53px;
      }
    }

    .cards {
      margin-top: -50vw;

      .card {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        margin-bottom: 40px;
        min-height: 280px;
        text-align: center;
        box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);

        @include XGA {
          min-height: 210px;
        }

        .title {
          font-size: 20px;
          line-height: 22px;

          @include XGA {
            font-size: 17.5px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 0;
          }
        }
        .desc {
          font-size: 14px;
        }
        .small-text {
          font-size: 9px;
        }
      }

      @include XGA {
        margin: 0 auto;
        margin-top: -235px;
        max-width: 1226px;
        // border:1px solid red;
        // left: 50%;
        // transform: translateX(-50%);
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .card {
      min-height: 154px;
      background: #fff;
      border-radius: 12px;

      @include XGA {
        min-height: 270px;
        width: 270px;
        height: 206px;
      }
    }

    .title {
      text-align: center;
      color: $secondaryColor;
      padding: 0 10%;
      margin: 5px 0;
    }
  }

  .vuse-info {
    position: relative;
    z-index: 2;
    margin-top: 15vw;
    width: 100%;

    @include XGA {
      height: 700px;
      margin-top: 72px;
    }

    .swiper {
      padding-bottom: 80px;
    }

    .image-wrapper {
      position: relative;
      width: 100%;
    }
    .cards {
      width: 100%;
      top: 100px;
      left: 0;
      position: absolute;

      @include XGA {
        max-width: 1226px;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .bg-info {
      width: 100%;
      display: block;
      @include vHD {
        min-height: 550px;
      }
    }
    .card {
      background: #fff;
      border-radius: 12px;
      padding: 29px 18px;
      height: 466px;
      margin-bottom: 60px;
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);

      @include VGA {
        height: 400px;
        width: 100%;
      }
      @include XGA {
        height: 460px;
        width: 270px;
      }

      .title {
        font-size: 20px;
        text-align: center;
        margin: 0;
        margin-bottom: 23px;
        color: $secondaryColor;
      }
      .desc {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0 7%;
      }
      .footer {
        padding: 0 7%;
        text-align: center;
        font-weight: 700;
        font-size: 12px;

        .disclaimer {
          font-weight: 400;
          display: block;
          margin-top: 30px;
        }
      }
    }
    .vuse-info-title {
      text-align: center;
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      max-width: 330px;
      margin: 0 auto;
    }
  }
}
