@import "../../theme/variables.scss";
.VuseEpod {
  max-width: 1366px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: -56px;
  @include HD {
    margin-top: -35px;
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .hero-content {
    position: absolute;
    top: 8%;
    color: #fff;

    .Title {
      font-size: 15.5vw;
      font-weight: 600;
      line-height: 15.5vw;

      @include XGA {
        margin-top: 20px;
        font-size: 54px;
        line-height: 54px;
        width: 600px;
        padding-bottom: 0;
      }
      @include HD {
        font-size: 64px;
        line-height: 64px;
      }
      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
    .sub-title {
      margin-top: -10vw;
      color: inherit;
      font-size: 5.3vw;
      text-align: left;

      @include XGA {
        margin-top: 30px;
        font-size: 18px;
        line-height: 22px;
        width: 400px;
      }
      @include HD {
        font-size: 20px;
        line-height: 24px;
        width: 350px;
        margin-left: 100px;
      }
    }
  }

  .hero-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .vuse-taste {
    background: #f4f5f9;
    position: relative;

    max-width: 1170px;
    margin-left: auto;

    @include XGA {
      &::before {
        content: "";
        position: absolute;
        top: 100px;
        left: -250px;
        right: -250px;
        width: calc(100% + 500px);
        height: 180px;
        background: #d2d7cf;
        transform: rotate(-5deg);
      }
    }

    .swiper-wrapper {
      @include XGA {
        display: flex;
        justify-content: space-between;
      }
    }

    .vuse-taste-slider {
      .card {
        border: 1px solid #99aa9c;
        border-radius: 12px;
        min-height: 320px;
        width: 100%;

        @include XGA {
          width: 292px;
          max-height: 275px;
        }
      }

      .bg {
        width: 130px;
        height: 172px;
        display: block;
        margin: 0 auto;
        margin-top: 27px;
        margin-bottom: 27px;
      }
      .title {
        font-size: 22px;
        margin: 0;
      }

      .desc {
        margin: 0 auto;
        text-align: center;
        padding: 0 5%;
        padding-bottom: 30px;
        font-size: 11px;

        @include XGA {
          max-width: 550px;
          span {
            display: block;
          }
        }
      }
    }

    .title {
      text-align: center;
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        margin-bottom: 45px;
      }
    }
  }

  .power-tobacco-wrapper {
    display: flex;
    margin-left: 10px;
  }
  .vuse-swiper {
    padding: 50px 0;
    margin-top: -80vw;
    @include XGA {
      margin-top: -200px;
      max-width: 1030px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
    }
    @include HD {
      margin-top: -280px;
    }

    .vuse-swiper-slide {
      background: #fff;

      border-radius: 12px;
      min-height: 270px;
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      overflow: hidden;
      @include XGA {
        width: 292px;
        height: 300px;
      }

      .top {
        height: 109px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #84837e;

        .icon {
          display: block;
        }
      }
      .body {
        padding: 12px 16px;
        text-align: center;
        min-height: 200px;
        padding-bottom: 0;

        @include XGA {
          padding-top: 10px;
        }
      }
      .title {
        font-size: 17px;
        margin: 10px;
        line-height: 18px;

        @include XGA {
          margin-top: 10px;
          font-size: 18px;
          margin-bottom: 0;
          height: 50px;
        }

        span {
          display: block;
        }
      }
      .desc {
        font-size: 13px;
        line-height: 22px;

        span {
          display: block;
        }
      }

      .disc {
        font-size: 12px;
      }
    }
  }

  .vuse-device {
    margin-top: 70px;
    .vuse-bg {
      width: 100%;
      display: block;

      @include XGA {
        width: 100%;
        height: 100%;
      }
    }

    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;

      @include XGA {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 70px;
      }

      svg {
        width: 53px;
        @include XGA {
          width: 90px;
        }
      }
    }

    .static-cards {
      display: none;

      @include XGA {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        max-width: 970px;
        margin-top: -350px;
        margin-left: auto;
        margin-right: auto;
      }
      @include HD {
        margin-top: -435px;
      }
      .card {
        height: 206px;
        background: #fff;
        border-radius: 12px;
        width: 425px;
        box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      }
      .title {
        text-align: center;
        color: $secondaryColor;
        padding: 0 10%;
        margin: 5px 0;

        @include XGA {
          font-size: 27px;
          line-height: 30px;
        }
      }
    }

    .cards {
      margin-top: -50vw;

      @include XGA {
        display: none;
      }
    }

    .card {
      min-height: 154px;
      background: #fff;
      border-radius: 12px;
    }
    .title {
      text-align: center;
      color: $secondaryColor;
      padding: 0 10%;
      margin: 5px 0;
    }
  }

  .vuse-info {
    position: relative;
    z-index: 2;
    margin-top: 15vw;
    width: 100%;

    @include XGA {
      height: 700px;
      margin-top: 72px;
    }

    .swiper {
      padding-bottom: 80px;
    }

    .image-wrapper {
      position: relative;
      width: 100%;
    }
    .cards {
      width: 100%;
      top: 100px;
      left: 0;
      position: absolute;

      @include XGA {
        max-width: 1226px;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .bg-info {
      width: 100%;
      display: block;
      @include vHD {
        min-height: 550px;
      }
    }
    .card {
      background: #fff;
      border-radius: 12px;
      padding: 29px 18px;
      height: 466px;
      margin-bottom: 60px;
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);

      @include VGA {
        height: 400px;
        width: 100%;
      }
      @include XGA {
        height: 460px;
        width: 270px;
      }

      .title {
        font-size: 20px;
        text-align: center;
        margin: 0;
        margin-bottom: 23px;
        color: $secondaryColor;
      }
      .desc {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0 7%;
      }
      .footer {
        padding: 0 7%;
        text-align: center;
        font-weight: 700;
        font-size: 12px;

        .disclaimer {
          font-weight: 400;
          display: block;
          margin-top: 30px;
        }
      }
    }
    .vuse-info-title {
      text-align: center;
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      max-width: 330px;
      margin: 0 auto;
    }
  }
}
