@import "../../theme/variables.scss";

.rank {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-auto-rows: minmax(200px, auto);
    // grid-column-gap: 130px;
  }

  
  .ranks-items-title {
    font-weight: 600;
    @include vHD {
      margin-bottom: 30px;
    }
  }

  .rank-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    @include vHD {
      justify-content: space-around;
    }
    @include HD {
      justify-content: space-between;
    }

    .rank-list-item {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      height: 108px;
      // margin-bottom: 20px;
      max-width: 327px;

      @include vHD {
        margin-bottom: 200px;
      }
    }

    .rank-list-item--current {
      .rank-list-item-desc {
        background: #feb70d;
      }
    }

    .rank-list-item--gainded {
      opacity: 0.5;
      .rank-list-item-desc {
        background: #feb70d;
      }
    }

    .rank-list-item-body {
      position: relative;
      // display: flex;
      // border: 5px solid red;
      width: 327px;

      @include vHD {
        width: 169px;
        height: 169px;
      }
    }

    .rank-list-item-desc {
      width: 140px;
      color: #fff;
      padding: 10px;
      background: #bcbcbc;
      border-radius: 12px;
      @include vHD {
        width: 100%;
        height: 100%;
        padding: 24px;
 
      }
    }
    .rank-list-item-desc-text {
      padding: 0;
      margin: 0;
      line-height: 1.1;

      @include XGA {
        font-size: 16px;
        width: 110%;
      }
    }

    .rank-list-item-value {
      display: block;
      font-weight: 600;
      font-size: 35px;
      @include XGA {
        font-size: 42px;
      }
    }

    .rank-list-item-role {
      position: absolute;
      top: 50%;
      left: 130px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      width: 197px;
      height: 90px;
      background: #fff;
      border-radius: 12px;
      padding-left: 14px;
      padding-right: 14px;

      @include vHD {
        top: 100%;
        bottom: 0;
        left: 50%;
        width: 143px;
        height: 176px;
        transform: translate(-50%, -35px);
        align-items: flex-start;
        box-shadow: -10px 15px 22px 7px rgba(0, 0, 0, 0.078);
      }
    }

    .rank-list-item-role-title {
      font-weight: 600;
      font-size: 24px;
      margin: 0;
      padding: 0;

      @include vHD {
        font-size: 22px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }

    .rank-list-item-role-desc {
      font-weight: 400;
      font-size: 12px;
      margin: 0;
      color: #999999;
      text-align: left;
      line-height: 1.1;
      @include vHD {
        text-align: center;
       
      } 
    }
    .rank-list-item-icon {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      margin-left: auto;

      @include vHD {
        margin: 0 auto;
        width: 41px;
        height: 41px;
        svg {
          width: 100%;
          height: 100%;
        }
      }

      &--absolute {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        @include vHD {
          right: 50%;
          transform: translateX(50%);
        }
      }

      &--star {
        @include vHD {
          order: 2;
        }
      }
    }
  }
}
