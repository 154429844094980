@import "../../theme/variables.scss";
.Video-player {
 


    .inner:hover > .play svg  {
        opacity: .7;
    
    }

    .inner {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
        position:relative;
        cursor: pointer;
      
       

       

       
        video {
          display: block;
          width: 100%;
          height: 100%;
          display: block;
        }


        .play {
            pointer-events: none;
            position: absolute;
            left: 50%;
            top:50%;
            height: 53px;
            width: 53px;
            background: $primaryColor;
            transform: translate(-50%,-50%);
            border-radius:50%;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;            
        }
    }
}