@import "../../theme/variables.scss";

.Profile {
  position: relative;
  width: 100%;
  padding-bottom: 100px;

  
  .react-select__value-container {
    height: 50px;
    font-size: 16px;
  }
  .react-select__control {
    border-color:#000;
    border-radius: 4px;

  }
  .react-select__indicators {
    // border:1px solid red;
  }
  .react-select__indicator-separator {
    display: none;
  }


  .Title {
    width: 90%;
    margin: 0 auto;

    @include HD {
      width: 100%;
    }
  }

  .profile-batcode {
    color: $primaryColor;
    display: flex;
    justify-content: space-between;
    margin: 10px 5%;

    @include XGA {
      position: absolute;
      top: 50px;
      display: block;
      width: 200px;
      right: 0;
      margin: 0;
      text-align: right;
    }

    .batcode-number {
      font-size: 1.2em;
      font-weight: 700;

      @include XGA {
        font-size: 20px;
      }
    }
  }

  .profile-columns {
    margin-bottom: 40px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    background-color: #fff;

    @include XGA {
      margin: 0 auto 40px;
      display: flex;
      justify-content: space-between;
    }
  }

  .profile-column {
    padding: 15px 5%;

    &.nav-column {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      background-color: #E7ECFA;

      @include XGA {
        border-top-right-radius: 0;
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
        width: 240px;
        padding: 32px 0 32px 32px;
      }

      .profile-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include XGA {
          display: block;
        }

        .profile-nav-item {
          color: $primaryColor;
          cursor: pointer;
          margin-bottom: 10px;
          margin-right: 10px;
          margin-left: 10px;
          font-size: 16px;

          &.active {
            font-weight: 700;
          }
        }
      }
    }

    &.edit-column {
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #fff;

      @include XGA {
        padding: 32px;
        width: calc(100% - 240px);
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      section {
        display: none;

        &.active-section {
          display: block;
        }
      }
    }
  }

  .password-item {
    margin-bottom: 20px;

    .label {
      font-weight: 700;
    }
  }

  .login-section {
    h2 {
      color: $primaryColor;
    }
  }

  .user-data-section {
    h2 {
      margin-top: 1em;
      color: $primaryColor;

      @include HD {
        margin-top: 1em;
      }
    }

    .disclaimer {
      margin-top: 48px;
    }

    .send-disclaimer {
      margin-top: 72px;
    }

    .btn-group {
      display: flex;
      gap: 24px;
    }

    .react-select-container  {
      cursor: pointer;
      color: #000!important;
      margin-bottom: 5px;
    }
    .react-select-container  {
      div {
        color: #000!important;
      }
    }
    .react-select-container:hover  {
      border-color: #000!important;
    }
  }

  .dropped {
    box-sizing: border-box; 
    height: 0px; 
    overflow: hidden; 
    display: none;
  }

  .tax-header {
    font-size: 18px;
    margin: 24px 0 12px 0;
    padding: 6px 0;
    border-bottom: 2px solid #817E7E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #817E7E;
    @include XGA {
      font-size: 22px;
    }
  }

  .desktop-row {
    @include XGA {
      display: flex;
      justify-content: space-between;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    }
  }

  #streetAddress, #cityName {
    @include XGA {
      min-width: 400px;
    }
  }

  .desktop-margin-right {
    @include XGA {
      margin-right: 64px;
    }
  } 

  .desktop-margin-left {
    @include XGA {
      margin-left: 8px;
    }
  }

  .pit-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @include XGA {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      flex-direction: row;
    }
  }

  .info-warning-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .user-data-text {
    color: #EF1212;
    // position: relative;
    // min-height: 45px;
    // margin-bottom: 25px;

    // &:after {
    //   content: "!";
    //   position: absolute;
    //   top: 2px;
    //   left: 0;
    //   width: 44px;
    //   height: 44px;
    //   color: #EA1313;
    //   font-size: 38px;
    //   text-align: center;
    //   line-height: 40px;
    //   border: 2px solid #000;
    //   border-radius: 50%;
    // }
  }

  .send-code-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    flex-direction: column;

    @include XGA {
      align-items: flex-end;
      justify-content: flex-start;
      gap: 16px;
      flex-direction: row;
    }

    div {
      input {
        margin-bottom: 0;
        height: 40px;
        width: 327px;
      }
      input:disabled {
        border: solid 1px lightgrey;
        cursor: default;
      }
    }
  }

  .sended-sms-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 321px;
    margin-bottom: 24px;
    text-align: center;

    p, span  {
      margin: 0;
      font-weight: 700;
    }

    span {
      color: $secondaryColor;
      max-width: 115px;
    }

    .send-again {
      text-decoration: underline;
      color: $primaryColor;
      font-weight: 700;
      cursor: pointer;

      &.disabled {
        cursor: default;
        color: #828282;
        font-weight: 400;
      }
    }
  }

  .checkbox-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;

    .label-text {
      font-weight: 700;
      padding-right: 20px;
    }

    .check {
      position: relative;
      color: #fff;
      text-align: right;
      padding: 2px 8px 2px 8px;
      height: 22px;
      width: 52px;
      border-radius: 15px;
      background-color: #001854;

      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #fff;
      }

      &.active {
        text-align: left;

        &:after {
          left: auto;
          right: 2px;
        }
      }
    }
  }

  .checkbox-item-marketing {
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 8px;

    .check { 
      background-color: #EFEFEF;
      color: #000;
      min-width: 52px;
      font-size: 14px;
      cursor: pointer;

      @include XGA {
        min-width: none;
      }

      &:before {
        display: none
      }

      &.active {
        text-align: left;
        background-color: #001854;
        color: #fff;
  
        &:after {
          left: auto;
          right: 2px;
        }
      }
    }

  }
}

.save-section {
  margin-top: 0 !important;
  .bottom-section-inner {

    @include XGA {
      max-width: 330px !important;
    }
  }
  
}
