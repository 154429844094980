@import "../../theme/variables.scss";

.Veo {
  max-width: 1366px;
  min-height: 1000px;
  margin: 0 auto;
  background: #f4f5f9;
  background-image: url(./img/bg-mobile.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  @include XGA {
    background-image: url(./img/bg-desktop.jpg);
    //background: #f4f5f9;
  }

  .hero-section {
    position: relative;
    margin-top: -56px;
    padding-top: 56px;

    @include HD {
      margin-top: 0px;
      padding-top:40px;
    }
  }

  .main-section {
    position: relative;
  }

  .glo-hero-content {
    position: relative;
    color: #fff;

    .Title {
      //padding-top: 0;
    }

    .Title-veo {
      padding-top: 64vw;
      font-size: 11vw;
      width: 100%;
      font-weight: 700;
      line-height: 1.2em;
      padding-bottom: 10px;
      margin: 0;

      @include XGA {
        font-size: 54px;
        line-height: 54px;
        width: 700px;
        padding-bottom: 0;
        padding-top: 40px;
      }

      @include HD {
        margin-top: 50px;
        font-size: 70px;
        line-height: 70px;
        width: 800px;
        padding-top: 20px;
        margin-top:0;
      }

      &.with-button {
        margin-top: 0;

        @include HD {
          padding-bottom: 0.3em;
        }
      }
      
      .ButtonBack {
        //align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }

    p {
      font-size: 5.5vw;
      font-weight: 700;
      padding-right: 10vw;
      margin: 2em 0 2vw;
      margin-top: 0;
      line-height: 1.2;

      @include XGA {
        margin-top: 1em;
        font-size: 20px;
        max-width: 400px;
      }
    }
  }

  .section-veo {
    h2 {
      font-size: 32px;
      color: #0E2B63;
      text-align: center;
      line-height: 1.1;
      margin-bottom: .5em;

      @include vHD {
        br {
          display: none;
        }
      }
    }

    p {
      text-align: center;
    }
    
  }

  .glo-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .card-control {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;

    .control-btn {
      font-size: 14px;
      color: $primaryColor;
      border: 2px solid $primaryColor;
      border-radius: 20px;
      padding: 10px 20px;
      cursor: pointer;

      &.active {
        color: #fff;
        font-weight: 700;
        background-color: $primaryColor;
        border: 2px solid $primaryColor;
      }
    }
  }

  .pallmall-boxes {
    @include XGA {
      display: flex;
      justify-content: center;
    }
  }

  .pallmall-title {
    margin: 1em 0 .5em;
    padding: 0 5%;
    font-size: 26px;
    text-align: center;
    color: #0F1785;
    text-wrap: balance;
    text-transform: uppercase;

    span {
      color: #00B1EB;
    }

    @include XGA {
      font-size: 32px;
    }

    @include HD {
      padding: 0 22%;
      text-wrap: wrap;
    }

    img {
      width: 110px;
      height: auto;
      margin: 0 15px;
    }
  }

  .pallmall-subtext {
    font-size: 18px;
    text-align: center;
    padding: 0 5%;
    color: #0F1785;
    text-wrap: balance;

    @include HD {
      font-size: 22px;
    }
  }

  .icon-swiper {
    padding: 2em 0 3em;
    
    @include XGA {
      padding: 2em 35px 3em;
      max-width: 900px;
      margin-top: 10vw;
    }

    @include HD {
      max-width: 1000px;
      margin-top: 170px;
    }
  }

  .icon-swiper-slide {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.12);
    justify-content: flex-start;
    min-height: 122px;

    @include XGA {
      min-height: 146px;
    }

    .top {
      .icon {
        display: block;
        width: 100%;
        max-width: 40px;
      }
    }



    .body {
      font-size: 15px;
      font-weight: 700;
      color: #0F1785;
      padding-right: 20px;
    }
  }

  .veo-products {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 900px;
    margin: 40px auto;

    @include HD {
      width: 1170px;

      >div {
        &:nth-child(4) {
      
            margin-top: 24px;
            margin-left: 200px;
          
        }
        &:nth-child(5) {

            margin-top: 24px;
            margin-right: 200px !important;
          }
        
      }
    }
  }

  .veo-swiper {
    padding: 2em 0 3em;
    
    @include XGA {
      max-width: 1100px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .VeoCard {
    color: #0F1785;
    font-size: 20px;
    margin-bottom: 25px;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 30px;
    border-radius: 12px;
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.12);
    position: relative;

    @include XGA {
      width: 270px
    }

    @include HD {
      width: 370px;
    }

    &.active {
      .pack {
        visibility: hidden;
      }

      .more {
        display: block;
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 54px;
      font-size: 14px;
      color: #fff;
      font-weight: 700;
      text-align: center;
      background-color: #0E2B63;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .pack {
      visibility: visible;
    }

    .top {

      .icon {
        width: 90%;
        margin: 0 auto;
        display: block;
        max-width: 200px;

        @include XGA {
          width: 200px;
          height: auto;
          display: block;
        }
      }
    }

    .more {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      color: #0E2B63;
      font-size: 14px;
      padding: 20px;

      @include XGA {
        padding: 30px 10%;
      }

      @include HD {
        padding: 50px 15%;
      }

      h3 {
        font-size: 24px;
        margin: 0;
      }

      .desc {
        font-size: 14px;
        margin-top: 0;
        text-align: left;
      }

      .level {
        margin-bottom: 1em;
        @include XGA {
          display: flex;
        }

        .power {
          display: flex;
          margin-top: 5px;

          @include XGA {
            margin-left: 8px;
          }
        }

        .dot {
          width: 8px;
          height: 8px;
          margin-right: 8px;
          background-color: #0E2B63;
          border-radius: 50%;

          &.active {
            background-color: #00B1EB;
          }
        }
      }
    }
  }

  .section-blue {
    padding: 150px 5% 50px;
    margin-top: -180px;
    color: #fff;
    background: url(./img/bg-blue-m.png);
    background-size: cover;

    @include XGA {
      background: url(./img/bg-blue.jpg);
    }

    h2 {
      font-size: 32px;
      color: #fff;
      text-align: center;
      line-height: 1.1;
      margin-bottom: .5em;

      @include vHD {
        br {
          display: none;
        }
      }
    }

    p {
      text-align: center;
      font-size: 16px;

      @include XGA {
        font-size: 24px;
      }
    }

    .wklady {

      @include XGA {
        display: flex;
        justify-content: center;
      }

      .wklady-item {
        display: flex;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid #fff;
        margin: 12px 0;

        @include XGA {
          width: 470px;
          margin: 30px;
        }
        
        .column {
          width: 50%;
          
          h3 {
            margin-top: 0;
            font-size: 16px;

            @include XGA {
              font-size: 24px;
            }
          }

          p {
            text-align: left;
            font-size: 14px;
          }
        }

        .column-img {
          display: flex;
          align-items: center;

          img {
            height: 84px;
            width: auto;
            margin: 0 auto;

            &.single {
              height: 140px;
            }
          }
        }
      }
    }
    .disclaimer {
      font-size: 12px;
      max-width: 600px;
      margin: 20px auto 0;
      text-align: center;
    }
  }

  .section-roibos {
    padding: 10px 5% 100px;
    background-color: #fff;

    h2 {
      font-size: 32px;
      color: #0E2B63;
      text-align: center;
      line-height: 1.1;
      margin-bottom: .5em;
      margin-top: 2em;
    }

    .columns {
      margin: 0 auto;
      max-width: 880px;
      @include XGA {
        display: flex;
      }

      .column {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
          width: 100%;
          height: auto;
        }

        @include XGA {
          width: 400px;
        }

        &:last-child {
          @include XGA {
            padding-top: 30px;
          }
        }
      }
    }
  }
}