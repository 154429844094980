@import "../../theme/variables.scss";
.Login {
  max-width: 350px;
  position: relative;

  @include XGA {
    margin-top: 40px;
    padding: 40px;
  }
  @include HD {
    padding: 0;
  }

  .disclamer {
    position: fixed;
    top: 32px;
    left: 0;
    right: 0;
    height: 48px;
    background: white;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 24px;
    @include XGA {
      top: 108px;
    }
    p {
      margin: 0;
      font-size: 10px;
      font-weight: 700;
      color: #FF4444;
      @include XGA {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .body-form {
    max-width: 351px;
  }

  @include XGA {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 120px;
    max-width: 1170px;
    width: 100%;
  }
  @include HD {
    gap: 140px;
  }

  h1 {
    font-weight: 600;
    text-align: center;
    @include XGA {
      margin-top: 0;
    }
  }
  input::placeholder {
    font-style: normal;
  }

  .login-image {
    width: 100%;
    height: 124px;
    border-radius: 12px;

    @include XGA {
      height: auto;
      width: 100%;
    }
    @include HD {
      height: 393px;
      width: 568px;
      position: sticky;
      top: 40px;
    }

    overflow: hidden;
    picture,
    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
