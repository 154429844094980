@import "../../theme/variables.scss";

.PallMall {
  max-width: 1366px;
  min-height: 1000px;
  margin: 0 auto;
  background: #f4f5f9;
  background-image: url(./img/bg-mobile.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  @include XGA {
    background-image: url(./img/bg-desktop.jpg);
    //background: #f4f5f9;
  }

  .hero-section {
    position: relative;
    margin-top: -56px;
    padding-top: 56px;

    @include HD {
      margin-top: 0px;
    }
  }

  .main-section {
    position: relative;
    
    &:after {
      z-index: 0;
      content: "";
      width: 100%;
      height: 900px;
      background-color: #f4f5f9;
      top: 200px;
      left: 0;
      position: absolute;

      @include XGA {
        display: none;
      }
    }

    >* {
      position: relative;
      z-index: 1;
    }
  }

  .glo-hero-content {
    position: relative;
    color: #fff;

    .Title {
      font-size: 13vw;
      width: 100%;
      font-weight: 700;
      line-height: 1.2em;
      color: #00B1EB;
      padding-bottom: 20px;
      text-transform: uppercase;

      @include XGA {
        font-size: 54px;
        line-height: 54px;
        width: 700px;
        padding-bottom: 0;
      }

      @include HD {
        margin-top: 50px;
        font-size: 64px;
        line-height: 64px;
        width: 800px;
        padding-top: 0;
      }

      &.with-button {
        margin-top: 0;

        @include HD {
          padding-bottom: 0.3em;
        }
      }
      
      .ButtonBack {
        //align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }

    .pallmall-logo {
      margin: 20px 0 0;
      width: 112px;

      @include XGA {
        position: absolute;
        width: 225px;
        right: 60px;
        top: 20px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    p {
      font-size: 24px;
      text-transform: uppercase;
      margin: 2em 0;
      margin-top: 0;

      @include XGA {
        margin-top: 1em;
      }

      @include HD {
        font-size: 28px;
      }
    }
  }

  .glo-wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .static-items {
    position: relative;
    display: none;
    padding-bottom: 50px;

    .glo-wrapper {
      @include XGA {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 100%;
      }
    }

    @include XGA {
      display: block;
      margin-top: -70px;
    }

    @include HD {
      margin-top: -120px;
    }

    .static-item:not(:first-child) {
      margin-left: 16px;
    }

    .static-item {
      background: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;

      .item-img {
        width: 212px;
        height: 190px;
        display: block;
      }
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      @include HD {
        width: 269px;
        height: 248px;
      }
    }
  }

  .pallmall-swiper {
    padding: 30px 0 50px;
    background-color: #fff;

    @include XGA {
      max-width: 1030px;
      padding-left: 30px;
      padding-right: 30px;
    }

    .pallmall-swiper-slide {
      min-height: 270px;
      font-size: 18px;
      background-size: cover;
      color: #0F1785;

      .top {
        position: relative;
        width: 100%;
        padding-top: 32px;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: -10%;
          width: 120%;
          height: 2px;
          background-color: #0F1785;
        }

        &:before {
          content: "";
          position: absolute;
          top: -11px;
          left: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #0F1785;
        }

        @include XGA {
          height: 98px;
        }

        h3 {
          color: #00B1EB;
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 0;
        }

        .date {
          color: #00B1EB;
          font-weight: 700;
          margin-top: 0;
          text-transform: uppercase;
        }
      }

      .body {

        @include XGA {
          padding-top: 10px;
        }

        p {
          margin: 0;
          min-height: 205px;
        }

        img {
          margin-top: 20px;
          width: 100%;
          height: auto;
          display: block;
          border-radius: 12px;
        }
      }

      .title {
        font-size: 14px;
        @include XGA {
          margin-top: 10px;
          font-size: 21px;
          margin-bottom: 0;
        }
      }

      .desc {
        font-size: 14px;
        line-height: 22px;
        @include XGA {
          margin-top: 10px;
          font-size: 17px;
        }
      }
    }
  }

  .content-box {
    margin: 0 auto;
    width: 90%;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
    color: #0F1785;

    h2 {
      margin-top: 0;
    }

    .pallmall-subtext {
      padding: 0;
    }

    .fc90 {
      @include XGA {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    img {
      width: 130px;
      height: auto;
      margin: 1em auto;
      display: block;

      @include XGA {
        margin: 1em 0;
        width: 180px;
      }
    }

    ul {
      padding: 0;

      @include XGA {
        margin-left: 50px;
      }

       li {
        display: block;
        position: relative;
        font-size: 18px;
        line-height: 1.3;
        margin: 20px 0;
        padding-left: 40px;

        @include HD {
          font-size: 22px;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 24px;
          background-image: url(./img/pause.png);
          background-size: contain;
          background-repeat: no-repeat;
        }
       }
    }

    @include XGA {
      margin-top: 60px;
      padding: 30px;
    }

    @include HD {
      padding: 40px;
    }
  }

  .card-control {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;

    .control-btn {
      font-size: 14px;
      color: $primaryColor;
      border: 2px solid $primaryColor;
      border-radius: 20px;
      padding: 10px 20px;
      cursor: pointer;

      &.active {
        color: #fff;
        font-weight: 700;
        background-color: $primaryColor;
        border: 2px solid $primaryColor;
      }
    }
  }

  .pallmall-boxes {
    @include XGA {
      display: flex;
      justify-content: center;
    }
  }

  .pallmall-title {
    margin: 1em 0 .5em;
    padding: 0 5%;
    font-size: 26px;
    text-align: center;
    color: #0F1785;
    text-wrap: balance;
    text-transform: uppercase;

    span {
      color: #00B1EB;
    }

    @include XGA {
      font-size: 32px;
    }

    @include HD {
      padding: 0 22%;
      text-wrap: wrap;
    }

    img {
      width: 110px;
      height: auto;
      margin: 0 15px;
    }
  }

  .pallmall-subtext {
    font-size: 18px;
    text-align: center;
    padding: 0 5%;
    color: #0F1785;
    text-wrap: balance;

    @include HD {
      font-size: 22px;
    }
  }

  .glo-swiper {
    padding: 2em 0;
    
    @include XGA {
      max-width: 1100px;
    }
  }

  .glo-swiper-slide {
    .top {
      .icon {
        display: block;
        width: 100%;
        max-width: 168px;
        margin: 0 auto;
      }
    }

    .body {
      padding: .5em;
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      color: #0F1785;
    }
  }

  .pallmall-slides {
    padding: 4em 0;
    background-color: #f4f5f9;

    @include XGA {
      padding: 4em 0 8em;
    }
  }

  .pallmall-slide {
    color: #0F1785;
    font-size: 20px;
    margin-bottom: 25px;
    background-size: cover;
    display: flex;
    width: 90%;
    margin: 0 auto;
    max-width: 1170px;

    .top {
      padding-right: 100px;

      .icon {
        width: 48px;
        height: 48px;
        display: block;

        @include XGA {
          width: 270px;
          height: auto;
          display: block;
          border-radius: 12px;
        }
      }
    }

    .body {
      position: relative;
      padding: 16px 24px;
      font-size: 20px;
      min-height: 220px;

      &:after {
        content: "";
        position: absolute;
        top: -5%;
        left: -40px;
        width: 2px;
        height: 110%;
        background-color: #0F1785;
      }

      &:before {
        content: "";
        position: absolute;
        top: 25px;
        left: -51px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #0F1785;
      }

      @include XGA {
        padding-top: 10px;
      }

      p {
        margin: 0;
      }

      h3 {
        font-size: 36px;
        color: #00B1EB;
        margin: 0;
      }

      .date {
        color: #00B1EB;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1.5em;
      }
    }
  }
}