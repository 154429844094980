@import "../../theme/variables.scss";
.Footer {
  height: 36px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;

  @include HD {
    height: 48px;
    background: $primaryColor;
  }

  &.disclaimer {
    bottom: 66px;
  }

  .list {
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    max-width: fit-content;
    gap: 32px;
  }
  .list-item {
    button {
      text-decoration: underline;
      color: #001854;
      font-weight: 700;
      font-size: 10px;
      font-family: "Titillium Web", sans-serif;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      @include HD {
        font-size: 12px;
        color: #fff;
      }
      &:hover {
        text-decoration: none;
      }
    }
    a {
      font-size: 10px;

      @include HD {
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
