@import "../../theme/variables.scss";
.HowToRecommendAlt {
  max-width: 1366px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;

  margin-top: -56px;
  @include HD {
    margin-top: 0;
    padding-bottom: 50px;
  }

  .hero-section {
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .hero-content {
    position: absolute;
    top: 50px;
    left: 5%;
    width: 90%;
    max-width: 1170px;
    color: #fff;


    @include HD {
      top: 30px;
      left: 50%;
      margin-left: -585px;
    }

    .title {
      font-size: 32px;
      font-size: clamp(14px, 8vw, 84px);
      font-weight: 400;
      line-height: 1.2;

      .bold {
        font-weight: 600;
      }

      @include XGA {
        padding-bottom: 0;
        font-size: 36px;
        text-align: center;
        margin: 0 auto;
        max-width: 820px;

        .br {
          display: none;
        }
      }

      @include HD {
        margin-top: 0px;
        font-size: 44px;
      }

      .ButtonBack {
        align-self: center;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
      }
    }
  }

  .ButtonTabs {
    display: flex;
    margin-right: 15px;

    @include XGA {
      position: absolute;
      top: 20px;
      left: 420px;
    }

    @include HD {
      left: 480px;
    }

    .ButtonTab {
      padding: 3px 15px;
      height: auto;
      font-size: 16px;
      color: #fff;
      border: 1px solid #fff;

      &.active {
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
      }
    }
  }

  .wrapper {
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;

    @include HD {
      margin: 0 auto;
    }
  }

  .recommend-swiper {
    padding: 50px 0;
    margin-top: -45vw;

    @include XGA {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: -120px;
    }
    @include HD {
      margin-top: -205px;
      padding-left: 7%;
      padding-right: 7%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50px;
      left: -10%;
      right: -10%;
      background: $primaryColor;
      display: block;
      height: 250px;
      width: 120%;
      z-index: 0;
      transform: rotate(5deg);

      @include HD {
        top: 60px;
      }
    }

    .swiper-wrapper {
      @include XGA {
        // display: flex;
        // justify-content: space-between;
      }
    }

    .recommend-swiper-slide {
      background: #fff;
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      min-height: 420px;

      @include HD {
        min-height: 360px;
      }

      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 114px;
      }
      .body {
        text-align: center;
        padding: 0 23px;
        padding-bottom: 23px;
      }

      .title {
        color: #001854;
        font-size: 21px;
        margin: 0;
        line-height: 22px;
      }
      .title-small {
        font-size: 18px;
        line-height: 21px;
      }

      .desc {
        font-size: 14px;
        line-height: 21px;

        a {
          text-decoration: none;
          color: #2f4374;
        }
        .special {
          color: #2f4374;
          font-weight: bold;
        }
      }
    }
  }
  .prize {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -50px;
      left: -10%;
      right: -10%;
      background: #e7ecfa;
      display: block;
      height: 170px;
      width: 120%;
      z-index: 0;
      transform: rotate(174.81deg);
    }

    .inner {
      position: relative;
      z-index: 2;
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;

      @include XGA {
        margin-bottom: 1.8rem;
      }

      .title {
        color: #ce128d;
        font-size: 32px;
        line-height: 35px;
        text-align: center;
      }
      .icon {
        display: block;
        margin-right: 16px;
      }
    }

    .cards {
      padding: 0;
      margin: 0;

      @include XGA {
        display: flex;
        gap: 30px;
        justify-content: center;

        // /min-height: 500px;
      }

      .card:nth-child(2) {
        margin-top: 32px;
        @include XGA {
          margin-top: 0;
        }
      }
      .card {
        list-style: none;
        background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
        box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        overflow: hidden;

        @include XGA {
          width: 50%;
          max-width: 465px;
        }
      }
      .card:nth-child(1) {
        display: flex;
        flex-direction: column;
      }
      .top-card {
        background: #ce128d;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 120px;
        padding: 0 15px;
        margin: 0;

        @include HD {
          min-height: 90px;
        }
      }
      .title {
        font-size: 32px;
        line-height: 36px;
        color: #fff;
        text-align: center;

        @include HD {
          br {
            display: none;
          }
        }
      }
      .body {
        padding: 0 18px;
        padding-bottom: 18px;

        @include HD {
          display: flex;
          padding: 18px;
        }
      }

      .icon {
        margin: 0 auto;
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include HD {
          margin: 0;
          margin-right: 28px;
        }
      }
      .desc {
        font-size: 21px;
        line-height: 22px;
        text-align: center;
        font-weight: 400;

        @include HD {
          text-align: left;
        }

        span {
          color: #000;
          font-weight: 700;
        }
      }
    }
  }

  .qr {
    position: relative;
    z-index: 2;
    margin-top: 60px;

    &::before {
      position: absolute;
      bottom: 30px;
      height: 170px;
      width: calc(140%);
      background: rgb(255, 255, 255);
      transform: rotate(7deg) translate(-20%, 0%);
      display: block;
      content: "";
      z-index: 1;
      @include XGA {
        bottom: -25px;
        transform: rotate(5deg) translate(-20%, 0%);
      }
    }
    .wrapper {
      position: relative;
      z-index: 2;
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
    }
    .title {
      color: #fff;
      font-size: 32px;
      text-align: left;
      line-height: 35px;
      margin: 0;

      @include HD {
        br {
          display: none;
        }
      }
    }

    .cards {
      margin-top: 32px;

      @include XGA {
        display: flex;
        justify-content: center;
        gap: 30px;
      }
    }
    .card:nth-child(2) {
      margin-top: 32px;

      @include XGA {
        margin-top: 0;
      }
    }
    .card {
      background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
      box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      background: #fff;

      @include XGA {
        max-width: 470px;
        min-height: 360px;

        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .top {
      min-height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .count {
      width: 73px;
      height: 73px;
      background: #001854;
      border-radius: 50%;
      color: #fff;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
    }
    .body {
      padding: 0 36px;
      padding-bottom: 24px;
    }
    .desc {
      font-size: 21px;
      line-height: 23px;
      font-weight: 400;
      text-align: center;
      margin: 0;

      @include HD {
        font-size: 27px;
        line-height: 30px;
      }

      a {
        text-decoration: none;

        span {
          display: block;
        }
        @include vHD {
          br {
            display: none;
          }
        }
      }

      span {
        font-weight: bold;
        color: #0c235c;
      }
    }
  }
  .alter-zone {
    padding-top: 180px;
    margin-top: -100px;
    background-color: #0b0b0b;
    position: relative;

    .title {
      color: #fff;
      font-size: 32px;
    }

    .wrapper {
      position: relative;
      z-index: 2;
    }

    .cards {
      padding-bottom: 5rem;
      @include XGA {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .logo {
      margin: 0 auto;
      display: block;

      img {
        display: block;
        height: auto;
        width: 151px;
      }
    }
    .desc {
      color: #fff;
      text-align: center;
      font-size: 21px;
      line-height: 23px;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }
    .footer-desc {
      color: #fff;
      font-size: 21px;
      line-height: 23px;
      font-weight: 600;
    }
    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include XGA {
        width: 50%;
      }

      @include HD {
        width: 23%;
      }
    }
  }
}
