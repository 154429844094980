.Loading {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;


  .bullets {
    display: flex;
    margin-top: 8px;
    width: 50px;
    justify-content: space-between;
    align-items: center;
  
    .bullet {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      animation: bouncing-loader 0.6s infinite alternate;
      background: #fff;

      &.first {
        animation-delay: 0.2s;
      }
      &.second {
        animation-delay: 0.4s;
      }
      &.third {
        animation-delay: 0.6s;
      }
      &.four {
        animation-delay: 0.8s;
      }
    }
    &.color {

      &.bullets {
        height: 80vh;
        width: 80px;
      }

      .bullet {
        width: 11px;
        height: 11px;

        &.first {
          background: #001854;
        }
        &.secound {
          background: #ffb400;
        }
        &.third {
          background: #64328b;
        }
        &.four {
          background: #ff2885;
        }
      }
    }
  }

  @keyframes bouncing-loader {
    to {
      transform: translate3d(0, -.5rem, 0);
    }
  }
}
