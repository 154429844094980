@import "../../theme/variables.scss";
.QuizCta {
    border-radius: 8px;
    background: transparent;
    font-size:12px;
    text-align: left;
    padding: 12px;
    border:1px solid #C3C9D8;
    color: #000;
    cursor: pointer;

    @include XGA {
        font-size: 14px;
    }

    &:not(:first-of-type) {
        margin-top:24px;
     }
    
    &.active {
      font-weight: 600;
      border:1px solid $primaryColor;
    }
    
  }
 