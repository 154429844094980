@import "../../../theme/variables.scss";

.age-gate {
    .modal {
        padding: 24px;
        max-width: 680px;
        color: #fff;
        background-color: $primaryColor;
        width: 90%;
        max-width: 560px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        outline: 0;

        @include XGA {
            width: 72%;
            // height: 70%;
        }
    }

    .top {
        p {
            margin: 0;
            margin-top: 20px;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
        }

        @include vHD {
            margin-top: 23px;
        }
    }

    .over-18 {
        h3 {
            margin: 0;
            margin-top: 41px;
            font-weight: 600;
            font-size: 21px;
            line-height: 25px;
            text-align: center;

            @include vHD {
                font-size: 24px;
                line-height: 32px;
                margin-top: 46px;
            }
        }
    }

    .buttons {
        width: 100%;
        max-width: 270px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        .Button {
            width: max-content;
            padding-left: 24px;
            padding-right: 24px;
            margin-right: 14px;
            margin-left: 0;

            &:last-child {
                margin-right: 0;
                border: 1px solid #fff;
            }
        }
    }

    .disclaimer {
        margin-top: 48px;
        max-width: 290px;
        font-size: 10px;
        line-height: 12px;
        text-align: center;

        @include vHD {
            max-width: 495px;
            margin-top: 40px;
        }

        p {
            margin: 0;
        }
    }

    .error {
        color: #f44;
        text-align: center;

        &:before {
            display: none;
        }
    }

    .mobile {
        @include vHD {
            display: none;
        }
    }
}