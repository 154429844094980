@import "../../theme/variables.scss";
.QuizQuestionCounter {
  display: flex;

  position: relative;

  .bullets-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .prize {
    display: none;

    @include XGA {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 70px;
      right: 0;
      bottom: 5px;
      background: #f4f5f9;
      z-index: 2;
    }
    .icon {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      margin-left: 5px;
    }
    .value {
      font-size: 21px;
      font-weight: 600;
      margin-left: auto;
    }
  }

  .bullets {
    display: flex;
    width: fit-content;
    position: relative;
  }
  .line {
    position: absolute;
    right: -5px;
    transform: translateX(100%);
    width: 1000px;
    height: 1px;
    background: #c3c9d8;
    bottom: 5px;
  }
  .bullet {
    width: 18px;
    height: 18px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $primaryColor;
    flex-shrink: 0;

    &.active {
      background: $primaryColor;
      color: #fff;
      font-weight: 700;
    }
  }
}
