@import "../../theme/variables.scss";
.About {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include HD {
    margin: 0 auto;
  }
  .about-wrapper {
    @include XGA {
      display: grid;
      grid-template-columns: 1.3fr 0.7fr;
    }
  }

  .about-title {
    font-weight: 300;
    font-size: 21px;
    text-align: center;
    padding: 0 10%;
    align-items: center;
    .strong {
      font-weight: 600;
    }
    @include XGA {
      font-size: 26px;
      text-align: left;
      padding-right: 0;

      align-self: center;
    }

    @include HD {
      font-size: 31px;
    }
  }

  @include XGA {
    margin: 0 auto;
  }

 
}
