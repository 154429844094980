@import "../../theme/variables.scss";
.news-list-big-item {
  list-style: none;
  margin-bottom: 80px;
  position: relative;

  height: 209px;
  cursor: pointer;

  @include XGA {
    margin-bottom: 25px;
    width: 468px;
    height: 293px;
  }

  @include HD {
    margin-bottom: 25px;
    width: 568px;
    height: 393px;
  }

  .news-list-item-category {
    position: absolute;
    right: 5%;
    top: 0;
    transform: translateY(-50%);
    background: $pink;
    border-radius: 100px;
    min-width: 82px;
    min-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 0 10px;
    font-size: 10px;
    font-weight: 300;

    &.contest {
      background: $tertiaryColor;
    }
    @include XGA {
      font-size: 14px;
      min-height: 33px;
      min-width: 150px;
    }
  }

  .news-list-image {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .news-list-body {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -70%);
    .ArrowButton {
      @include HD {
        width: 53px;
        height: 53px;
        svg {
          width: auto;
          height: 17px;
        }
      }
    }

    @include HD {
      display: grid;
    }
  }
  .news-list-title {
    font-size: 19px;
    font-weight: 300;
    margin: 0;
    line-height: 21px;
    @include XGA {
      font-size: 38px;
      line-height: 43px;
    }
  }

  .news-item-footer {
    display: flex;
    margin-top: 5px;
    position: relative;
    @include HD {
      align-self: end;
    }
  }
  .news-item-icon {
    width: 18px;
    height: 18px;
    @include XGA {
      width: 21px;
      height: 21px;
    }
  }
  .news-item-line {
    width: 100%;
    height: 1px;
    background: #ccc;
    align-self: center;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
  }
  .news-item-date {
    flex-shrink: 0;
    color: $primaryColor;
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-top: -10px;

    svg {
      margin-right: 5px;
    }
  }
}
