@import "../../theme/variables.scss";
.ShortcutPage {
  position: relative;

  .wrapper {
    width: 90%;
    margin: 0 5%;
    @include XGA {
      margin: 50px auto;
    }
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  .desc {
    text-align: center;
  }

  .indexed-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    padding: 0;
    .ButtonTab {
      background: transparent;
      border: 1px solid $secondaryColor;
      color: $secondaryColor;

      &.active {
        background: $secondaryColor;
        color: #fff;
        border: $secondaryColor;
      }
    }
  }

  .card {
    width: 90%;
    position: relative;
    min-height: 300px;
    margin: 0 auto;
    padding: 25px 25px 30px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 12px;
    background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
    box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include XGA {
      max-width: 1366px;
    }

    p {
      font-size: 14px;
      text-align: center;
    }
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $secondaryColor;
    transform: scale(1.2);
  }

  .swiper-slide {
    padding-bottom: 50px;
  }
  .nr {
    position: relative;

    width: 42px;
    height: 42px;
    align-self: center;
    span {
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -21px;
      width: 42px;
      height: 42px;
      color: #001854;
      font-size: 20px;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
    }
  }
}
